import { menuLab } from './menu'

const menuIsExterno = [
  // Módulo Correspondências
  {
    id: 'HOME_CRP',
    name: 'HOME',
    modulo: 'CRP',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },

  // Módulo de Obrigação Contratual
  {
    id: 'HOME_OBR',
    name: 'HOME',
    modulo: 'OBR',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },

  // Módulo de Notificação
  {
    id: 'HOME_NTF',
    name: 'HOME',
    modulo: 'NTF',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },

  // Módulo de GED
  {
    id: 'GED_000',
    name: 'GED_HOME',
    modulo: 'GED',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },

  // Módulo de Meio Ambiente
  {
    id: 'HOME_MAM',
    name: 'HOME',
    modulo: 'MAM',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },

  {
    id: 'MAM01',
    modulo: 'MAM',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/ambiental/dashboard',
    visivel: true,
  },
  {
    id: 'MAM02',
    modulo: 'MAM',
    icon: 'tree',
    label: 'Fichas',
    to: '/ambiental',
    visivel: true,
    subs: [
      {
        icon: 'tree',
        label: 'RACS',
        to: '/ambiental/fichas/rac',
      },
      {
        icon: 'shield-alt',
        label: 'Inspeções Ambientais',
        to: '/ambiental/fichas/inspecaoAmbiental/inspecaoAmbientalList',
      },
      {
        icon: 'chart-pie',
        label: 'Análises Ambientais',
        to: '/ambiental/fichas/analiseAmbiental',
      },
      {
        icon: 'check',
        label: 'CheckLists',
        to: '/ambiental/fichas/checklist/checklist',
      },
      {
        icon: 'archive',
        label: 'Laudo Desativação PTC',
        to: '/ambiental/fichas/ldptc/desativaPtc',
      },
      {
        icon: 'window-restore',
        label: 'RACOM',
        to: '/ambiental/fichas/racom/racomList',
      },
      {
        icon: 'box',
        label: 'Depósito',
        to: '/ambiental/fichas/areaApoio',
      },
      {
        icon: 'user-check',
        label: 'Autorização Intervenção',
        to: '/ambiental/fichas/autorizacaoIntervencao/autorizacaoIntervencaoList',
      },
      {
        icon: 'user-tag',
        label: 'Autorização RAP',
        to: '/ambiental/fichas/autorizacaoDescarte',
      },
      {
        icon: 'pencil-ruler',
        label: 'Boas Práticas',
        to: '/ambiental/fichas/boasPraticas',
      },
    ],
  },

  {
    id: 'MAM03',
    modulo: 'MAM',
    icon: 'table',
    label: 'Cadastros',
    to: '/ambiental',
    visivel: true,
    subs: [
      {
        label: 'RAC',
        to: '/cadastro/',
        subs: [
          {
            icon: 'file-alt',
            label: 'Fichas - Status',
            to: '/ambiental/cadastros/statusFicha',
          },
          {
            icon: 'gavel',
            label: 'Requisitos Legais',
            to: '/ambiental/cadastros/requisitosLegais',
          },
          {
            icon: 'user-shield',
            label: 'Regras de Ouro',
            to: '/ambiental/cadastros/regraDeOuro',
          },
          {
            icon: 'lock',
            label: 'Áreas Protegidas',
            to: '/ambiental/cadastros/areaProtegida',
          },
          {
            icon: 'exclamation-circle',
            label: 'Ocorrências',
            to: '/ambiental/cadastros/ocorrencias',
          },
          {
            icon: 'tasks',
            label: 'Gravidades',
            to: '/ambiental/cadastros/gravidades',
          },
          {
            icon: 'flag-checkered',
            label: 'Soluções',
            to: '/ambiental/cadastros/solucoes',
          },
        ],
      },
      {
        icon: 'check',
        label: 'CheckLists',
        to: '/ambiental',
        subs: [
          {
            icon: 'check-circle',
            label: 'Checklist - Estruturas',
            to: '/ambiental/cadastros/estruturaCheckList',
          },
          {
            icon: 'check-square',
            label: 'CheckList - Ficha',
            to: '/ambiental/cadastros/estruturaFichaCheckList',
          },
        ],
      },
      {
        label: 'Geral',
        to: '/ambiental',
        subs: [
          {
            icon: 'window-maximize',
            label: 'Classificação de Tipos',
            to: '/ambiental/cadastros/tiposFichas',
          },
          {
            icon: 'recycle',
            label: 'Fluxos de Aprovação',
            to: '/ambiental/cadastros/fluxoAprovacao/fluxosAprovacao',
          },
          {
            icon: 'chart-area',
            label: 'Análise Ambiental - Grupos',
            to: '/ambiental/cadastros/analiseAmbientalGrupos',
          },
          {
            icon: 'chart-bar',
            label: 'Análise Ambiental - Tipos',
            to: '/ambiental/cadastros/analiseAmbientalTipos',
          },
          {
            icon: 'window-maximize',
            label: 'Fator Comportamental',
            to: '/ambiental/cadastros/fatoresComportamentais',
          },
          {
            icon: 'calendar-alt',
            label: 'Tipo de Evento',
            to: '/ambiental/cadastros/tiposEventos',
          },
          {
            icon: 'chess-board',
            label: 'Tipos Estratégicos',
            to: '/ambiental/cadastros/tiposEstrategicos',
          },
          {
            icon: 'users',
            label: 'Equipes',
            to: '/ambiental/cadastros/equipes',
          },
          {
            icon: 'leaf',
            label: 'Licenças Ambientais - Tipos',
            to: '/ambiental/cadastros/tipoLicencaAmbiental',
          },
          {
            icon: 'tools',
            label: 'Obras',
            to: '/ambiental/cadastros/obras',
          },
          {
            icon: 'wrench',
            label: 'Obras - Tipos',
            to: '/ambiental/cadastros/tiposObras',
          },
          {
            icon: 'map-signs',
            label: 'Ponto de Controle',
            to: '/ambiental/cadastros/pontoControle',
          },
          {
            icon: 'layer-group',
            label: 'Ponto de Controle - Tipos',
            to: '/ambiental/cadastros/tipoPontoControle',
          },
          {
            icon: 'layer-group',
            label: 'Trecho de Controle',
            to: '/ambiental/cadastros/trechoControle',
          },
        ],
      },
    ],
  },
  {
    id: 'MAM04',
    modulo: 'MAM',
    icon: 'map',
    label: 'Mapas',
    to: '/ambiental',
    visivel: true,
    subs: [
      {
        icon: 'map-marked',
        label: 'Não Conformidades',
        to: '/ambiental/mapas/mapasFichas',
      },
      {
        icon: 'map-marked',
        label: 'Obras',
        to: '/ambiental/mapas/mapasObras',
      },
      {
        icon: 'map-marked',
        label: 'Ponto de Controle',
        to: '/ambiental/mapas/mapaPontoControle',
      },
    ],
  },

  // Módulo de Demanda
  {
    id: 'HOME_DEM',
    name: 'HOME',
    modulo: 'DEM',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },

  // Módulo de Pleitos
  {
    id: 'HOME_PLT',
    name: 'HOME',
    modulo: 'PLT',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },

  // Módulo de Conserva
  {
    id: 'HOME_CSV',
    name: 'HOME',
    modulo: 'CSV',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },

  // Módulo SIGACO
  {
    id: 'HOME_SGC',
    name: 'HOME',
    modulo: 'GAC',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'SGC02',
    idSub: 2,
    modulo: 'GAC',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/sigaco/dashboard',
    permissao: 'GAC_DASHBOARDS',
    visivel: true,
  },
  {
    id: 'SGC03',
    modulo: 'GAC',
    icon: 'chalkboard-teacher',
    label: 'Monitorações',
    to: '/sigaco/monitoracoes',
    visivel: true,
    subs: [
      {
        icon: 'road',
        label: 'SGP - Pavimento',
        to: '/sigaco/monitoracao/pavimento',
        visivel: true,
      },
      {
        icon: 'traffic-light',
        label: 'SGS -  Sinalização',
        to: '/sigaco/monitoracao/sinalizacao',
        visivel: true,
      },
      {
        icon: 'archway',
        label: 'SGOAE - OAE',
        to: '/sigaco/monitoracao/obraArteEspecial',
        visivel: true,
      },
      {
        icon: 'water',
        label: 'SGOAC - Drenagem/OAC',
        to: '/sigaco/monitoracao/drenagem',
        visivel: true,
      },
      {
        icon: 'snowplow',
        label: 'SGTEC - Terrap./Contenção',
        to: '/sigaco/monitoracao/terrapleno',
        visivel: true,
      },
      {
        icon: 'route',
        label: 'SGF - Faixa Domínio',
        to: '/sigaco/monitoracao/faixaDominio',
        visivel: true,
      },
      {
        icon: 'building',
        label: 'SGE - Edific./Instalações',
        to: '/sigaco/monitoracao/edificacaoInstalacao',
        visivel: true,
      },
      {
        icon: 'bolt',
        label: 'SGSEI - Sistemas Elétricos',
        to: '/sigaco/monitoracao/sistemaEletrico',
        visivel: true,
      },
      {
        icon: 'archway',
        label: 'SGOST - Oper./Seg. Túneis',
        to: '/sigaco/monitoracao/operacaoSegurancaTunel',
        visivel: true,
      },
    ],
  },
  {
    id: 'SGC07',
    modulo: 'GAC',
    permissao: 'GAC_CONSULTAR_MAPA',
    icon: 'map',
    label: 'Mapas',
    to: '/sigaco/mapa',
    visivel: true,
  },

  // Módulo de Ativos
  {
    id: 'HOME_ATV',
    name: 'HOME',
    modulo: 'ATV',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'ATV01',
    modulo: 'ATV',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/ativos/dashboard',
    visivel: true,
  },
  {
    id: 'ATV02',
    modulo: 'ATV',
    icon: 'edit',
    label: 'Cadastros',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        label: 'Ativos',
        subs: [
          {
            icon: 'water',
            label: 'Drenagem/OAC',
            to: '/ativos/cadastro/drenagem/list',
          },
          {
            icon: 'road',
            label: 'Sinalização Horizontal',
            to: '/ativos/cadastro/sinalizacaoHorizontal/list',
          },
          {
            icon: 'traffic-light',
            label: 'Sinalização Vertical',
            to: '/ativos/cadastro/sinalizacaoVertical/list',
          },
          {
            icon: 'snowplow',
            label: 'Terraplenos/Estr. Cont.',
            to: '/ativos/cadastro/terraplenos/list',
          },
          {
            icon: 'bolt',
            label: 'Equip. Prot. Segur.',
            to: '/ativos/cadastro/eps/list',
          },
          {
            icon: 'building',
            label: 'Edificações',
            to: '/ativos/bensMoveis/itemAtivoMovel',
          },
        ],
      },
      {
        label: 'Geral',
        subs: [
          {
            icon: 'prescription-bottle',
            label: 'Elementos',
            to: '/ativos/elementos/list',
          },
          {
            icon: 'chart-pie',
            label: 'Categorias',
            to: '/ativos/categorias/list',
          },
          {
            icon: 'chart-bar',
            label: 'Parâmetros',
            to: '/ativos/parametros/list',
          },
          {
            icon: 'brazilian-real-sign',
            label: 'Preços Unitários',
            to: '/cadastros/precoUnitario/list',
          },
          {
            icon: 'recycle',
            label: 'Ciclos de Monitoração',
            to: '/ativos/cadastro/cicloMonitoracao/list',
          },
          {
            icon: 'cloud-sun',
            label: 'Ocorrências Climáticas',
            to: '/ativos/cadastro/ocorrenciasClimaticas/list',
          },
          {
            icon: 'clipboard',
            label: 'Tipos de Ocorrência',
            to: '/ativos/cadastro/tipoOcorrenciaClimatica/list',
          },
        ],
      },
    ],
  },
  {
    id: 'ATV03',
    modulo: 'ATV',
    icon: 'tv',
    label: 'Monitoração',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'water',
        label: 'Drenagem/OAC',
        to: '/ativos/monitoracao/drenagem',
      },
      {
        icon: 'road',
        label: 'Sinalizaçao Horizontal',
        to: '/ativos/monitoracao/list/?tipo=SNH',
      },
      {
        icon: 'traffic-light',
        label: 'Sinalização Vertical',
        to: '/ativos/monitoracao/list/?tipo=SNV',
      },
      {
        icon: 'snowplow',
        label: 'Terraplenos/Estr. Contenção',
        to: '/ativos/monitoracao/terrapleno',
      },
      {
        icon: 'bolt',
        label: 'Equip. Prot. Segur.',
        to: '/ativos/monitoracao/eps',
      },
      {
        icon: 'building',
        label: 'Edificações',
        to: '/ativos/monitoracao/list/?tipo=EDF',
      },
      {
        label: 'Histórico de Intervenção',
        subs: [
          {
            icon: 'water',
            label: 'Drenagem/OAC',
            to: '/ativos/monitoracao/historicoIntervencao/list/?tipo=DRE',
          },
        ],
      },
    ],
  },
  {
    id: 'ATV04',
    modulo: 'ATV',
    icon: 'tasks',
    label: 'Inspeção',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'water',
        label: 'Drenagem/OAC',
        to: '/ativos/inspecao/list/?tipo=DRE',
      },
      {
        icon: 'road',
        label: 'Sinalizaçao Horizontal',
        to: '/ativos/inspecao/list/?tipo=SNH',
      },
      {
        icon: 'traffic-light',
        label: 'Sinalização Vertical',
        to: '/ativos/inspecao/list/?tipo=SNV',
      },
      {
        icon: 'snowplow',
        label: 'Terraplenos/Estr.Cont.',
        to: '/ativos/inspecao/list/?tipo=TER',
      },
      {
        icon: 'bolt',
        label: 'Equip. Prot. Segur.',
        to: '/ativos/inspecao/list/?tipo=EPS',
      },
      {
        icon: 'building',
        label: 'Edificações',
        to: '/ativos/inspecao/list/?tipo=EDF',
      },
    ],
  },
  {
    id: 'ATV06',
    modulo: 'ATV',
    icon: 'tv',
    label: 'Conservação',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'water',
        label: 'Drenagem',
        to: '/ativos/conservacao/list/?tipo=dre',
      },
    ],
  },
  {
    id: 'ATV07',
    modulo: 'ATV',
    icon: 'tv',
    label: 'Não Conformidades',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'water',
        label: 'Drenagem',
        to: '/ativos/naoConformidades/list/?tipo=dre',
      },
    ],
  },
  {
    id: 'ATV08',
    modulo: 'ATV',
    icon: 'file-invoice-dollar',
    label: 'Planejamento',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'water',
        label: 'Drenagem/OAC',
        to: '/ativos/planejamento/drenagem',
      },
      {
        icon: 'road',
        label: 'Sinalizaçao Horizontal',
        to: '/ativos/planejamento/sinalizacaoHorizontal',
      },
      {
        icon: 'traffic-light',
        label: 'Sinalização Vertical',
        to: '/ativos/planejamento/sinalizacaoVertical',
      },
      {
        icon: 'snowplow',
        label: 'Terraplenos/Estr.Cont.',
        to: '/ativos/planejamento/terrapleno',
      },
      {
        icon: 'bolt',
        label: 'Equip. Prot. Segur.',
        to: '/ativos/planejamento/eps',
      },
      {
        icon: 'building',
        label: 'Edificações',
        to: '/ativos/planejamento/edificacao',
      },
    ],
  },
  {
    id: 'ATV09',
    modulo: 'ATV',
    icon: 'map',
    label: 'Mapas',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'location-dot',
        label: 'Mapas',
        to: '/ativos/mapa/mapa',
      },
    ],
  },
]
const data = [...menuIsExterno, ...menuLab]

export default data
