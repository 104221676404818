import { authHeaderMultipart } from '~/helpers'

export default ($axios) => ({
  // ok
  findRacom(siglaEmpresa, filtro) {
    return $axios.post(`/racoms/${siglaEmpresa}/filtro`, filtro)
  },

  // ok
  getRacomById(siglaEmpresa, idRacom) {
    return $axios.get(`/racoms/${siglaEmpresa}/${idRacom}`)
  },

  // ok
  saveRacom(siglaEmpresa, racom, anexos, racs, correcoes, causas, plano) {
    const formData = new FormData()

    if (racom !== undefined) {
      formData.append(
        'racom',
        JSON.stringify(racom, (key, value) => {
          if (value !== null) return value
        })
      )
    }

    if (anexos !== undefined) {
      console.log("Anexos=>", anexos)
      const anexosRaw = []
      for (const anexo of anexos) {
        formData.append('filesAnexos', anexo.file)
        anexosRaw.push({ comentario: anexo.comentario })
      }

      formData.append('anexos', JSON.stringify(anexosRaw))
    }

    if (racs !== undefined) {
      console.log("racs=>", racs)
      formData.append('racs', JSON.stringify(racs))
    }

    if (correcoes !== undefined) {
      console.log("correcoes=>", correcoes)
      formData.append('correcoes', JSON.stringify(correcoes))
    }

    if (causas !== undefined) {
      console.log("causas=>", causas)
      formData.append('causas', JSON.stringify(causas))
    }

    if (plano !== undefined) {
      console.log("plano=>", plano)
      formData.append('planosAcao', JSON.stringify(plano))
    }

    console.log('FormData=>', formData)

    return $axios.post(`/racoms/${siglaEmpresa}`, formData)
  },

  // ok
  deleteRacom(siglaEmpresa, idRacom) {
    return $axios.delete(`/racoms/${siglaEmpresa}/${idRacom}`)
  },

  // ok
  createAnexo(siglaEmpresa, idRacom, formData) {
    const requestCfg = {
      method: 'POST',
      headers: authHeaderMultipart(),
    }

    return $axios.post(
      `/racoms/${siglaEmpresa}/${idRacom}/anexos`,
      formData,
      requestCfg
    )
  },

  // ok
  findAnexos(siglaEmpresa, idRacom) {
    return $axios.get(`/racoms/${siglaEmpresa}/${idRacom}/anexos`)
  },

  saveAnexos(siglaEmpresa, idRacom, anexos) {
    const requestCfg = {
      method: 'POST',
      headers: authHeaderMultipart(),
    }

    return $axios.post(
      `/racoms/${siglaEmpresa}/${idRacom}/anexos`,
      anexos,
      requestCfg
    )
  },

  deleteAnexo(siglaEmpresa, idRacom, seqAnexo) {
    return $axios.delete(
      `/racom/${siglaEmpresa}/${idRacom}/anexos/${seqAnexo}`
    )
  },

  saveCorrecoes(payload, siglaEmpresa, idRacom) {
    return $axios.post(
      `/racoms/${siglaEmpresa}/${idRacom}/correcoes`,
      payload
    )
  },

  findCorrecoes(siglaEmpresa, idRacom) {
    return $axios.get(`/racoms/${siglaEmpresa}/${idRacom}/correcoes`)
  },

  deleteCorrecoes(siglaEmpresa, idRacom, seqCorrecao) {
    return $axios.delete(
      `/racoms/${siglaEmpresa}/${idRacom}/correcoes/${seqCorrecao}`
    )
  },

  findHistoricos(siglaEmpresa, idRacom) {
    return $axios.get(`/racom/${siglaEmpresa}/${idRacom}/historicos`)
  },

  saveHistorico(siglaEmpresa, idRacom, historico) {
    const formData = new FormData()

    if (historico !== undefined)
      formData.append('descricao', JSON.stringify(historico.descricao))

    return $axios.post(
      `/racom/${siglaEmpresa}/${idRacom}/historicos`,
      formData
    )
  },

  findRac(siglaEmpresa, idRacom) {
    return $axios.get(`/racoms/${siglaEmpresa}/${idRacom}/racs`)
  },

  saveRac(siglaEmpresa, idRacom, rac) {
    return $axios.post(`/racoms/${siglaEmpresa}/${idRacom}/racs`, rac)
  },

  deleteRac(siglaEmpresa, idRacom, idRac) {
    return $axios.delete(`/racoms/${siglaEmpresa}/${idRacom}/racs/${idRac}`)
  },

  findCausas(siglaEmpresa, idRacom) {
    return $axios.get(`/racoms/${siglaEmpresa}/${idRacom}/causas`)
  },

  saveCausas(siglaEmpresa, idRacom, causas) {
    return $axios.post(`/racoms/${siglaEmpresa}/${idRacom}/causas`, causas)
  },

  deleteCausas(siglasEmpresa, idRacom, seqCorrecao) {
    return $axios.delete(
      `/racoms/${siglasEmpresa}/${idRacom}/causas/${seqCorrecao}`
    )
  },

  findPlanoAcao(siglaEmpresa, idRacom) {
    return $axios.get(`/racoms/${siglaEmpresa}/${idRacom}/planos`)
  },

  savePlanoAcao(siglaEmpresa, idRacom, planos) {
    return $axios.post(`/racoms/${siglaEmpresa}/${idRacom}/planos`, planos)
  },

  deletePlanoAcao(siglasEmpresa, idRacom, seqCorrecao) {
    return $axios.delete(
      `/racoms/${siglasEmpresa}/${idRacom}/planos/${seqCorrecao}`
    )
  },
})
