export default ($axios) => ({
  findItens(empresa) {
    return $axios.get(`/itensContratos/${empresa}`)
  },

  findAnaliseItens(empresa, itemContratual) {
    return $axios.get(
      `/itensContratos/${empresa}/analises?itemContratual=${itemContratual}`
    )
  },
})
