export default ($axios) => ({
  findDemandas(empresa) {
    return $axios.get(`/demandas/${empresa}`)
  },

  getDemandaById(empresa, idDemanda) {
    return $axios.get(`/demandas/${empresa}/${idDemanda}`)
  },

  saveDemanda(empresa, demanda) {
    return $axios.post(`/demandas/${empresa}`, demanda)
  },

  deleteDemanda(empresa, idDemanda) {
    return $axios.post(`/demandas/${empresa}/${idDemanda}`)
  },

  // Tipos
  findTipos(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/demandas/${empresa}/tipos`)
    } else {
      return $axios.get(`/demandas/${empresa}/tipos?search=${search}`)
    }
  },

  getTipoById(empresa, idTipo) {
    return $axios.get(`/demandas/${empresa}/tipos/${idTipo}`)
  },

  saveTipo(tipo) {
    return $axios.post(`/demandas/tipos/`, tipo)
  },

  deleteTipo(idTipo) {
    return $axios.delete(`/demandas/tipos/${idTipo}`)
  },

  //  Pesos de Priorizações
  findPesos(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/demandas/${empresa}/pesosPriorizacao`)
    } else {
      return $axios.get(
        `/demandas/${empresa}/pesosPriorizacao?search=${search}`
      )
    }
  },

  getPesoById(idPeso) {
    return $axios.get(`/demandas/pesosPriorizacao/${idPeso}`)
  },

  savePeso(peso) {
    return $axios.post(`/demandas/pesosPriorizacao/`, peso)
  },

  deletePeso(idPeso) {
    return $axios.delete(`/demandas/pesosPriorizacao/${idPeso}`)
  },

  // Indices
  findIndices(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/demandas/${empresa}/indices`)
    } else {
      return $axios.get(`/demandas/${empresa}/indices?search=${search}`)
    }
  },

  getIndicesById(idIndices) {
    return $axios.get(`/demandas/indices/${idIndices}`)
  },

  saveIndices(indices) {
    return $axios.post(`/demandas/indices/`, indices)
  },

  deleteIndices(empresa, idIndice) {
    return $axios.delete(`/demandas/${empresa}/indices/${idIndice}`)
  },

  // Status
  findStatus(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/demandas/${empresa}/status`)
    } else {
      return $axios.get(`/demandas/${empresa}/status?search=${search}`)
    }
  },

  getStatusById(idStatus) {
    return $axios.get(`/demandas/status/${idStatus}`)
  },

  saveStatus(status) {
    return $axios.post(`/demandas/status/`, status)
  },

  deleteStatus(idStatus) {
    return $axios.delete(`/demandas/status/${idStatus}`)
  },

  // Objetivos
  findObjetivos(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/demandas/${empresa}/objetivos`)
    } else {
      return $axios.get(`/demandas/${empresa}/objetivos?search=${search}`)
    }
  },

  getObjetivosById(idObjetivos) {
    return $axios.get(`/demandas/objetivos/${idObjetivos}`)
  },

  saveObjetivos(objetivos) {
    return $axios.post(`/demandas/objetivos/`, objetivos)
  },

  deleteObjetivos(idObjetivo) {
    return $axios.delete(`/demandas/objetivos/${idObjetivo}`)
  },

  // Áreas
  findAreas(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/demandas/${empresa}/areas`)
    } else {
      return $axios.get(`/demandas/${empresa}/areas?search=${search}`)
    }
  },

  getAreaById(idArea) {
    return $axios.get(`/demandas/areas/${idArea}`)
  },

  saveArea(area) {
    return $axios.post(`/demandas/areas/`, area)
  },

  deleteArea(idArea) {
    return $axios.delete(`/demandas/areas/${idArea}`)
  },
})
