var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu"},[_c('vue-perfect-scrollbar',{staticClass:"scroll",attrs:{"settings":{ suppressScrollX: true, wheelPropagation: false }}},[(_vm.moduloAtual != null)?_c('div',{staticClass:"mx-auto text-dark rounded bg-secondary text-center",staticStyle:{"width":"114px"}},[_c('p',[_c('small',[(_vm.faIconBoolean === true)?_c('fa-icon',{attrs:{"icon":_vm.getIconModuloAtual(_vm.moduloAtual)}}):_vm._e(),_vm._v(" "),(_vm.faIconBoolean === false)?_c('Icons',{staticClass:"icon-modulo mr-1",attrs:{"icon":_vm.getIconModuloAtual(_vm.moduloAtual)}}):_vm._e(),_vm._v("\n          "+_vm._s(_vm.getNomeModuloAtual(_vm.moduloAtual)))],1)])]):_vm._e(),_vm._v(" "),(_vm.moduloAtual == null)?_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.modulosDisponiveis),function(item,idx){return _c('li',{key:("parent_" + idx)},[(_vm.moduloAtual == null)?_c('a',{attrs:{"href":"#","rel":"noopener noreferrer"},on:{"click":function($event){$event.preventDefault();return _vm.setModulo(item.sigla)}}},[(item.faIcon != null)?_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":item.faIcon}}):_vm._e(),_vm._v(" "),(item.icon != null)?_c('Icons',{staticClass:"icon-menu",attrs:{"src":("/icons/" + (item.icon)),"icon":("" + (item.icon))}}):_vm._e(),_vm._v("\n          "+_vm._s(item.label)+"\n        ")],1):_vm._e()])}),0):_vm._e(),_vm._v(" "),(_vm.moduloAtual != null && _vm.isExterno === true)?_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.getItensMenuExterno()),function(item){return _c('li',{key:("parent_" + (item.id)),class:{
          active:
            (_vm.selectedParentMenu === item.id && _vm.viewingParentMenu === '') ||
            _vm.viewingParentMenu === item.id,
        }},[(item.id === ("HOME_" + _vm.moduloAtual))?_c('a',{attrs:{"href":item.to,"rel":"noopener noreferrer"},on:{"click":function($event){$event.preventDefault();return _vm.clearModulos()}}},[_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":"home"}}),_vm._v("\n          Início\n        ")],1):(item.newWindow && item.modulo === _vm.moduloAtual)?_c('a',{attrs:{"href":item.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":item.icon}}),_vm._v("\n          "+_vm._s(item.label)+"\n        ")],1):(
            item.subs && item.subs.length > 0 && item.modulo === _vm.moduloAtual
          )?_c('a',{attrs:{"href":("#" + (item.to))},on:{"click":function($event){$event.preventDefault();return _vm.openSubMenu($event, item)}}},[_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":item.icon}}),_vm._v("\n          "+_vm._s(item.label)+"\n        ")],1):(item.modulo === _vm.moduloAtual)?_c('nuxt-link',{attrs:{"to":item.to},nativeOn:{"click":function($event){return _vm.changeSelectedParentHasNoSubmenu(item.id)}}},[_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":item.icon}}),_vm._v("\n          "+_vm._s(item.label)+"\n        ")],1):_vm._e()],1)}),0):_vm._e(),_vm._v(" "),(_vm.moduloAtual != null && !_vm.isExterno)?_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.getItensMenu()),function(item,itemIndex){return _c('li',{key:("parent_" + (item.id)),class:{
          active:
            (_vm.selectedParentMenu === item.id && _vm.viewingParentMenu === '') ||
            _vm.viewingParentMenu === item.id,
        }},[(
            item.newWindow &&
            item.visible === undefined &&
            item.modulo === _vm.moduloAtual
          )?_c('a',{attrs:{"href":item.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":item.icon}}),_vm._v("\n          "+_vm._s(item.label)+"\n        ")],1):(
            item.subs &&
            item.subs.length > 0 &&
            item.visible === undefined &&
            item.modulo === _vm.moduloAtual
          )?_c('a',{attrs:{"id":itemIndex,"href":("#" + (item.to))},on:{"click":function($event){$event.preventDefault();return _vm.openSubMenu($event, item)}}},[_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":item.icon}}),_vm._v("\n          "+_vm._s(item.label)+"\n        ")],1):_vm._e(),_vm._v(" "),(
            item.visible === undefined &&
            item.modulo === _vm.moduloAtual &&
            item.id === ("HOME_" + _vm.moduloAtual) &&
            _vm.modulosDisponiveis.length > 1
          )?_c('nuxt-link',{attrs:{"to":item.to},on:{"click":function($event){$event.preventDefault();return _vm.clearModulos()}}},[_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":item.icon}}),_vm._v("\n          "+_vm._s(item.label)+"\n        ")],1):(
            item.visible === undefined &&
            item.modulo === _vm.moduloAtual &&
            !item.subs
          )?_c('nuxt-link',{attrs:{"to":item.to},nativeOn:{"click":function($event){return _vm.changeSelectedParentHasNoSubmenu(item.id)}}},[_c('fa-icon',{staticClass:"icon-menu",attrs:{"icon":item.icon}}),_vm._v("\n          "+_vm._s(item.label)+"\n        ")],1):_vm._e()],1)}),0):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }