export default ($axios) => ({
  findStatus(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/statusFicha`)
    } else {
      return $axios.get(`/statusFicha?search=${search}`)
    }
  },

  getStatusById(idStatus) {
    return $axios.get(`/statusFicha/${idStatus}`)
  },

  saveStatus(statusFicha) {
    return $axios.post(`/statusFicha`, statusFicha)
  },

  deleteStatus(idStatus) {
    return $axios.delete(`/statusFicha/${idStatus}`)
  },
})
