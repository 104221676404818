const modulos = [
  {
    name: 'CORRESPONDENCIAS',
    nameAux: 'Correspondências',
    sigla: 'CRP',
    label: 'Correspondências',
    icon: 'bl-corresp',
    file: '/correspondencia',
  },
  {
    name: 'OBRIGACOES',
    nameAux: 'Contrato',
    sigla: 'OBR',
    label: 'Contrato',
    icon: 'bl-obrigacao',
    file: '/obrigacao',
  },
  {
    name: 'NOTIFICACOES',
    nameAux: 'Notificações',
    sigla: 'NTF',
    label: 'Notificações',
    icon: 'bl-notificacao',
    file: '/notificacao',
  },
  {
    name: 'DOCUMENTOS',
    nameAux: 'Documentos',
    sigla: 'GED',
    label: 'GED',
    icon: 'bl-ged',
    file: '/documentos',
  },
  {
    name: 'MEIO_AMBIENTE',
    nameAux: 'Meio Ambiente',
    sigla: 'MAM',
    label: 'Ambiental',
    icon: 'bl-ambiente',
    file: '/ambiental',
  },
  {
    name: 'QUALIDADE',
    nameAux: 'Qualidade',
    sigla: 'QUA',
    label: 'Qualidade',
    icon: 'bl-qualidade',
    file: '/qualidade',
  },
  {
    name: 'DEMANDAS',
    nameAux: 'Demandas',
    sigla: 'DEM',
    label: 'Demanda',
    icon: 'bl-demandas',
    file: '/demandas',
  },
  {
    name: 'PLEITOS',
    nameAux: 'Pleitos',
    sigla: 'PLT',
    label: 'Pleitos',
    icon: 'bl-contratos',
    file: '/pleitos',
  },
  {
    name: 'CONSERVACAO',
    nameAux: 'Operação',
    sigla: 'CSV',
    label: 'Operação',
    icon: 'bl-conserva',
    file: '/conserva',
  },
  {
    name: 'SIG',
    nameAux: 'SIG',
    sigla: 'SIG',
    label: 'SIG',
    faIcon: 'map-location-dot',
    file: '/sig',
  },
  {
    name: 'ATIVOS',
    nameAux: 'Ativos',
    sigla: 'ATV',
    label: 'Ativos',
    faIcon: 'layer-group',
    file: '/ativos',
  },
  {
    name: 'SIGACO',
    nameAux: 'Sigaco',
    sigla: 'GAC',
    label: 'Sigaco',
    faIcon: 'kaaba',
    file: '/sigaco',
  },
  {
    name: 'LABORATORIO',
    nameAux: 'Laboratório',
    sigla: 'LAB',
    label: 'Laboratório',
    faIcon: 'flask',
    file: '/laboratorio',
  },
  {
    name: 'RELACOES_INSTITUCIONAIS',
    nameAux: 'Relações Institucionais',
    sigla: 'RLI',
    label: 'Relações Instituc.',
    faIcon: 'users',
    file: '/relacoes',
  },
  {
    name: 'CADASTROS',
    nameAux: 'Cadastros',
    sigla: 'CAD',
    label: 'Cadastros',
    faIcon: 'table',
    file: '/cadastros',
  },
  {
    name: 'SEGURANCA',
    nameAux: 'Segurança',
    sigla: 'SEG',
    label: 'Segurança',
    faIcon: 'shield-alt',
    file: '/seguranca',
  },
]

export default modulos
