export default ($axios) => ({
  findGrupoServico(empresa, search) {
    if (search === null || search === undefined || search === '') {
      return $axios.get(`/cadastros/lab/${empresa}/gruposServicos`)
    } else {
      return $axios.get(
        `/cadastros/lab/${empresa}/gruposServicos/?search=${search}`
      )
    }
  },
  getByIdGrupoServico(empresa, idGrupo) {
    return $axios.get(`/cadastros/lab/${empresa}/gruposServicos/${idGrupo}`)
  },
  saveGrupoServico(empresa, grupoServico) {
    return $axios.post(`/cadastros/lab/${empresa}/gruposServicos`, grupoServico)
  },
  deleteGrupoServico(empresa, idGrupo) {
    return $axios.delete(`/cadastros/lab/${empresa}/gruposServicos/${idGrupo}`)
  },
})
