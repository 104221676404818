export const state = () => ({
  modulo: null,
})

export const mutations = {
  selectModulo(state, payload) {
    state.modulo = payload.modulo
  },
  clearModulo(state) {
    state.modulo = null
  },
}

export const actions = {
  changeModulo({ commit }, message) {
    commit('selectModulo', { modulo: message })
  },
  clearModulo({ commit }) {
    commit('clearModulo')
  },
}

export const getters = {
  modulo(state) {
    return state.modulo
  },
}
