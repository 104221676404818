//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations } from 'vuex'
import menus from '@/data/menu'
import menusExterno from '@/data/menuExterno'
import modulos from '@/data/modulos'
import Icons from '~/components/svg/Icons.vue'

export default {
  components: {
    Icons,
  },
  props: {
    selectedParentMenu: {
      type: String,
      default: () => '',
    },
    viewingParentMenu: {
      type: String,
      default: () => '',
    },
    changeSelectedParentHasNoSubmenu: {
      type: Function,
      default: () => false,
    },
    openSubMenu: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      menuItems: menus,
      menuExterno: menusExterno,
      modulos,
      modulosDisponiveis: [],
      faIconBoolean: false,
      refCodeDashSigaco: false,
    }
  },
  computed: {
    ...mapGetters({
      moduloAtual: 'appData/modulo',
      menuType: 'menu/getMenuType',
      menuClickCount: 'menu/getMenuClickCount',
      selectedMenuHasSubItems: 'menu/getSelectedMenuHasSubItems',
      isExterno: 'usuario/isExterno',
      modulosUsuario: 'usuario/modulos',
      permissoesUser: 'usuario/permissoes',
      usuario: 'usuario/usuario',
      empresa: 'empresa/empresaAtual',
    }),
  },
  mounted() {
    this.modulosDisponiveis = []
    this.getRefCodeDashSigaco()

    // setTimeout(() => {
    for (const item of this.modulos) {
      if (
        (this.usuario.isAdmin && item.sigla === 'SEG') ||
        this.modulosUsuario.find((modulo) => modulo === item.sigla)
      ) {
        this.modulosDisponiveis.push(item)
      }
    }

    if (this.modulosDisponiveis.length === 1) {
      this.changeModulo(this.modulosDisponiveis[0].sigla)
      const query = this.getUrlVars()

      if (this.modulosDisponiveis[0].sigla === 'GAC') {
        if (query.GAC === undefined && this.refCodeDashSigaco === true) {
          this.$router.push({
            path: `${this.modulosDisponiveis[0].file}/dashboard`,
          })
        } else if (
          query.GAC === undefined &&
          this.refCodeDashSigaco === false
        ) {
          this.$router.push({
            path: `${this.modulosDisponiveis[0].file}/monitoracao/pavimento`,
          })
        }
      } else {
        this.$router.push({
          path: `${this.modulosDisponiveis[0].file}/dashboard`,
        })
      }

      console.log(this.$router)
    }
    // else {
    //   this.getRefCodeDashSigaco()

    //   for (const modulo of this.modulosDisponiveis) {
    //     if (modulo.sigla === 'GAC') {
    //       console.log('this.refCodeDashSigaco=>', this.refCodeDashSigaco)
    //       if (this.refCodeDashSigaco === true) {
    //         this.$router.push({
    //           path: `${modulo.file}/dashboard`,
    //         })
    //       } else {
    //         this.$router.push({
    //           path: `${modulo.file}/monitoracao/pavimento`,
    //         })
    //       }
    //     }
    //   }
    // }
    // }, 1000)
  },
  methods: {
    ...mapActions({
      changeModulo: 'appData/changeModulo',
      clearModulo: 'appData/clearModulo',
    }),
    ...mapMutations({
      changeSideMenuStatus: 'menu/changeSideMenuStatus',
    }),
    setModulo(modulo) {
      this.changeModulo(modulo).then((ok) => {
        const menusData = []
        this.menuItems = []
        this.menuExterno = []
        // this.getRefCodeDashSigaco()

        if (this.isExterno === true) {
          for (const itemMenu of menusExterno) {
            if (itemMenu.modulo && itemMenu.modulo === modulo) {
              menusData.push(itemMenu)
              if (itemMenu.modulo === 'GAC') {
                if (this.refCodeDashSigaco === true) {
                  this.$router.push({
                    path: `/sigaco/dashboard`,
                  })
                } else {
                  this.$router.push({
                    path: `/sigaco/monitoracao/pavimento`,
                  })
                }
              } else {
                console.log()
                if (itemMenu.label === 'Dashboard') {
                  this.$nuxt.$options.router.push({
                    path: itemMenu.to,
                  })
                } else {
                  continue
                }
              }
            }
          }

          this.menuExterno = menusData
        } else {
          for (const itemMenu of menus) {
            if (itemMenu.modulo && itemMenu.modulo === modulo) {
              menusData.push(itemMenu)
              if (itemMenu.modulo === 'GAC') {
                if (this.refCodeDashSigaco === true) {
                  this.$router.push({
                    path: `/sigaco/dashboard`,
                  })
                } else {
                  this.$router.push({
                    path: `/sigaco/monitoracao/pavimento`,
                  })
                }
              } else {
                console.log()
                if (itemMenu.label === 'Dashboard') {
                  this.$nuxt.$options.router.push({
                    path: itemMenu.to,
                  })
                } else {
                  continue
                }
              }
            }
          }

          this.menuItems = menusData
        }
      })
    },
    clearModulos() {
      // Limpa a variavel módulo no vuex
      this.clearModulo()

      // Faz a rota para o dashboard
      this.$nuxt.$options.router.push({
        path: `/dashboard`,
      })

      this.changeSideMenuStatus({
        step: 0,
        classNames: this.menuType,
        selectedMenuHasSubItems: false,
      })
    },
    getItensMenu() {
      const menusData = []
      for (const itemMenu of menus) {
        let flagComPermissaoMenu = false
        let flagComPermissaoSubMenu = false
        let flagNaoTemSubMenu = false
        const subMenu = []

        if (itemMenu.modulo && itemMenu.modulo === this.moduloAtual) {
          // Adiciona o item do Menu se tiver permissão de acesso
          for (const permissao of this.permissoesUser) {
            if (itemMenu.permissao === permissao.nome) {
              flagComPermissaoMenu = true
            }
          }

          // Verifica se existe os itens do SubMenu para mostar no menu
          if (itemMenu.subs !== undefined) {
            if (itemMenu.subs.length > 0) {
              for (const menuSubAux of itemMenu.subs) {
                for (const permissao of this.permissoesUser) {
                  if (permissao.modulo === this.moduloAtual) {
                    if (menuSubAux.permissao === permissao.nome) {
                      // console.log(permissao.nome)
                      // subMenu.push(menuSubAux)
                      flagComPermissaoSubMenu = true
                    }
                  }
                }

                if (menuSubAux.permissao === undefined) {
                  if (flagComPermissaoSubMenu === false) {
                    subMenu.push(menuSubAux)
                  } else {
                    subMenu.push(menuSubAux)
                  }
                }

                if (flagComPermissaoSubMenu === true) {
                  subMenu.push(menuSubAux)
                }
              }
            }
          } else {
            flagNaoTemSubMenu = true
          }

          // Adiciona os itens do menu que não tem permissões
          if (itemMenu.permissao === undefined) {
            if (flagComPermissaoMenu === false) {
              if (subMenu.length > 0) {
                menusData.push(itemMenu)
              }
              if (flagNaoTemSubMenu === true) {
                menusData.push(itemMenu)
              }
            }
          }

          // Adiciona os itens do mennu que tem permissoes
          if (flagComPermissaoMenu === true) {
            if (subMenu.length > 0) {
              menusData.push(itemMenu)
            }
            if (flagNaoTemSubMenu === true) {
              menusData.push(itemMenu)
            }
          }
        }
      }

      return menusData
    },
    getItensMenuExterno() {
      const menusData = []
      for (const itemMenu of menusExterno) {
        let flagComPermissaoMenu = false
        let flagComPermissaoSubMenu = false
        let flagNaoTemSubMenu = false
        const subMenu = []

        if (itemMenu.modulo && itemMenu.modulo === this.moduloAtual) {
          // Adiciona o item do Menu se tiver permissão de acesso
          for (const permissao of this.permissoesUser) {
            if (itemMenu.permissao === permissao.nome) {
              flagComPermissaoMenu = true
            }
          }

          // Verifica se existe os itens do SubMenu para mostar no menu
          if (itemMenu.subs !== undefined) {
            if (itemMenu.subs.length > 0) {
              for (const menuSubAux of itemMenu.subs) {
                for (const permissao of this.permissoesUser) {
                  if (permissao.modulo === this.moduloAtual) {
                    if (menuSubAux.permissao === permissao.nome) {
                      // console.log(permissao.nome)
                      // subMenu.push(menuSubAux)
                      flagComPermissaoSubMenu = true
                    }
                  }
                }

                if (menuSubAux.permissao === undefined) {
                  if (flagComPermissaoSubMenu === false) {
                    subMenu.push(menuSubAux)
                  } else {
                    subMenu.push(menuSubAux)
                  }
                }

                if (flagComPermissaoSubMenu === true) {
                  subMenu.push(menuSubAux)
                }
              }
            }
          } else {
            flagNaoTemSubMenu = true
          }

          // Adiciona os itens do menu que não tem permissões
          if (itemMenu.permissao === undefined) {
            if (flagComPermissaoMenu === false) {
              if (subMenu.length > 0) {
                menusData.push(itemMenu)
              }
              if (flagNaoTemSubMenu === true) {
                menusData.push(itemMenu)
              }
            }
          }

          // Adiciona os itens do mennu que tem permissoes
          if (flagComPermissaoMenu === true) {
            if (subMenu.length > 0) {
              menusData.push(itemMenu)
            }
            if (flagNaoTemSubMenu === true) {
              menusData.push(itemMenu)
            }
          }
        }
      }

      return menusData
    },
    getNomeModuloAtual(sigla) {
      let text = ''
      for (const modulo of this.modulos) {
        if (sigla === modulo.sigla) {
          text = modulo.nameAux
        }
      }

      return text
    },
    getIconModuloAtual(sigla) {
      let text = ''
      for (const modulo of this.modulos) {
        if (sigla === modulo.sigla) {
          if (modulo.icon) {
            text = modulo.icon
            this.faIconBoolean = false
          } else {
            text = modulo.faIcon
            this.faIconBoolean = true
          }
        }
      }

      return text
    },

    getUrlVars() {
      const vars = {}
      window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
          vars[key] = value
        }
      )
      return vars
    },

    getRefCodeDashSigaco() {
      this.permissoesUser.forEach(({ nome }) => {
        if (nome === 'GAC_DASHBOARDS') {
          this.refCodeDashSigaco = true
        }
      })
    },
  },
}
