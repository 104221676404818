const URL_API = '/cadastros/sigaco'

export default ($axios) => ({
  findElementos(siglaEmpresa, tipoAtivo, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        console.log('key==> ', key, ':: ', filtro[key])
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(
      `${URL_API}/${siglaEmpresa}/elementos/${tipoAtivo}/all${parms}`
    )
  },

  getElementoById(siglaEmpresa, tipoAtivo, idElemento) {
    return $axios.get(
      `${URL_API}/${siglaEmpresa}/elementos/${tipoAtivo}/${idElemento}`
    )
  },

  saveElemento(siglaEmpresa, elemento) {
    return $axios.post(`${URL_API}/${siglaEmpresa}`, elemento)
  },

  deleteElemento(siglaEmpresa, tipoAtivo, idElemento) {
    return $axios.delete(
      `${URL_API}/${siglaEmpresa}/elementos/${tipoAtivo}/${idElemento}`
    )
  },
})
