export default ($axios) => ({

    findEixos(search) {
      if (search === null || search === undefined) {
        return $axios.get(`/cadastros/eixoEstrategicos`)
      } else {
        return $axios.get(`/cadastros/eixoEstrategicos?search=${search}`)
      }
    },

    getEixoById(idEixo) {
      return $axios.get(`/cadastros/eixoEstrategicos/${idEixo}`)
    },

    saveEixo(payload) {
      return $axios.post(`/cadastros/eixoEstrategicos`, payload)
    },

    updateEixo(idEixo, payload) {
      return $axios.post(`/cadastros/eixoEstrategicos/${idEixo}`, payload)
    },

    deleteEixo(idEixo) {
      return $axios.delete(`/cadastros/eixoEstrategicos/${idEixo}`)
    },
  })
