export default ($axios) => ({
  findItemAtivo(siglaEmpresa, tipoAtivo, filtro) {
    let parms = ''
    const sizeEmpresa = 0

    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue

          // Empresa
          if (key === 'empresas') {
            if (filtro.empresas.length > 0) {
              if (filtro.empresas.length > sizeEmpresa) {
                for (const empresa of filtro.empresas) {
                  if (parms !== '') parms += '&'
                  parms += key + '=' + empresa
                }
              }
            }
          }
        }

        if (key !== 'empresas') {
          if (filtro[key] !== null) {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
      if (parms !== '') parms = '?' + parms
    }
    return $axios.get(
      `/cadastros/atv/${siglaEmpresa}/itens/${tipoAtivo}/all${parms}`
    )
  },

  getItemAtivoById(siglaEmpresa, idItemAtivo) {
    return $axios.get(`/cadastros/atv/${siglaEmpresa}/itens/${idItemAtivo}`)
  },

  saveItemAtivo(siglaEmpresa, itemAtivo) {
    return $axios.post(`/cadastros/atv/${siglaEmpresa}/itens`, itemAtivo)
  },

  saveItemAtivoDrenagem(siglaEmpresa, itemAtivo) {
    return $axios.post(
      `/cadastros/atv/${siglaEmpresa}/itensDrenagem`,
      itemAtivo
    )
  },

  saveItemAtivoSinalizacaoHorizontal(siglaEmpresa, itemAtivo) {
    return $axios.post(
      `/cadastros/atv/${siglaEmpresa}/itensSinalizacaoHorizontal`,
      itemAtivo
    )
  },

  saveItemAtivoSinalizacaoVertical(siglaEmpresa, itemAtivo) {
    return $axios.post(
      `/cadastros/atv/${siglaEmpresa}/itensSinalizacaoVertical`,
      itemAtivo
    )
  },

  saveItemAtivoTerraplenos(siglaEmpresa, itemAtivo) {
    return $axios.post(
      `/cadastros/atv/${siglaEmpresa}/itensTerrapleno`,
      itemAtivo
    )
  },

  saveItemAtivoEps(siglaEmpresa, itemAtivo) {
    return $axios.post(`/cadastros/atv/${siglaEmpresa}/itensEps`, itemAtivo)
  },

  deleteItemAtivo(siglaEmpresa, idItemAtivo) {
    return $axios.delete(
      `/cadastros/atv/item/${siglaEmpresa}/itens/${idItemAtivo}`
    )
  },

  findAnexos(siglaEmpresa, idAtivo) {
    return $axios.get(`/cadastros/atv/${siglaEmpresa}/itens/${idAtivo}/anexos`)
  },

  saveAnexo(siglaEmpresa, idAtivo, anexo) {
    return $axios.post(
      `/cadastros/atv/${siglaEmpresa}/itens/${idAtivo}/anexos`,
      anexo
    )
  },

  deleteAnexo() {},
})
