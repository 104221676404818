export default ($axios) => ({
  findCidades(search) {
    if (search === null || search === undefined || search === '') {
      return $axios.get(`/cidades`)
    } else {
      return $axios.get(`/cidades/?search=${search}`)
    }
  },

  getCidadeById(idCidade) {
    return $axios.get(`/cidades/${idCidade}`)
  },

  saveCidade(cidade) {
    return $axios.post('/cidades', cidade)
  },

  deleteCidade(idCidade) {
    return $axios.delete(`/cidades/${idCidade}`)
  },
})
