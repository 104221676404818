export default ($axios) => ({
  findGrupos(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/analises/grupos`)
    } else {
      return $axios.get(`/analises/grupos?search=${search}`)
    }
  },

  getGrupoById(idGrupo) {
    return $axios.get(`/analises/grupos/${idGrupo}`)
  },

  deleteGrupo(idGrupo) {
    return $axios.delete(`/analises/grupos/${idGrupo}`)
  },

  saveGrupo(grupo) {
    return $axios.post(`/analises/grupos`, grupo)
  },

  saveOrderGrupos(grupos) {
    return $axios.post(`/analises/grupos/ordens`, grupos)
  },
})
