export default ($axios) => ({

    findStatus(search) {
      if (search === null || search === undefined) {
        return $axios.get(`/cadastros/amb/statusAprovacoesAgencia`)
      } else {
        return $axios.get(`/cadastros/amb/statusAprovacoesAgencia?search=${search}`)
      }
    },
    
    getStatusById(idStatusAprovacaoAgencia) {
      return $axios.get(`/cadastros/amb/statusAprovacoesAgencia/${idStatusAprovacaoAgencia}`)
    },

    saveStatus(payload) {
      return $axios.post(`/cadastros/amb/statusAprovacoesAgencia/`, payload)
    },
    
    updateStatus(idStatusAprovacaoAgencia, payload) {
      return $axios.post(`/cadastros/amb/statusAprovacoesAgencia/${idStatusAprovacaoAgencia}`, payload)
    },
  
    deleteStatus(idStatusAprovacaoAgencia) {
      return $axios.delete(`/cadastros/amb/statusAprovacoesAgencia/${idStatusAprovacaoAgencia}`)
    },
  })