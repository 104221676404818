export default ($axios) => ({
  findTipos(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/tiposInspecaoAmbiental`)
    } else {
      return $axios.get(`/tiposInspecaoAmbiental?search=${search}`)
    }
  },

  saveTipo(tipoInspecaoAmbiental) {
    return $axios.post(`/tiposInspecaoAmbiental`, tipoInspecaoAmbiental)
  },

  deleteTipo(idTipoInspecaoAmbiental) {
    return $axios.delete(
      `/tiposInspecaoAmbiental/${idTipoInspecaoAmbiental}`
    )
  },
})
