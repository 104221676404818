export default ($axios) => ({
  findPareceres(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/pareceres`)
    } else {
      return $axios.get(`/cadastros/amb/pareceres?search=${search}`)
    }
  },

  saveParecer(payload) {
    return $axios.post(`/cadastros/amb/pareceres`, payload)
  },

  getParecerById(idParecer) {
    return $axios.get(`/cadastros/amb/pareceres/${idParecer}`)
  },

  updateParecer(idParecer, payload) {
    return $axios.post(`/cadastros/amb/pareceres/${idParecer}`, payload)
  },

  deleteParecer(idParecer) {
    return $axios.delete(`/cadastros/amb/pareceres/${idParecer}`)
  },
})
