export default ($axios) => ({
  findTiposLicencaAmbiental(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/tiposDocumento/${siglaEmpresa}/LIC`)
    } else {
      return $axios.get(
        `/tiposDocumento/${siglaEmpresa}/LIC?search=${search}`
      )
    }
  },

  getTipoLicencaAmbientalById(idTipoLicencaAmbiental) {
    return $axios.get(`/tiposDocumento/${idTipoLicencaAmbiental}`)
  },

  saveTipoLicencaAmbiental(tipoLicencaAmbiental) {
    return $axios.post(`/tiposDocumento`, tipoLicencaAmbiental)
  },

  deleteTipoLicencaAmbiental(siglaEmpresa, idTipoLicencaAmbiental) {
    return $axios.delete(
      `/tiposDocumento/${siglaEmpresa}/${idTipoLicencaAmbiental}`
    )
  },
})
