export default ($axios) => ({
  getFicha(empresa, idRac, seqFicha) {
    return $axios.get(`/racs/${empresa}/${idRac}/fichas/${seqFicha}`)
  },

  findFichas(empresa, idRac, search) {
    let parms = ''
    if (search !== null && search !== undefined) parms += `search=${search}`
    if (parms !== undefined) parms = `?${parms}`

    return $axios.get(`/racs/${empresa}/${idRac}/fichas${parms}`)
  },

  saveFicha(empresa, idRac, token, ficha, anexos) {
    console.log('anexos=>', anexos)

    const formData = new FormData()

    if (ficha !== undefined) {
      ficha.usuario = null
      formData.append('ficha', JSON.stringify(ficha))
    }

    if (anexos.length > 0) {
      const anexosRaw = []
      console.log('anexos2=>', anexos)
      for (const anexo of anexos) {
        console.log('anexo=>', anexo)
        formData.append('filesAnexos', anexo.file)

        let fileName = ''

        if (anexo.file?.name) fileName = anexo.file.name
        else if (anexo.anexo?.nome) fileName = anexo.anexo.nome

        anexosRaw.push({
          comentario: anexo.comentario,
          anexo: {
            nome: fileName,
          },
        })
      }

      formData.append('anexos', JSON.stringify(anexosRaw))
    }

    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(`/racs/${empresa}/${idRac}/fichas`, formData)
  },

  deleteFicha(idRac, empresa, seqFicha) {
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.delete(
      `/racs/${empresa}/${idRac}/fichas/${seqFicha}`,
      requestCfg
    )
  },

  findAnexosFicha(empresa, idRac, seqFicha) {
    return $axios.get(`/racs/${empresa}/${idRac}/fichas/${seqFicha}/anexos`)
  },

  deleteAnexoFicha(siglaEmpresa, idRac, seqFicha, seqAnexo) {
    return $axios.delete(
      `/racs/${siglaEmpresa}/${idRac}/fichas/${seqFicha}/anexos/${seqAnexo}`
    )
  },

  createAnexoFicha(siglaEmpresa, idFicha, seqFicha, token, anexo) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/racs/${siglaEmpresa}/${idFicha}/${seqFicha}/fichas/anexos`,
      anexo
    )
  },
})
