export default ($axios) => ({
  findCiclosMonitoracoes(empresa, tipoAtivo, searchTxt) {
    let parms = ''
    if (tipoAtivo != null) parms += '?tipoAtivo=' + tipoAtivo

    if (searchTxt !== undefined && searchTxt !== null) {
      if (parms !== '') parms += '&'
      else parms += '?'
      parms += 'search=' + searchTxt
    }

    return $axios.get(`/cadastros/atv/ciclosMonitoracao/${empresa}${parms}`)
  },

  findCiclosMonitoracaoAll(empresa) {
    return $axios.get(`/cadastros/atv/ciclosMonitoracao/${empresa}/all`)
  },

  findCicloMonitoracaoByID(empresa, idCiclo) {
    return $axios.get(`/cadastros/atv/ciclosMonitoracao/${empresa}/${idCiclo}`)
  },

  saveCicloMonitoracao(empresa, cicloMonitoracao) {
    return $axios.post(
      `/cadastros/atv/ciclosMonitoracao/${empresa}`,
      cicloMonitoracao
    )
  },

  deleteCicloMonitoracao(empresa, idCiclo) {
    return $axios.delete(
      `/cadastros/atv/ciclosMonitoracao/${empresa}/${idCiclo}`
    )
  },

  encerrarCicloMonitoracao(empresa, idCiclo) {
    return $axios.post(
      `/cadastros/atv/ciclosMonitoracao/${empresa}/${idCiclo}/encerrar`
    )
  },

  reabrirCicloMonitoracao(empresa, idCiclo) {
    return $axios.post(
      `/cadastros/atv/ciclosMonitoracao/${empresa}/${idCiclo}/reabrir`
    )
  },

  findAnexosCiclo(empresa, idCiclo) {
    return $axios.get(
      `/cadastros/atv/ciclosMonitoracao/${empresa}/${idCiclo}/anexos`
    )
  },

  saveAnexoCiclo(empresa, idCiclo, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/cadastros/atv/ciclosMonitoracao/${empresa}/${idCiclo}/anexos`,
      formData
    )
  },

  deleteAnexoCiclo(empresa, idCiclo, idAnexoCiclo) {
    return $axios.delete(
      `/cadastros/atv/ciclosMonitoracao/${empresa}/${idCiclo}/anexos/${idAnexoCiclo}`
    )
  },
})
