import { authHeaderMultipart } from '~/helpers'

export default ($axios) => ({
  getFicha(empresa, idInspecaoAmbiental, seqFicha) {
    return $axios.get(
      `/inspecoes/${empresa}/${idInspecaoAmbiental}/fichas/${seqFicha}`
    )
  },

  findFichas(empresa, idInspecaoAmbiental, search) {
    let parms = ''
    if (search !== null && search !== undefined) parms += `search=${search}`
    if (parms !== undefined) parms = `?${parms}`

    return $axios.get(
      `/inspecoes/${empresa}/${idInspecaoAmbiental}/fichas${parms}`
    )
  },

  saveFicha(empresa, idInspecaoAmbiental, ficha, anexos) {
    const requestCfg = {
      headers: authHeaderMultipart(),
    }

    const formData = new FormData()

    if (ficha !== undefined) {
      formData.append('ficha', JSON.stringify({ ...ficha, usuario: null }))
    }

    if (anexos !== undefined) {
      const anexosRaw = []
      for (const anexo of anexos) {
        formData.append('filesAnexos', anexo.file)
        anexosRaw.push({
          comentario: anexo.comentario,
          anexo: {
            nome: anexo.file.name,
          },
        })
      }

      formData.append('anexos', JSON.stringify(anexosRaw))
    }

    return $axios.post(
      `/inspecoes/${empresa}/${idInspecaoAmbiental}/fichas`,
      formData,
      requestCfg
    )
  },

  deleteFicha(idInspecaoAmbiental, empresa, seqFicha) {
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.delete(
      `/inspecoes/${empresa}/${idInspecaoAmbiental}/fichas/${seqFicha}`,
      requestCfg
    )
  },

  createAnexo(siglaEmpresa, idInspecaoAmbiental, seqFicha, formData) {
    const requestCfg = {
      method: 'POST',
      headers: authHeaderMultipart(),
    }

    return $axios.post(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/fichas/${seqFicha}/anexos`,
      formData,
      requestCfg
    )
  },

  findAnexosFicha(empresa, idInspecaoAmbiental, seqFicha) {
    return $axios.get(
      `/inspecoes/${empresa}/${idInspecaoAmbiental}/fichas/${seqFicha}/anexos`
    )
  },

  deleteAnexoFicha(siglaEmpresa, idInspecaoAmbiental, seqFicha, seqAnexo) {
    return $axios.delete(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/fichas/${seqFicha}/anexos/${seqAnexo}`
    )
  },

  findAssinaturasFicha(empresa, idInspecaoAmbiental) {
    return $axios.get(
      `/inspecoes/${empresa}/${idInspecaoAmbiental}/fichas/assinaturas`
    )
  },
})
