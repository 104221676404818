export const state = () => ({
    equipes: [],
  })
  
  export const mutations = {
    fill(state, rows) {
      state.equipes = rows
    },
  }
  
  export const actions = {
    find({ commit }, empresa) {
      this.$equipeService
        .findEquipes(empresa)
        .then((response) => {
          const equipes = []
          for (const item of response.data) {
            equipes.push({
              id: item.id,
              descricao: item.descricao,
              tipo: item.tipo,
            })
          }
  
          commit('fill', equipes)
        })
        .catch((error) => {
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error',
          })
        })
    },
  }
  
  export const getters = {
    equipes(state) {
      return state.equipes
    },
  }
  