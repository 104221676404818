export const state = () => ({
  obras: [],
})

export const mutations = {
  fill(state, rows) {
    state.obras = rows
  },
}

export const actions = {
  find({ commit }, empresa) {
    this.$obraService
      .findObras(empresa)
      .then((response) => {
        const obras = []
        for (const item of response.data) {
          if (item.status === 'P' || item.status === 'A') {
            obras.push(item)
          }
        }

        commit('fill', obras)
      })
      .catch((error) => {
        this.$notify({
          title: 'Erro',
          text: error.response ? error.response.data.message : error,
          type: 'error',
        })
      })
  },
}

export const getters = {
  obras(state) {
    return state.obras
  },
}
