export default ($axios) => ({

    findTipoRequisitoLegal(empresa, search) {
      if (search === null || search === undefined) {
        return $axios.get(`/cadastros/amb/${empresa}/tiposRequisitosLegais`)
      } else {
        return $axios.get(`/cadastros/amb/${empresa}/tiposRequisitosLegais?search=${search}`)
      }
    },

    getTipoRequisitoLegalById(empresa, idTipo) {
      return $axios.get(`/cadastros/amb/${empresa}/tiposRequisitosLegais/${idTipo}`)
    },

    saveTipoRequisitoLegal(empresa, payload) {
      return $axios.post(`/cadastros/amb/${empresa}/tiposRequisitosLegais`, payload)
    },

    updateTipoRequisitoLegal(empresa, idTipo, payload) {
      return $axios.put(`/cadastros/amb/${empresa}/tiposRequisitosLegais${idTipo}`, payload)
    },

    deleteTipoRequisitoLegal(empresa, idTipo) {
      return $axios.delete(`/cadastros/amb/${empresa}/tiposRequisitosLegais/${idTipo}`)
    },
  })
