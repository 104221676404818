export default ($axios) => ({
  findUnidadeMedida(search) {
    if (search === null || search === undefined || search === '') {
      return $axios.get(`/cadastros/unidadesMedida/`)
    } else {
      return $axios.get(`/cadastros/unidadesMedida?search=${search}`)
    }
  },

  getUnidadeMedidaById(idUnidadeMedida) {
    return $axios.get(`/cadastros/unidadesMedida/${idUnidadeMedida}`)
  },

  saveUnidadeMedida(unidadeMedida) {
    return $axios.post(`/cadastros/unidadesMedida`, unidadeMedida)
  },

  deleteUnidadeMedida(idUnidadeMedida) {
    return $axios.delete(`/cadastros/unidadesMedida/${idUnidadeMedida}`)
  },
})
