export default ($axios) => ({
  findElementoArboreo(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/${siglaEmpresa}/elementosArboreos`)
    } else {
      return $axios.get(
        `/cadastros/amb/${siglaEmpresa}/elementosArboreos?search=${search}`
      )
    }
  },

  getElementoArboreoById(siglaEmpresa, idElementoArboreo) {
    return $axios.get(
      `/cadastros/amb/${siglaEmpresa}/elementosArboreos/${idElementoArboreo}`
    )
  },

  saveElementoArboreo(siglaEmpresa, elementoArboreo) {
    return $axios.post(
      `/cadastros/amb/${siglaEmpresa}/elementosArboreos`,
      elementoArboreo
    )
  },

  deleteElementoArboreo(siglaEmpresa, idElementoArboreo) {
    return $axios.delete(
      `/cadastros/amb/${siglaEmpresa}/elementosArboreos/${idElementoArboreo}`
    )
  },
})
