export default ($axios) => ({
  findUtilizacao(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/tiposStatus?tipo=I`)
    } else {
      return $axios.get(`/cadastros/amb/tiposStatus?tipo=I&search=${search}`)
    }
  },

  getUtilizacaoById(idStatusUtilizacao) {
    return $axios.get(`/cadastros/amb/tiposStatus/${idStatusUtilizacao}`)
  },

  saveUtilizacao(payload) {
    return $axios.post(`/cadastros/amb/tiposStatus`, payload)
  },

  updateUtilizacao(idStatusUtilizacao, payload) {
    return $axios.post(`/cadastros/tiposStatus/${idStatusUtilizacao}`, payload)
  },

  deleteUtilizacao(idStatusUtilizacao) {
    return $axios.delete(`/cadastros/amb/tiposStatus/${idStatusUtilizacao}`)
  },
})
