const URL_API = '/correspondencias'

export default ($axios) => ({
  findCorrespondencias(empresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        console.log('key==> ', key, ':: ', filtro[key])
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms
    return $axios.get(`${URL_API}/${empresa}${parms}`)
  },

  getById(empresa, idCorrespondencia) {
    return $axios.get(`${URL_API}/${empresa}/${idCorrespondencia}`)
  },

  save(empresa, correspondencia, anexos, destinatarios) {
    const formData = new FormData()

    if (correspondencia !== undefined) {
      formData.append(
        'correspondencia',
        JSON.stringify(correspondencia, (key, value) => {
          if (value !== null) return value
        })
      )
    }

    if (anexos !== undefined) {
      // const anexosRaw = []
      for (const anexo of anexos) {
        formData.append('file', anexo.file)
        // anexosRaw.push({
        //   anexo: {
        //     nome: anexo.file.name,
        //   },
        // })
      }

      // formData.append('anexos', JSON.stringify(anexosRaw))
    }

    if (destinatarios !== undefined)
      formData.append('destinatarios', JSON.stringify(destinatarios))

    return $axios.post(`${URL_API}/${empresa}/registrar`, formData)
  },

  findAnexos(empresa, idCorrespondencia) {
    return $axios.get(`${URL_API}/${empresa}/${idCorrespondencia}/anexos`)
  },

  getAnexoByResponsavel(empresa, idCorrespondencia, idResponsavel, tipo) {
    return $axios.get(
      `${URL_API}/${empresa}/${idCorrespondencia}/${idResponsavel}/${tipo}/anexosResponsavel`
    )
  },

  getAnexoByTipo(empresa, idCorrespondencia, tipo, sequencia) {
    return $axios.get(
      `${URL_API}/${empresa}/${idCorrespondencia}/${tipo}/anexosTipo/${sequencia}`
    )
  },

  getAnexoByAssinatura(empresa, idAssinatura) {
    return $axios.get(`${URL_API}/${empresa}/${idAssinatura}`)
  },

  downloadAnexo(empresa, modulo, uuid) {
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.get(
      `/downloads/${empresa}/anexos/${modulo}/${uuid}`,
      requestCfg
    )
  },

  saveAnexo(empresa, idCorrespondencia, tipoAnexo, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `${URL_API}/${empresa}/${idCorrespondencia}/anexos/${tipoAnexo}`,
      formData
    )
  },

  deleteAnexo(empresa, idCorrespondencia, sequencia) {
    return $axios.delete(
      `${URL_API}/${empresa}/${idCorrespondencia}/anexos/${sequencia}`
    )
  },

  findHistoricoAtividades(empresa, idCorrespondencia) {
    return $axios.get(
      `${URL_API}/${empresa}/${idCorrespondencia}/historicoAtividades`
    )
  },

  findResumoPeriodos(empresa, ano) {
    return $axios.get(`${URL_API}/${empresa}/resumo?ano=${ano}`)
  },

  findModelosNumeracao(empresa, idArea, idEntidade) {
    let route = `/cadastros/crp/${empresa}/modelosNumeracao`
    if (idArea) route += '?idArea=' + idArea + '&'
    if (idEntidade) route += '?idEntidade=' + idEntidade

    return $axios.get(route)
  },
  getModeloNumeracao(empresa, idModeloNumeracao) {
    return $axios.get(
      `/cadastros/crp/${empresa}/${idModeloNumeracao}/modelosNumeracao`
    )
  },
  findModelosMinuta(empresa, idArea, idEntidade) {
    let route = `/cadastros/crp/${empresa}/modelosMinuta`
    if (idArea) route += '?idArea=' + idArea + '&'
    if (idEntidade) route += '?idEntidade=' + idEntidade

    return $axios.get(route)
  },

  findReferencias(empresa, idCorrespondencia) {
    return $axios.get(`${URL_API}/${empresa}/${idCorrespondencia}/referencias`)
  },

  // Modelos de Assinatura Digital

  findModelosAssinatura(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/crp/${empresa}/modelosAssinatura`)
    } else {
      return $axios.get(
        `/cadastros/crp/${empresa}/modelosAssinatura?search=${search}`
      )
    }
  },
  getModeloAssinatura(empresa, idModeloAssinatura) {
    return $axios.get(
      `/cadastros/crp/${empresa}/${idModeloAssinatura}/modelosAssinatura`
    )
  },

  saveModelosAssinatura(empresa, modelo) {
    return $axios.post(`/cadastros/crp/${empresa}/modelosAssinatura`, modelo)
  },

  // Relatorios
  findRelatorio(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/relatorios/pendencias/${empresa}`)
    } else {
      return $axios.get(`/relatorios/pendencias/${empresa}?search=${search}`)
    }
  },

  executeEncerrar(empresa, payload) {
    return $axios.post(`/correspondencias/${empresa}/executeEncerrar`, payload)
  },

  saveProtocoloInterno(empresa, idCorrespondencia, idModelo) {
    return $axios.post(
      `/correspondencias/${empresa}/${idCorrespondencia}/${idModelo}/numerosProtocoloInterno`
    )
  },

  deleteProtocoloInterno(empresa, idCorrespondencia) {
    return $axios.delete(
      `/correspondencias/${empresa}/${idCorrespondencia}/numerosProtocoloInterno`
    )
  },

  saveNroMinuta(empresa, idCorrespondencia, idModelo) {
    return $axios.post(
      `/correspondencias/${empresa}/${idCorrespondencia}/${idModelo}/numerosMinuta`
    )
  },

  deleteNroMinuta(empresa, idCorrespondencia) {
    return $axios.delete(
      `/correspondencias/${empresa}/${idCorrespondencia}/numerosMinuta`
    )
  },

  postarCarta(empresa, idCorrespondencia) {
    return $axios.post(
      `/correspondencias/${empresa}/${idCorrespondencia}/executePostagem`
    )
  },

  findRelacoes(empresa, idCorrespondencia) {
    return $axios.get(
      `/correspondencias/${empresa}/${idCorrespondencia}/relacoes`
    )
  },

  findDestinatarios(empresa, idCorrespondencia) {
    return $axios.get(
      `/correspondencias/${empresa}/${idCorrespondencia}/destinatarios`
    )
  },

  findDestinatariosByTipo(empresa, idCorrespondencia, tipo) {
    return $axios.get(
      `/correspondencias/${empresa}/${idCorrespondencia}/${tipo}/destinatarios`
    )
  },

  saveDestinatarios(empresa, idCorrespondencia, payload) {
    return $axios.post(
      `/correspondencias/${empresa}/${idCorrespondencia}/destinatarios`,
      payload
    )
  },

  findDestinatariosById(empresa, idCorrespondencia, sequencia) {
    return $axios.get(
      `/correspondencias/${empresa}/${idCorrespondencia}/destinatarios/${sequencia}`
    )
  },

  deleteDestinatario(empresa, idCorrespondencia, sequencia) {
    return $axios.delete(
      `/correspondencias/${empresa}/${idCorrespondencia}/destinatarios/${sequencia}`
    )
  },

  findResponsaveisAprovacao(empresa, idCorrespondencia) {
    return $axios.get(
      `/correspondencias/${empresa}/${idCorrespondencia}/responsaveisAprovacao`
    )
  },

  saveResponsavelAprovacao(empresa, idCorrespondencia, payload) {
    return $axios.post(
      `/correspondencias/${empresa}/${idCorrespondencia}/responsaveisAprovacao`,
      payload
    )
  },

  deleteResponsavelAprovacao(empresa, idCorrespondencia, sequencia) {
    return $axios.delete(
      `/correspondencias/${empresa}/${idCorrespondencia}/responsaveisAprovacao/${sequencia}`
    )
  },

  modificaFluxoAprovacao(empresa, idCorrespondencia, payload) {
    return $axios.post(
      `/correspondencias/${empresa}/${idCorrespondencia}/modificarFluxoAprovacao`,
      payload
    )
  },

  saveResponsavelResposta(empresa, idCorrespondencia, idUsuarioResposta) {
    return $axios.post(
      `/correspondencias/${empresa}/${idCorrespondencia}/responsavelResposta/${idUsuarioResposta}`
    )
  },
})
