export default ($axios) => ({
  findPrecoUnitarios(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/atv/${siglaEmpresa}/precosUnitarios`)
    } else {
      return $axios.get(
        `/cadastros/atv/${siglaEmpresa}/precosUnitarios?search=${search}`
      )
    }
  },

  getPrecoUnitarioById(siglaEmpresa, idPrecoUnitario) {
    return $axios.get(
      `/cadastros/atv/${siglaEmpresa}/precosUnitarios/${idPrecoUnitario}`
    )
  },

  savePrecoUnitario(siglaEmpresa, precoUnitario) {
    return $axios.post(`/cadastros/atv/${siglaEmpresa}/precosUnitarios`, precoUnitario)
  },

  deletePrecoUnitario(siglaEmpresa, idPrecoUnitario) {
    return $axios.delete(
      `/cadastros/atv/${siglaEmpresa}/precosUnitarios/${idPrecoUnitario}`
    )
  },
})
