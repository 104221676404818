export const state = () => ({
  idAutorizacaoRap: null,
})

export const mutations = {
  changeAutorizacaoRap(state, autorizacaoRap) {
    state.idAutorizacaoRap = autorizacaoRap.id.idAutorizacao
  },
  clearAutorizacaoRap(state) {
    state.idAutorizacaoRap = null
  },
}

export const actions = {
  setAutorizacaoRap({ commit }, autorizacaoRap) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeAutorizacaoRap', autorizacaoRap)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearAutorizacaoRap')
  },
}

export const getters = {
  idAutorizacaoRap(state) {
    return state.idAutorizacaoRap
  },
}
