export default ($axios) => ({
  findGravidades(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/gravidades`)
    } else {
      return $axios.get(`/gravidades?search=${search}`)
    }
  },

  getGravidadeById(idGravidade) {
    return $axios.get(`/gravidades/${idGravidade}`)
  },

  saveGravidade(gravidade) {
    return $axios.post(`/gravidades`, gravidade)
  },

  deleteGravidade(idGravidade) {
    return $axios.delete(`/gravidades/${idGravidade}`)
  },
})
