export const state = () => ({
  dados: {},
})

export const mutations = {
  changeDados(state, dados) {
    state.dados = dados
  },
  clearDados(state) {
    state.dados = {}
  },
}

export const actions = {
  setDados({ commit }, dados) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeDados', dados)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearDados')
  },
}

export const getters = {
  dados(state) {
    return state.dados
  },
}
