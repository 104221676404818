export default ($axios) => ({
  findTiposPontoControle(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pontosControle/tipos`)
    } else {
      return $axios.get(`/pontosControle/tipos?search=${search}`)
    }
  },

  getTipoPontoControleById(idTipoPontoControle) {
    return $axios.get(`/pontosControle/tipos/${idTipoPontoControle}`)
  },

  saveTipoPontoControle(tipoPontoControle) {
    return $axios.post(`/pontosControle/tipos`, tipoPontoControle)
  },

  deleteTipoPontoControle(idTipoPontoControle) {
    return $axios.delete(`/pontosControle/tipos/${idTipoPontoControle}`)
  },
})
