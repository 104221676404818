export default ($axios) => ({
  findEstacoes(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/atv/${siglaEmpresa}/sinalizacoes/estacoes`)
    } else {
      return $axios.get(
        `/cadastros/atv/${siglaEmpresa}/sinalizacoes/estacoes?search=${search}`
      )
    }
  },

  getEstacoesById(siglaEmpresa, idEstacao) {
    return $axios.get(
      `/cadastros/atv/${siglaEmpresa}/sinalizacoes/estacoes/${idEstacao}`
    )
  },

  saveEstacao(siglaEmpresa, estacao) {
    return $axios.post(
      `/cadastros/atv/${siglaEmpresa}/sinalizacoes/estacoes`,
      estacao
    )
  },

  deleteEstacao(siglaEmpresa, idEstacao) {
    return $axios.delete(
      `/cadastros/atv/${siglaEmpresa}/sinalizacoes/estacoes/${idEstacao}`
    )
  },
})
