export default ($axios) => ({
  findIqmas(empresa, filtro = {}) {
    let parms = ''
    if (filtro) {
      Object.entries(filtro).forEach(([key, value]) => {
        if (value === undefined || value === null) return
        if (Array.isArray(value)) {
          value.forEach((col) => {
            if (parms !== '') parms += '&'
            parms += key + '=' + col
          })
        } else {
          if (parms !== '') parms += '&'

          parms += key + '=' + value
        }
      })
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/ambiental/iqmas/${empresa}${parms}`)
  },

  saveIqmas(empresa, payload) {
    return $axios.post(`/ambiental/iqmas/${empresa}/gerar`, payload)
  },

  deleteIqmas(empresa, idIqma) {
    return $axios.delete(`/ambiental/iqmas/${empresa}/${idIqma}`)
  },
  getIqmasById(empresa, idIqma) {
    return $axios.get(`/ambiental/iqmas/${empresa}/${idIqma}`)
  },
  getIqmasByChecklist(empresa, idIqma) {
    return $axios.get(`/ambiental/iqmas/${empresa}/${idIqma}/checklists`)
  },
})
