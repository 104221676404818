export function authHeaderMultipart() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem("token"));

  if (user) {
    return {
      "Authorization": "Bearer " + user,
      "Content-Type": "multipart/form-data",
    };
  } else {
    return {};
  }
}
