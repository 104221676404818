export default ($axios) => ({
  findEquipes(empresa, filtro) {
    let parms = ''
    if (filtro) {
      Object.entries(filtro).forEach(([key, value]) => {
        if (value === undefined || value === null) return
        if (Array.isArray(value) && value.length === 0) return

        if (parms !== '') parms += '&'

        parms += key + '=' + value
      })
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/equipes/${empresa}${parms}`)
  },

  getEquipeById(empresa, idEquipes) {
    return $axios.get(`/equipes/${empresa}/${idEquipes}`)
  },

  saveEquipe(equipe) {
    return $axios.post(`/equipes`, equipe)
  },

  deleteEquipe(idEquipe) {
    return $axios.delete(`/equipes/${idEquipe}`)
  },

  findResponsaveisByEquipe(siglaEmpresa, idEquipe) {
    return $axios.get(`/equipes/${siglaEmpresa}/${idEquipe}/responsaveis`)
  },

  saveResponsaveisByEquipe(responsaveisEquipe, siglaEmpresa, idEquipe) {
    return $axios.post(
      `/equipes/${siglaEmpresa}/${idEquipe}/responsaveis`,
      responsaveisEquipe
    )
  },

  deleteResponsavelByEquipe(siglaEmpresa, idEquipe, seqResponsavel) {
    return $axios.delete(
      `/equipes/${siglaEmpresa}/${idEquipe}/responsaveis/${seqResponsavel}`
    )
  },

  findREmailsByEquipe(siglaEmpresa, idEquipe) {
    return $axios.get(`/equipes/${siglaEmpresa}/${idEquipe}/emails`)
  },

  saveEmailByEquipe(emailEquipe, siglaEmpresa, idEquipe) {
    return $axios.post(
      `/equipes/${siglaEmpresa}/${idEquipe}/emails`,
      emailEquipe
    )
  },

  deleteEmailByEquipe(siglaEmpresa, idEquipe, seqEmail) {
    return $axios.delete(
      `/equipes/${siglaEmpresa}/${idEquipe}/emails/${seqEmail}`
    )
  },

  findFornecedores(empresa) {
    return $axios.get(`/equipes/${empresa}/fornecedores`)
  },

  findContratos(empresa, idFornecedor) {
    return $axios.get(
      `/equipes/${empresa}/fornecedores/${idFornecedor}/contratos`
    )
  },
})
