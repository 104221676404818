export const state = () => ({
  idBoaPratica: null || undefined,
})

export const mutations = {
  changeBoaPratica(state, boaPratica) {
    state.idBoaPratica = boaPratica.id
  },
  clearBoaPratica(state) {
    state.idBoaPratica = null
  },
}

export const actions = {
  setBoaPratica({ commit }, boaPratica) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeBoaPratica', boaPratica)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearBoaPratica')
  },
}

export const getters = {
  idBoaPratica(state) {
    return state.idBoaPratica;
  }
}