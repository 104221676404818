export default ($axios) => ({
  findRegrasOuro(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/regrasOuro`)
    } else {
      return $axios.get(`/regrasOuro?search=${search}`)
    }
  },

  getRegraDeOuroById(idRegraDeOuro) {
    return $axios.get(`/regrasOuro/${idRegraDeOuro}`)
  },

  saveRegraDeOuro(regraOuro) {
    return $axios.post(`/regrasOuro`, regraOuro)
  },

  deleteRegraDeOuro(idRegraDeOuro) {
    return $axios.delete(`/regrasOuro/${idRegraDeOuro}`)
  },
})
