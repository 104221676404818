const URL_API = '/seguranca'

export default ($axios) => ({
  findGruposAcesso(search) {
    if (search !== undefined) {
      return $axios.get(`${URL_API}/grupos?search=${search}`)
    } else {
      return $axios.get(`${URL_API}/grupos`)
    }
  },

  getGrupoAcessoById(idGrupo) {
    return $axios.get(`${URL_API}/grupos/${idGrupo}`)
  },

  getUsuariosByGrupo(idGrupo) {
    return $axios.get(`${URL_API}/grupos/${idGrupo}/usuarios`);
  },

  findUsuariosByGrupo(idGrupo) {
    return $axios.get(`${URL_API}/grupos/${idGrupo}/usuarios`)
  },

  saveGrupoAcesso(grupoAcesso) {
    return $axios.post(`${URL_API}/grupos`, grupoAcesso)
  },

  deleteGrupoAcesso(idGrupo) {
    return $axios.delete(`${URL_API}/grupos/${idGrupo}`)
  },

  findModulos() {
    return $axios.get(`/usuarios/permissoes/all`)
  },

  findPermissaoByGrupo(idGrupo) {
    return $axios.get(`/usuarios/${idGrupo}/permissoes`)
  },

  savePermissoesByGrupo(permissoes, idGrupo, modulo) {
    return $axios.post(
      `/usuarios/permissoesAcesso/${idGrupo}/${modulo}`,
      permissoes
    )
  },

  findAuditoria(empresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/usuarios/${empresa}/auditorias${parms}`)
  },
})
