export const defaultMenuType = "menu-default"; // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = "/app";
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const defaultLocale = "pt";
export const defaultDirection = "ltr";
export const localeOptions = [
  { id: "pt", name: "Português Brasil LTR", direction: "ltr" },
  { id: "en", name: "English LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English RTL", direction: "rtl" }
];

export const isAuthGuardActive = true;
export const themeRadiusStorageKey = "theme_radius";
export const themeSelectedColorStorageKey = "theme_selected_color";
export const defaultColor = "light.blueolympic";
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel"
];
