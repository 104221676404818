export default ($axios) => ({
  findOcorrenciasFlorestais(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/${siglaEmpresa}/ocorrenciasFlorestais`)
    } else {
      return $axios.get(
        `/cadastros/amb/${siglaEmpresa}/ocorrenciasFlorestais?search=${search}`
      )
    }
  },

  getOcorrenciaFlorestalById(siglaEmpresa, idOcorrenciaFlorestal) {
    return $axios.get(
      `/cadastros/amb/${siglaEmpresa}/ocorrenciasFlorestais/${idOcorrenciaFlorestal}`
    )
  },

  saveOcorrenciaFlorestal(siglaEmpresa, ocorrenciaFlorestal) {
    return $axios.post(
      `/cadastros/amb/${siglaEmpresa}/ocorrenciasFlorestais`,
      ocorrenciaFlorestal
    )
  },

  deleteOcorrenciaFlorestal(siglaEmpresa, idOcorrenciaFlorestal) {
    return $axios.delete(
      `/cadastros/amb/${siglaEmpresa}/ocorrenciasFlorestais/${idOcorrenciaFlorestal}`
    )
  },
})
