const ADD_ROW = 'ADD_ROW'
const DELETE = 'DELETE'
const FILL_ROWS = 'FILL_ROWS'
const GET_ROW = 'GET_ROW'
const INIT_DATA = 'INIT_DATA'
const SAVE = 'SAVE'

export const state = () => ({
  // Pre-fill one row with an
  // empty `Contact` model.
  rows: [{}],
  rowData: {},
})

export const actions = {
  initData({ commit }, { dataInicial }) {
    commit(INIT_DATA, { dataInicial })
  },
  fill({ commit }, rows) {
    commit(FILL_ROWS, { rows })
  },
  findByEmpresa({ commit }, idEmpresa) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line new-cap
      new this.$previstoService.findPrevisto(idEmpresa).then(
        (response) => {
          commit(FILL_ROWS, { previstos: response.data })
          resolve(response.data)
        },
        (error) => reject(error)
      )
    })
  },
  getOne({ commit }, { idEmpresa, idPrevisto }) {
    commit(GET_ROW, { idEmpresa, idPrevisto })
  },
  save(context, previstoData) {
    return new Promise((resolve, reject) => {
      console.log('save.previsto=>', previstoData)
      // eslint-disable-next-line new-cap
      new this.$previstoService.save(previstoData).then(
        (response) => {
          resolve(response)
        },
        (error) => reject(error)
      )
    })
  },
  delete({ commit }, { idEmpresa, idPrevisto }) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line new-cap
      new this.$previstoService.delete(idEmpresa, idPrevisto).then(
        (response) => {
          commit(DELETE, { idEmpresa, idPrevisto })
          resolve(response)
        },
        (error) => reject(error)
      )
    })
  },
}

export const mutations = {
  [ADD_ROW](state) {
    // To enable multi-row form handling
    // we make it possible to add new rows.
    state.rows.push({})
  },
  [INIT_DATA](state, { dataInicial }) {
    state.rowData = {}
    if (dataInicial !== undefined) {
      state.rowData.dataInicial = dataInicial
    }
  },
  [FILL_ROWS](state, { previstos }) {
    state.rows = previstos
  },
  [GET_ROW](state, { idEmpresa, idPrevisto }) {
    const idx = state.rows.findIndex(
      (item) => item.idEmpresa === idEmpresa && item.idPrevisto === idPrevisto
    )
    if (idx !== -1) {
      state.rowData = state.rows[idx]
    } else {
      state.rowData = null
    }
  },
  [SAVE](state, { previstoData }) {
    state.rowData = previstoData

    const idx = state.rows.findIndex(
      (item) =>
        item.id.idEmpresa === previstoData.id.idEmpresa &&
        item.id.idPrevisto === previstoData.id.idPrevisto
    )
    if (idx !== -1) {
      state.rows[idx] = previstoData
    } else {
      state.rows.push(previstoData)
    }
  },
  [DELETE](state, { previstoData }) {
    state.rows = state.rows.filter(
      (item) =>
        item.id.idEmpresa === previstoData.id.idEmpresa &&
        item.id.idPrevisto === previstoData.id.idPrevisto
    )
  },
}

export const getters = {
  all: (state) => state.rows,
  one: (state) => state.rowData,
}
