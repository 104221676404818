export default ($axios) => ({
  // Tipos Pleito
  findTipoPleito(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/tipos`)
    } else {
      return $axios.get(`/pleitos/${empresa}/tipos?search=${search}`)
    }
  },

  getTipoPleitoById(empresa, idTipoPleito) {
    return $axios.get(`/pleitos/${empresa}/${idTipoPleito}/tipoPleito`)
  },

  saveTipoPleito(empresa, tipo) {
    return $axios.post(`/pleitos/${empresa}/tipos/`, tipo)
  },

  deleteTipoPleito(empresa, idTipo) {
    return $axios.delete(`/pleitos/${empresa}/${idTipo}/tipos`)
  },

  // Faixas valores
  findFaixasValores(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/faixasValores`)
    } else {
      return $axios.get(`/pleitos/${empresa}/faixasValores?search=${search}`)
    }
  },

  getFaixasValoresById(empresa, idFaixaValore) {
    return $axios.get(`/pleitos/${empresa}/${idFaixaValore}/faixaValor`)
  },

  saveFaixasValores(empresa, faixa) {
    return $axios.post(`/pleitos/${empresa}/faixasValores/`, faixa)
  },

  deleteFaixasValores(empresa, idFaixa) {
    return $axios.delete(`/pleitos/${empresa}/${idFaixa}/faixasValores`)
  },

  // Probabilidade de Sucesso
  findProbabilidade(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/possibilidadesExito`)
    } else {
      return $axios.get(`/pleitos/${empresa}/possibilidadesExito?search=${search}`)
    }
  },

  getProbalidadeById(empresa, idPossibilidadeExito) {
    return $axios.get(`/pleitos/${empresa}/${idPossibilidadeExito}/possibilidadesExito`)
  },

  saveProbalidade(empresa, probalidade) {
    return $axios.post(`/pleitos/${empresa}/possibilidadesExito/`, probalidade)
  },

  deleteProbalidade(empresa, idPossibilidade) {
    return $axios.delete(`/pleitos/${empresa}/${idPossibilidade}/possibilidadesExito`)
  },

  // revisoes tarifarias
  findRevisoesTarifarias(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`pleitos/${empresa}/revisoesTarifarias`)
    } else {
      return $axios.get(
        `/pleitos/${empresa}/revisoesTarifarias?search=${search}`
      )
    }
  },

  getRevisoesTarifariasById(empresa, idRevisaoTarifaria) {
    return $axios.get(
      `/pleitos/${empresa}/${idRevisaoTarifaria}/revisaoTarifaria`
    )
  },

  saveRevisoesTarifarias(empresa, revisao) {
    return $axios.post(`/pleitos/${empresa}/revisoesTarifarias/`, revisao)
  },

  deleteRevisoesTarifarias(empresa, idRevisao) {
    return $axios.delete(`/pleitos/${empresa}/${idRevisao}/revisoesTarifarias`)
  },

  // prioridades
  findPrioridadePleitos(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/prioridades`)
    } else {
      return $axios.get(`/pleitos/${empresa}/prioridades?search=${search}`)
    }
  },

  getPrioridadePleitosById(empresa, idPrioridade) {
    return $axios.get(`/pleitos/${empresa}/${idPrioridade}/prioridadePleito`)
  },

  savePrioridadePleitos(empresa, prioridades) {
    return $axios.post(`/pleitos/${empresa}/prioridades/`, prioridades)
  },

  deletePrioridadePleitos(empresa, idPrioridade) {
    return $axios.delete(`/pleitos/${empresa}/${idPrioridade}/prioridades`)
  },

  // Tipos caracteristicas
  findTipoCaracteristica(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/tiposCaracteristica`)
    } else {
      return $axios.get(
        `/pleitos/${empresa}/tiposCaracteristica?search=${search}`
      )
    }
  },

  getTipoCaracteristicaById(empresa, idTipoCaracteristica) {
    return $axios.get(
      `/pleitos/${empresa}/${idTipoCaracteristica}/tipoCaracteristica`
    )
  },

  saveTipoCaracteristica(empresa, TipoCaracteristica) {
    return $axios.post(`/pleitos/${empresa}/tiposCaracteristica/`, TipoCaracteristica)
  },

  deleteTipoCaracteristica(empresa, idTipo) {
    return $axios.delete(`/pleitos/${empresa}/${idTipo}/tiposCaracteristica`)
  },

  // Niveis Agencia
  findNiveisAgencia(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/nivelAgencia`)
    } else {
      return $axios.get(`/pleitos/${empresa}/nivelAgencia?search=${search}`)
    }
  },

  getNiveisAgenciaById(empresa, idNivelAgencia) {
    return $axios.get(`/pleitos/${empresa}/${idNivelAgencia}/nivelAgencia`)
  },

  saveNiveisAgencia(empresa, nivelAgencia) {
    return $axios.post(`/pleitos/${empresa}/nivelAgencia`, nivelAgencia)
  },

  deleteNiveisAgencia(empresa, idNivel) {
    return $axios.delete(`/pleitos/${empresa}/${idNivel}/nivelAgencia`)
  },

  // Status Pleito
  findStatusPleito(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/status`)
    } else {
      return $axios.get(`/pleitos/${empresa}/status?search=${search}`)
    }
  },

  getStatusPleitoById(empresa, idStatus) {
    return $axios.get(`/pleitos/${empresa}/${idStatus}/status`)
  },

  saveStatusPleito(empresa, status) {
    return $axios.post(`/pleitos/${empresa}/status/`, status)
  },

  deleteStatusPleito(empresa,idStatus) {
    return $axios.delete(`/pleitos/${empresa}/${idStatus}/status`)
  },

  // Status Agência
  findStatusAgencia(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/statusAgencia`)
    } else {
      return $axios.get(`/pleitos/${empresa}/statusAgencia?search=${search}`)
    }
  },

  getStatusAgenciaById(empresa, idSatus) {
    return $axios.get(`/pleitos/${empresa}/${idSatus}/statusAgencia`)
  },

  saveStatusAgencia(empresa, status) {
    return $axios.post(`/pleitos/${empresa}/statusAgencia/`, status)
  },

  deleteStatusAgencia(empresa, idStatus) {
    return $axios.delete(`/pleitos/${empresa}/${idStatus}/statusAgencia`)
  },

  // Grupos de Trabalho
  findGruposTrabalho(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/gruposTrabalho`)
    } else {
      return $axios.get(`/pleitos/${empresa}/gruposTrabalho?search=${search}`)
    }
  },

  getGruposTrabalhoById(empresa, idGrupoTrabalho) {
    return $axios.get(`/pleitos/${empresa}/${idGrupoTrabalho}/gruposPleito`)
  },

  saveGruposTrabalho(empresa, gruposTrabalho) {
    return $axios.post(`/pleitos/${empresa}/gruposTrabalho/`, gruposTrabalho)
  },

  deleteGruposTrabalho(empresa, idGrupo) {
    return $axios.delete(`/pleitos/${empresa}/${idGrupo}/gruposTrabalho`)
  },

  // Tipos Desequilibrio
  findTiposDesequilibrio(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pleitos/${empresa}/tiposDesequilibrio`)
    } else {
      return $axios.get(
        `/pleitos/${empresa}/tiposDesequilibrio?search=${search}`
      )
    }
  },

  getTiposDesequilibrioById(empresa, idTipoDesequilibrio) {
    return $axios.get(`/pleitos/${empresa}/${idTipoDesequilibrio}/tipoDesequilibrio`)
  },

  saveTiposDesequilibrio(empresa, tiposDesequilibrio) {
    return $axios.post(`/pleitos/${empresa}/tiposDesequilibrio/`, tiposDesequilibrio)
  },

  deleteTiposDesequilibrio(empresa, idTipo) {
    return $axios.delete(`/pleitos/${empresa}/${idTipo}/tiposDesequilibrio`)
  },

  // Pleitos
  findPleitos(empresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        console.log('key==> ', key, ':: ', filtro[key])
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }

      if (parms !== '') parms = '?' + parms
    }
    return $axios.get(`/pleitos/${empresa}${parms}`)
  },

  getPleitosById(empresa, idPleito) {
    return $axios.get(`/pleitos/${empresa}/${idPleito}`)
  },

  savePleitos(empresa, pleito) {
    return $axios.post(`/pleitos/${empresa}/`, pleito)
  },

  deletePleitos(empresa, idPleito) {
    return $axios.delete(`/pleitos/${empresa}/${idPleito}`)
  },
})
