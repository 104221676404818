export default ($axios) => ({

    findStatus(search) {
      if (search === null || search === undefined) {
        return $axios.get(`/cadastros/amb/statusAprovacoesUso`)
      } else {
        return $axios.get(`/cadastros/amb/statusAprovacoesUso?search=${search}`)
      }
    },

    getStatusById(idStatusAprovacaoUso) {
      return $axios.get(`/cadastros/amb/statusAprovacoesUso/${idStatusAprovacaoUso}`)
    },

    saveStatus(payload) {
      return $axios.post(`/cadastros/amb/statusAprovacoesUso/`, payload)
    },

    updateStatus(idStatusAprovacaoUso, payload) {
      return $axios.post(`/cadastros/amb/statusAprovacoesUso/${idStatusAprovacaoUso}`, payload)
    },

    deleteStatus(idStatusAprovacaoUso) {
      return $axios.delete(`/cadastros/amb/statusAprovacoesUso/${idStatusAprovacaoUso}`)
    },
  })
