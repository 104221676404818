export const state = () => ({
  background: null,
})

export const mutations = {
  selectBackground(state, imagem) {
    state.background = imagem
  },
  clearBackground(state) {
    state.background = null
  },
}

export const actions = {
  setBackground({ commit }, imagem) {
    commit('selectBackground', imagem)
  },
  clearBackground({ commit }) {
    commit('clearBackground')
  },
}

export const getters = {
  getBackground(state) {
    return state.background
  },
}
