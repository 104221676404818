const URL_API = '/sigaco/planosAcoes'

export default ($axios) => ({
  findPlanosAcao(siglaEmpresa, tipoAtivo, idFase, idElemento) {
    return $axios.get(
      `${URL_API}/${siglaEmpresa}/${tipoAtivo}/${idFase}?elemento=${idElemento}`
    )
  },

  getPlanoAcaoById(siglaEmpresa, idFase, sequencia) {
    return $axios.get(
      `${URL_API}/${siglaEmpresa}/${idFase}/${sequencia}`
    )
  },
})
