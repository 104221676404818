export default ($axios) => ({
  findReunioes(empresa, filtro = {}) {
    let params = '?'

    Object.entries(filtro).forEach(([key, value]) => {
      if (value === undefined || value === null || value === '') return
      if (Array.isArray(filtro[key]) && filtro[key].length === 0) return

      if (params !== '?') params += '&'
      params += `${key}=${value}`
    })

    return $axios.get(
      `/relacoesInstitucionais/reunioes/${empresa}/${
        params === '?' ? '' : params
      }`
    )
  },
  findReuniaoById(empresa, id) {
    return $axios.get(`/relacoesInstitucionais/reunioes/${empresa}/${id}`)
  },
  saveReuniao(empresa, reuniao) {
    return $axios.post(`/relacoesInstitucionais/reunioes/${empresa}`, reuniao)
  },
  deleteReuniao(empresa, idRegistro) {
    return $axios.delete(
      `/relacoesInstitucionais/reunioes/${empresa}/${idRegistro}`
    )
  },
  getReuniaoParticipantes(empresa, idRegistro) {
    return $axios.get(
      `/relacoesInstitucionais/${empresa}/registros/${idRegistro}/participantes`
    )
  },
  saveReuniaoParticipantes(payload) {
    return $axios.post(
      `/relacoesInstitucionais/registros/participantes`,
      payload
    )
  },
  getReuniaoAnexos(empresa, idRegistro) {
    return $axios.get(
      `/relacoesInstitucionais/${empresa}/registros/${idRegistro}/anexos`
    )
  },

  // Participantes Reunião
  findParticipantesReuniao(empresa, idReuniao) {
    return $axios.get(
      `/relacoesInstitucionais/${empresa}/registros/${idReuniao}/participantes`
    )
  },
  saveParticipanteReuniao(participante) {
    return $axios.post(
      `/relacoesInstitucionais/registros/participantes`,
      participante
    )
  },
  deleteParticipanteReuniao(empresa, idRegistro, sequencia) {
    return $axios.delete(
      `/relacoesInstitucionais/${empresa}/registros/${idRegistro}/${sequencia}/participantes`
    )
  },

  // Anexos Reunião
  findAnexoReuniao(empresa, idReuniao) {
    return $axios.get(
      `/relacoesInstitucionais/reunioes/${empresa}/${idReuniao}/anexos`
    )
  },
  saveAnexoReuniao(empresa, idRegistro, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/relacoesInstitucionais/${empresa}/reunioes/${idRegistro}`,
      formData
    )
  },
  deleteAnexoReuniao(empresa, idRegistro, sequencia) {
    return $axios.delete(
      `/relacoesInstitucionais/reunioes/${empresa}/${idRegistro}/${sequencia}/anexos`
    )
  },

  findInformacoes(empresa) {
    return $axios.get(`/relacoesInstitucionais/informacoes/${empresa}`)
  },
  findInformacao(empresa, id) {
    return $axios.get(`/relacoesInstitucionais/informacoes/${empresa}/${id}`)
  },
  saveInformacao(empresa, informacao) {
    return $axios.post(
      `/relacoesInstitucionais/informacoes/${empresa}`,
      informacao
    )
  },
  deleteInformacao(empresa, idRegistro) {
    return $axios.delete(
      `/relacoesInstitucionais/informacoes/${empresa}/${idRegistro}`
    )
  },
})
