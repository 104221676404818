const URL_API = '/ged'

export default ($axios) => ({
  findAnexos(empresa, search, dataInicial, dataFinal) {
    let parm = ''
    if (search != null) {
      if (parm !== '') parm += '&'
      else parm += '?'

      parm += 'search=' + search
    }

    if (dataInicial != null) {
      if (parm !== '') parm += '&'
      else parm += '?'

      parm += 'dataInicial=' + dataInicial
    }

    if (dataFinal != null) {
      if (parm !== '') parm += '&'
      else parm += '?'

      parm += 'dataFinal=' + dataFinal
    }

    return $axios.get(`${URL_API}/${empresa}${parm}`)
  },
})
