//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete'

export default {
  components: {
    VueBootstrapAutocomplete,
  },
  props: {
    /**
     * @description V-model do componente.
     * O Valor deve ser definido no minimo como undefined
     */
    value: {
      type: [String, Number],
      required: false,
      default: () => '',
    },
    /**
     * @description Placeholder do input
     */
    placeholder: {
      type: String,
      required: false,
      default: () => '',
    },
    /**
     * @description Nome da propriedade que será retornada no v-model
     */
    returnKey: {
      type: String,
      required: false,
      default: () => undefined,
    },
    /**
     * @description Itens que serão exibidos no autocomplete
     */
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * @description Função que será chamada para renderizar o item no autocomplete
     * Deve sempre retornar uma string senão causará erro no componente (match)
     * Exemplo: (item) => item.id + ': ' + item.nome
     */
    render: {
      type: Function,
      required: false,
      default: () => '',
    },

    state: {
      type: Boolean,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      search: '',
      lastSelected: null,

      itemsCopy: [],
    }
  },
  watch: {
    value(newValue) {
      if (newValue === null || newValue === undefined || newValue === '') {
        this.search = ''
        return
      }

      if (newValue + '' === this.lastSelected + '') return

      const item = this.items.find((item) => {
        const itemValue = this.returnKey ? item[this.returnKey] : item

        return itemValue + '' === newValue + ''
      })

      if (item) {
        this.search = this.render(item)
        this.selectItem(item)
      }
    },

    items(newItems) {
      if (JSON.stringify(newItems) === JSON.stringify(this.itemsCopy)) return

      const item = newItems.find((item) => {
        const itemValue = this.returnKey ? item[this.returnKey] : item

        return itemValue + '' === this.value + ''
      })

      this.itemsCopy = newItems

      if (item) {
        this.search = this.render(item)
        this.selectItem(item)
      }
    },
  },
  mounted() {
    if (this.value) {
      const item = this.items.find((item) => {
        const itemValue = this.returnKey ? item[this.returnKey] : item

        return itemValue + '' === this.value + ''
      })

      if (item) {
        this.search = this.render(item)
        this.selectItem(item)
      }
    }
  },
  methods: {
    searching(search) {
      if (!search) return this.selectItem(undefined)

      const item = this.items.find((item) => this.render(item) === search)

      if (item) this.selectItem(item)
    },

    selectItem(newItem) {
      let itemValue = newItem

      if (newItem !== undefined && newItem !== null) {
        itemValue = this.returnKey ? newItem[this.returnKey] : newItem
      }

      this.lastSelected = itemValue
      this.$emit('input', itemValue)
      this.$emit('change', itemValue)
    },
  },
}
