export const state = () => ({
  idInspecaoAmbiental: null,
  statusInspecaoAmbiental: null,
  supervisorAmbiental: null,
  coordenadorObra: null,
})

export const mutations = {
  changeInspecaoAmbiental(state, inspecaoAmbiental) {
    state.idInspecaoAmbiental = inspecaoAmbiental.id.idInspecao
    state.statusInspecaoAmbiental = inspecaoAmbiental.status
    state.supervisorAmbiental = inspecaoAmbiental.idSupervisorAmbiental
    state.coordenadorObra = inspecaoAmbiental.idCoordenadorObra
  },
  clearInspecaoAmbiental(state) {
    state.idInspecaoAmbiental = null
    state.statusInspecaoAmbiental = null
    state.supervisorAmbiental = null
    state.coordenadorObra = null
  },
}

export const actions = {
  setInspecaoAmbiental({ commit }, inspecaoAmbiental) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeInspecaoAmbiental', inspecaoAmbiental)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearInspecaoAmbiental')
  },
}

export const getters = {
  idInspecaoAmbiental(state) {
    return state.idInspecaoAmbiental
  },
  statusInspecaoAmbiental(state) {
    return state.statusInspecaoAmbiental
  },
  supervisorAmbiental(state) {
    return state.supervisorAmbiental
  },
  coordenadorObra(state) {
    return state.coordenadorObra
  },
}
