const URL_API = '/cadastros/sigaco'

export default ($axios) => ({
  findFasesMonitoracoes(siglaEmpresa) {
    return $axios.get(`${URL_API}/${siglaEmpresa}/fasesMonitoracao`)
  },

  findFaseMonitoracaoById(siglaEmpresa, idFase) {
    return $axios.get(`${URL_API}/${siglaEmpresa}/fasesMonitoracao/${idFase}`)
  },

  saveFaseMonitoracao(siglaEmpresa, fase) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(`${URL_API}/${siglaEmpresa}/fasesMonitoracao`, fase)
  },

  deleteFaseMonitoracao(siglaEmpresa, idFase) {
    return $axios.delete(
      `${URL_API}/${siglaEmpresa}/fasesMonitoracao/${idFase}`
    )
  },

  findAnexosFase(siglaEmpresa, idFase) {
    return $axios.get(
      `${URL_API}/${siglaEmpresa}/fasesMonitoracao/${idFase}/anexos`
    )
  },

  saveAnexoFase(siglaEmpresa, idFase, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `${URL_API}/${siglaEmpresa}/fasesMonitoracao/${idFase}/anexos`,
      formData
    )
  },

  deleteAnexoFase(siglaEmpresa, idFase, idAnexoFase) {
    return $axios.delete(
      `${URL_API}/${siglaEmpresa}/fasesMonitoracao/${idFase}/anexos/${idAnexoFase}`
    )
  },
})
