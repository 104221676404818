export const state = () => ({
  filtro: undefined,
  pagina: undefined,
  paginatorPage: 1,
})

export const mutations = {
  changeFiltro(state, filtro) {
    state.filtro = filtro
  },
  clearFiltro(state) {
    state.filtro = undefined
  },

  changePagina(state, pagina) {
    state.pagina = pagina
  },
  clearPagina(state) {
    state.pagina = undefined
  },

  changePaginatorPage(state, paginatorPage) {
    state.paginatorPage = paginatorPage
  },
}

export const actions = {
  setFiltro({ commit }, filtro) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeFiltro', filtro)
        resolve()
      }, 50)
    })
  },
  clearFiltro({ commit }) {
    commit('clearFiltro')
  },

  setPagina({ commit }, pagina) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changePagina', pagina)
        resolve()
      }, 50)
    })
  },
  clearPagina({ commit }) {
    commit('clearPagina')
  },

  setPaginatorPage({ commit }, paginatorPage) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changePaginatorPage', paginatorPage)
        resolve()
      }, 50)
    })
  },
}

export const getters = {
  getFiltro(state) {
    return state.filtro
  },
  getPagina(state) {
    return state.pagina
  },

  getPaginatorPage(state) {
    return state.paginatorPage
  },
}
