export default ($axios) => ({
  findLigantes() {
    return $axios.get(`/cadastros/lab/ligantes`)
  },
  getByIdLigantes(empresa, idLigante) {
    return $axios.get(`/cadastros/lab/ligantes/${idLigante}`)
  },
  saveLigante(ligante) {
    return $axios.post(`/cadastros/lab/ligantes`, ligante)
  },
  deleteLigante(idLigante) {
    return $axios.delete(`/cadastros/lab/ligantes/${idLigante}`)
  },
})
