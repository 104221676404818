//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

// Vee-Validate
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate/dist/vee-validate.full'

export default {
  name: 'ModalSenha',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Dados da tela
      login: undefined,
      nome: undefined,
      novaSenha: undefined,
      confirmarSenha: undefined,

      // Loading
      loading: false,
      variant: 'white',

      // Dados do Usuário
      usuario: {},
    }
  },
  computed: {
    ...mapGetters({
      empresa: 'empresa/empresaAtual',
      usuarioAux: 'usuario/usuario',
    }),
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'editSenha') {
        this.loading = true
        this.getUserById()
      }
    })
  },
  methods: {
    async getUserById() {
      await this.$usuarioService
        .getUserSavedById(this.usuarioAux.refId)
        .then((response) => {
          this.usuario = response.data

          this.login = this.usuario.usuario.login
          this.nome = this.usuario.usuario.nome

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error',
          })
        })
    },
    save() {
      this.usuario.perfil.senha = this.novaSenha

      this.$usuarioService
        .resetPassword(this.usuario.login, this.usuario)
        .then(() => {
          this.$notify({
            title: 'Altera senha',
            text: 'Senha alterada com sucesso.',
            type: 'success',
          })

          this.$bvModal.hide('editSenha')
        })
        .catch((error) => {
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error',
          })
        })
    },
    closeModal() {
      this.$bvModal.hide('editSenha')
    },
    getValidationState({ dirty, validated, valid }) {
      return dirty || validated ? valid : null
    },
  },
}
