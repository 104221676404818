export const state = () => ({
    gravidades: [],
  })
  
  export const mutations = {
    fill(state, rows) {
      state.gravidades = rows
    },
  }
  
  export const actions = {
    find({ commit }, tipoFicha) {
      this.$gravidadeService
        .findGravidades()
        .then((response) => {
          const gravidades = []
          for (const item of response.data) {
            gravidades.push({
              id: item.id,
              nome: item.nome,
              descricao: item.descricao,
            })
          }
  
          commit('fill', gravidades)
        })
        .catch((error) => {
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error',
          })
        })
    },
  }
  
  export const getters = {
    gravidades(state) {
      return state.gravidades
    },
  }
  