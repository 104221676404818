export const state = () => ({
  idRac: null,
  idRacInfo: null,
  idRacHist: null,
  statusRac: null,
  supervisorAmbiental: null,
  coordenadorObra: null,
})

export const mutations = {
  changeRac(state, rac) {
    state.idRac = rac.id.idRac
    state.statusRac = rac.status
    state.supervisorAmbiental = rac.idSupervisorAmbiental
    state.coordenadorObra = rac.idCoordenadorObra
  },
  clearRac(state) {
    state.idRac = null
    state.statusRac = null
    state.supervisorAmbiental = null
    state.coordenadorObra = null
  },

  // Modal RAC Informações
  changeIdRacInfo(state, idRac) {
    state.idRacInfo = idRac
  },

  clearIdRacInfo(state) {
    state.idRacInfo = null
  },

  // Modal RAC Histórico
  changeIdRacHist(state, idRac) {
    state.idRacHist = idRac
  },

  clearIdRacHist(state) {
    state.idRacHist = null
  },
  // Criado para o MAPA DE NÃO CONFORMIDADES
  changeIdRac(state, idRac) {
    state.idRac = idRac
  },

  clearIdRac(state) {
    state.idRac = null
  },

  changeStatusRac(state, status) {
    state.statusRac = status
  },
}

export const actions = {
  setRac({ commit }, rac) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeRac', rac)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearRac')
  },

  // Modal RAC Informações
  clearIdRacInfo({ commit }) {
    commit('clearIdRacInfo')
  },

  setIdRacInfo({ commit }, idRac) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeIdRacInfo', idRac)
        resolve()
      }, 50)
    })
  },

  // Modal RAC Histórico
  clearIdRacHist({ commit }) {
    commit('clearIdRacHist')
  },

  setIdRacHist({ commit }, idRac) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeIdRacHist', idRac)
        resolve()
      }, 50)
    })
  },

  // Criado para o MAPA DE NÃO CONFORMIDADES
  setIdRac({ commit }, idRac) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeIdRac', idRac)
        resolve()
      }, 50)
    })
  },

  clearIdRac({ commit }) {
    commit('clearIdRac')
  },

  setStatusRac({ commit }, status) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeStatusRac', status)
        resolve()
      }, 50)
    })
  },
}

export const getters = {
  idRac(state) {
    return state.idRac
  },
  idRacInfo(state) {
    return state.idRacInfo
  },
  idRacHist(state) {
    return state.idRacHist
  },
  statusRac(state) {
    return state.statusRac
  },
  supervisorAmbiental(state) {
    return state.supervisorAmbiental
  },
  coordenadorObra(state) {
    return state.coordenadorObra
  },
}
