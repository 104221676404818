export default function ({ store, redirect, route }) {
  if (route.path === '/login' || route.path === '/selecaoEmpresa' || route.path === '/registro' || route.path === `/confirm/${route.params.id !== undefined ? route.params.id : ''}` ) return null

  if (route.path === '/' && store.state.appData.modulo !== null) {
    store.commit('appData/clearModulo', null)
  }

  // If the user is not authenticated
  if (!store.state.empresa.empresaAtual) {
    return redirect('/selecaoEmpresa')
  }
}
