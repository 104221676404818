const URL_API = '/cadastros/sigaco'

export default ($axios) => ({
  findTiposElementos(siglaEmpresa, subsistema, search) {
    let parms = ''
    if (subsistema !== undefined) parms += '?subsistema=' + subsistema
    if (search !== undefined) {
      parms !== '' ? (parms += '&') : (parms += '?')
      parms += 'search=' + search
    }

    return $axios.get(`${URL_API}/tiposElementos${parms}`)
  },

  getTipoElementoById(idTipo) {
    return $axios.get(`${URL_API}/tiposElementos/${idTipo}`)
  },

  saveTipoElemento(tipo) {
    return $axios.post(`${URL_API}/tiposElementos`, tipo)
  },

  deleteTipoElemento(idTipo) {
    return $axios.delete(`${URL_API}/tiposElementos/${idTipo}`)
  },
})
