export default ($axios) => ({
  findOcorrencias(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/ocorrencias`)
    } else {
      return $axios.get(`/ocorrencias?search=${search}`)
    }
  },

  getOcorrenciaById(idOcorrencia) {
    return $axios.get(`/ocorrencias/${idOcorrencia}`)
  },

  saveOcorrencia(ocorrencia) {
    return $axios.post(`/ocorrencias`, ocorrencia)
  },

  deleteOcorrencia(idOcorrencia) {
    return $axios.delete(`/ocorrencias/${idOcorrencia}`)
  },
})
