import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Vue from 'vue'
import { tiposEnsaios } from '~/data/laboratorio/ensaios/ensaios'

dayjs.extend(utc)
dayjs.extend(timezone)

Vue.filter('formatDate', (value) => {
  if (value) {
    return dayjs(value.toString(), 'YYYY-MM-DD').format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateTime', (value) => {
  if (value) {
    const timestamp = dayjs.tz(value.toString(), 'America/Sao_Paulo')
    return timestamp.format('DD/MM/YYYY HH:mm')
  }
})

Vue.filter('formatKm', (value) => {
  if (value !== undefined && value !== null) {
    if (!isNaN(value)) {
      const km = Math.trunc(value)
      const metro = Math.round((value - km) * 1000.0)

      return (
        km.toString().padStart(3, '0') + '+' + metro.toString().padStart(3, '0')
      )
    }

    return value
  }
})

Vue.filter('findDescription', (value, array) => {
  for (let index = 0; index < array.length; index++) {
    const element = array[index]

    window.console.log(element)

    if (element.id === value) {
      return element.descricao
    }
  }
})

Vue.filter('typeEnsaio', (value, tipoMicroesfera) => {
  for (const tipo of tiposEnsaios) {
    if (value === 'MEV/GRN') {
      if (tipoMicroesfera === 1) {
        return 'Análise de Microsfera de Vidro - Tipo (II-A)'
      } else if (tipoMicroesfera === 2) {
        return 'Análise de Microsfera de Vidro - Tipo (II-C)'
      }
    } else if (tipo.tipo === value) {
      return tipo.descricao
    }
  }
})

Vue.filter('pistaFilter', (value) => {
  if (value === 'N') {
    return 'Norte'
  } else if (value === 'S') {
    return 'Sul'
  } else if (value === 'O') {
    return 'Oeste'
  } else if (value === 'L') {
    return 'Leste'
  }
})

Vue.filter('statusFilter', (value) => {
  if (value === 'P') {
    return 'Pendente'
  } else if (value === 'A') {
    return 'Em andamento'
  } else if (value === 'E') {
    return 'Encerrada'
  } else if (value === 'C') {
    return 'Cancelada'
  }
})
