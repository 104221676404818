export const state = () => ({
  idTrecho: null,
  idRodovia: null,
  rodovia: null,
})

export const mutations = {
  changeTrecho(state, trecho) {
    state.idTrecho = trecho.id
    state.idRodovia = trecho.idRodovia
    if (trecho.rodovia != null) state.rodovia = trecho.rodovia.descricao
  },
  clearTrecho(state) {
    state.idTrecho = null
    state.idRodovia = null
    state.rodovia = null
  },
}

export const actions = {
  setTrecho({ commit }, trecho) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeTrecho', trecho)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearTrecho')
  },
}

export const getters = {
  idTrecho(state) {
    return state.idTrecho
  },
  idRodovia(state) {
    return state.idRodovia
  },
  rodovia(state) {
    return state.rodovia
  },
}
