import { authHeaderMultipart } from '~/helpers'

export default ($axios) => ({
  findAutorizacaoIntervencao(
    siglaEmpresa,
    payload,
    pageNumber,
    pageSize,
    sortField,
    sortDirection
  ) {
    if (!payload) payload = {}

    let pageNbr = pageNumber
    let pageSz = pageSize
    if (pageNbr == null || pageNbr === 0) pageNbr = 1
    if (pageSz == null || pageSz === 0) pageSz = 15

    let field = 'numero'
    let sortDesc = true
    if (sortField !== null && sortField !== undefined) field = sortField
    if (sortDirection !== null && sortDirection !== undefined)
      sortDesc = sortDirection

    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/autorizacoesIntervencao/${siglaEmpresa}/filtros?page=${pageNbr}&size=${pageSz}&sortField=${field}&sortDesc=${sortDesc}`,
      payload
    )
  },

  getAutorizacaoIntervencaoById(siglaEmpresa, idAutorizacaoIntervencao) {
    return $axios.get(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}`
    )
  },

  saveAutorizacaoIntervencao(
    siglaEmpresa,
    autorizacoesIntervencao,
    ocorrencias,
    anexos
  ) {
    const requestCfg = {
      method: 'POST',
      headers: authHeaderMultipart(),
    }

    const formData = new FormData()

    if (autorizacoesIntervencao !== undefined) {
      formData.append(
        'autorizacao',
        JSON.stringify(autorizacoesIntervencao, (key, value) => {
          if (value !== null) return value
        })
      )
    }

    if (ocorrencias !== undefined)
      formData.append('ocorrencias', JSON.stringify(ocorrencias))

    if (anexos !== undefined) {
      const anexosRaw = []
      for (const anexo of anexos) {
        formData.append('filesAnexos', anexo.file)
        anexosRaw.push({
          comentario: anexo.comentario,
          anexo: {
            nome: anexo.file.name,
          },
        })
      }

      formData.append('anexos', JSON.stringify(anexosRaw))
    }
    return $axios.post(
      `/autorizacoesIntervencao/${siglaEmpresa}`,
      formData,
      requestCfg
    )
  },

  deleteAutorizacaoIntervencao(siglaEmpresa, idAutorizacaoIntervencao) {
    return $axios.delete(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}`
    )
  },

  createAnexo(siglaEmpresa, idAutorizacaoIntervencao, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/anexos`,
      formData
    )
  },

  findAnexos(siglaEmpresa, idAutorizacaoIntervencao) {
    return $axios.get(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/anexos`
    )
  },

  deleteAnexo(siglaEmpresa, idAutorizacaoIntervencao, seqAnexo) {
    return $axios.delete(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/anexos/${seqAnexo}`
    )
  },

  findOcorrenciasIntervencao(siglaEmpresa, idAutorizacaoIntervencao) {
    return $axios.get(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/ocorrencias`
    )
  },

  findHistoricos(siglaEmpresa, idAutorizacaoIntervencao) {
    return $axios.get(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/historicos`
    )
  },

  saveHistorico(siglaEmpresa, idAutorizacaoIntervencao, historico) {
    const formData = new FormData()

    if (historico !== undefined)
      formData.append('descricao', historico.descricao + '')

    return $axios.post(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/historicos`,
      formData
    )
  },

  editComentarioAnexo(siglaEmpresa, idAutorizacaoIntervencao, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/anexos/comentarios`,
      payload
    )
  },

  girarImagem(siglaEmpresa, idAutorizacaoIntervencao, idAnexo, angulo) {
    return $axios.post(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/anexos/girar/${idAnexo}/${angulo}`
    )
  },

  downloadPdf(empresa, idAutorizacaoIntervencao, tipoMapa = 'S') {
    return $axios.get(
      `/autorizacoesIntervencao/${empresa}/exportacaoPdf/${idAutorizacaoIntervencao}?tipoMapa=${tipoMapa}`,
      {
        responseType: 'blob',
      }
    )
  },
  downloadExcel(empresa, filter) {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.post(
      `/autorizacoesIntervencao/${empresa}/exportacaoExcel`,
      filter,
      requestCfg
    )
  },

  findAprovacoesAutorizacao(siglaEmpresa, idAutorizacaoIntervencao) {
    console.log(
      'findAprovacoesDeposito=>',
      siglaEmpresa,
      idAutorizacaoIntervencao
    )
    return $axios.get(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacaoIntervencao}/aprovacoes`
    )
  },

  executeEvento(
    empresa,
    idAutorizacaoIntervencao,
    tipoEvento,
    paramsPayload = {}
  ) {
    $axios.setHeader('Content-Type', 'application/json')
    const { seqAprovacao, justificativa, perfilReprovar } = paramsPayload

    let parms = ''

    if (Object.keys(paramsPayload).length > 0) {
      if (seqAprovacao !== undefined) parms += '&seqAprovacao=' + seqAprovacao
      if (justificativa !== undefined) parms += '&descricao=' + justificativa
      if (perfilReprovar !== undefined)
        parms += '&perfilReprovar=' + perfilReprovar

      parms = '?' + parms.slice(1)
    }

    return $axios.post(
      `/autorizacoesIntervencao/${empresa}/${idAutorizacaoIntervencao}/eventos/${tipoEvento}${parms}`
    )
  },

  importFileFlorestal(siglaEmpresa, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')

    return $axios.post(
      `/autorizacoesIntervencao/${siglaEmpresa}/importaFlorestal`,
      formData
    )
  },

  findInspecoesFlorestais(siglaEmpresa, idAutorizacao) {
    return $axios.get(
      `/autorizacoesIntervencao/${siglaEmpresa}/${idAutorizacao}/inspecoesFlorestais`
    )
  },
})
