export default ($axios) => ({
  findMonitoracoes(siglaEmpresa, tipo, tipoAtivo, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) {
            if (filtro[key].length === 0) continue

            for (const col of filtro[key]) {
              if (parms !== '') parms += '&'
              parms += key + '=' + col
            }
          } else {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(
      `/monitoracoes/ativos/${siglaEmpresa}/${tipo}/${tipoAtivo}${parms}`
    )
  },

  findMonitoracoesParametro(siglaEmpresa, tipo) {
    return $axios.get(`/monitoracoes/ativos/${siglaEmpresa}/${tipo}`)
  },

  getMonitoracaoById(siglaEmpresa, idMonitoracao) {
    return $axios.get(`/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}`)
  },

  saveMonitoracao(siglaEmpresa, monitoracao) {
    if (monitoracao.tipoAtivo === 'DRE')
      return this.saveMonitoracaoDrenagem(siglaEmpresa, monitoracao)

    if (monitoracao.tipoAtivo === 'SNH')
      return this.saveMonitoracaoSinalizacaoHorizontal(
        siglaEmpresa,
        monitoracao
      )

    if (monitoracao.tipoAtivo === 'SNV')
      return this.saveMonitoracaoSinalizacaoVertical(siglaEmpresa, monitoracao)

    if (monitoracao.tipoAtivo === 'TEC')
      return this.saveMonitoracaoTerraplenos(siglaEmpresa, monitoracao)

    if (monitoracao.tipoAtivo === 'EPS')
      return this.saveMonitoracaoEps(siglaEmpresa, monitoracao)
  },

  saveMonitoracaoDrenagem(siglaEmpresa, monitoracao) {
    return $axios.post(
      `/monitoracoes/ativos/${siglaEmpresa}/drenagens`,
      monitoracao
    )
  },

  saveMonitoracaoSinalizacaoHorizontal(siglaEmpresa, monitoracao) {
    return $axios.post(
      `/monitoracoes/ativos/${siglaEmpresa}/sinalizacoesHorizontais`,
      monitoracao
    )
  },

  saveMonitoracaoSinalizacaoVertical(siglaEmpresa, monitoracao) {
    return $axios.post(
      `/monitoracoes/ativos/${siglaEmpresa}/sinalizacoesVerticais`,
      monitoracao
    )
  },

  saveMonitoracaoTerraplenos(siglaEmpresa, monitoracao) {
    return $axios.post(
      `/monitoracoes/ativos/${siglaEmpresa}/terraplenos`,
      monitoracao
    )
  },

  saveMonitoracaoEps(siglaEmpresa, monitoracao) {
    return $axios.post(
      `/monitoracoes/ativos/${siglaEmpresa}/equipamentosSeguranca`,
      monitoracao
    )
  },

  deleteMonitoracao(siglaEmpresa, idMonitoracao) {
    return $axios.delete(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}`
    )
  },

  deleteImportacoes(empresa, ciclo, importacao) {
    return $axios.delete(
      `/monitoracoes/ativos/importacoes/${empresa}/${ciclo}/${importacao}/excluir`
    )
  },

  findCronogramas(siglaEmpresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) {
            if (filtro[key].length === 0) continue

            for (const col of filtro[key]) {
              if (parms !== '') parms += '&'
              parms += key + '=' + col
            }
          } else {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(
      `/monitoracoes/ativos/cronogramas/${siglaEmpresa}${parms}`
    )
  },

  findImagens(siglaEmpresa, idMonitoracao) {
    return $axios.get(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/imagens`
    )
  },

  getImagem(siglaEmpresa, idMonitoracao, sequencia) {
    return $axios.get(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/${sequencia}/imagens`
    )
  },

  createAnexo(siglaEmpresa, idMonitoracao, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/imagens`,
      formData
    )
  },

  deleteImagem(siglaEmpresa, idMonitoracao, seqAnexo) {
    return $axios.delete(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/imagens/${seqAnexo}`
    )
  },

  findAnexos(siglaEmpresa, idMonitoracao) {
    return $axios.get(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/anexos`
    )
  },

  getAnexo(siglaEmpresa, idMonitoracao, sequencia) {
    return $axios.get(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/anexos/${sequencia}`
    )
  },

  saveAnexo(siglaEmpresa, idMonitoracao, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/anexos`,
      formData
    )
  },

  deleteAnexo(siglaEmpresa, idMonitoracao, seqAnexo) {
    return $axios.delete(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/anexos/${seqAnexo}`
    )
  },

  downloadAnexo(empresa, modulo, uuid) {
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.get(
      `/downloads/${empresa}/anexos/${modulo}/${uuid}`,
      requestCfg
    )
  },

  executeImportacao(siglaEmpresa, formData) {
    console.log('defaults 1 =>', $axios.defaults)
    const requestTimeout = $axios.defaults.timeout

    $axios.setHeader('Content-Type', 'multipart/form-data', ['post'])

    $axios.defaults.timeout = 3 * 60000
    const request = $axios.post(`/importacoes/${siglaEmpresa}/ATV`, formData)
    console.log('defaults 2 =>', $axios.defaults)
    $axios.defaults.timeout = requestTimeout

    $axios.setHeader('Content-Type', false, ['post'])

    return request
  },

  donwloadRelatorio(siglaEmpresa, tipoAtivo) {
    return $axios.get(
      `/monitoracoes/ativos/${siglaEmpresa}/${tipoAtivo}/exportacao`,
      {
        responseType: 'arraybuffer',
      }
    )
  },

  findHistoricosAtividades(siglaEmpresa, idMonitoracao) {
    return $axios.get(
      `/monitoracoes/ativos/${siglaEmpresa}/${idMonitoracao}/historicosAtividades`
    )
  },

  findImportacoes(siglaEmpresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) {
            if (filtro[key].length === 0) continue

            for (const col of filtro[key]) {
              if (parms !== '') parms += '&'
              parms += key + '=' + col
            }
          } else {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(
      `/monitoracoes/ativos/importacoes/${siglaEmpresa}${parms}`
    )
  },

  getImportacaoById(siglaEmpresa, fase, seqImportacao) {
    return $axios.get(
      `/monitoracoes/ativos/importacoes/${siglaEmpresa}/${fase}/${seqImportacao}`
    )
  },

  aprovar(siglaEmpresa, idCiclo, sequencia) {
    return $axios.post(
      `/monitoracoes/ativos/importacoes/${siglaEmpresa}/${idCiclo}/${sequencia}/aprovar`
    )
  },

  publicar(siglaEmpresa, idCiclo, sequencia) {
    return $axios.post(
      `/monitoracoes/ativos/importacoes/${siglaEmpresa}/${idCiclo}/${sequencia}/publicar`
    )
  },

  reprovarImportacao(siglaEmpresa, idCiclo, sequencia) {
    return $axios.post(
      `/monitoracoes/ativos/importacoes/${siglaEmpresa}/${idCiclo}/${sequencia}/reprovarImportacao`
    )
  },
  reprovarPublicacao(siglaEmpresa, idCiclo, sequencia) {
    return $axios.post(
      `/monitoracoes/ativos/importacoes/${siglaEmpresa}/${idCiclo}/${sequencia}/reprovarPublicacao`
    )
  },

  findCronogramaEntregas(siglaEmpresa, tipoAtivo) {
    return $axios.get(
      `/monitoracoes/ativos/cronogramas/${siglaEmpresa}?tipoAtivo=${tipoAtivo}`
    )
  },

  downloadModeloImportacao(
    siglaEmpresa,
    tipoAtivo,
    tipoPlanilha,
    tipoConteudo,
    campos
  ) {
    if (tipoConteudo === undefined) tipoConteudo = 'MON'

    let parms = ''
    if (campos !== undefined) parms += '&campos=' + campos
    if (tipoPlanilha !== undefined) parms += '&subTipo=' + tipoPlanilha

    return $axios.get(
      `/monitoracoes/ativos/${siglaEmpresa}/${tipoAtivo}/exportacoes/modelos?tipoConteudo=${tipoConteudo}${parms}`,
      {
        responseType: 'arraybuffer',
      }
    )
  },
  
  findDashboards(siglaEmpresa, tipoAtivo, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) {
            if (filtro[key].length === 0) continue

            for (const col of filtro[key]) {
              if (parms !== '') parms += '&'
              parms += key + '=' + col
            }
          } else {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/monitoracoes/ativos/${siglaEmpresa}/${tipoAtivo}/dashboards${parms}`);
  }
})
