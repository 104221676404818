//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import menuItems from '../../../data/menu'
import menuExterno from '../../../data/menuExterno'

// Import Ensaios
// import { ensaios } from '~/data/laboratorio/ensaios/ensaios.js'

export default {
  props: {
    selectedParentMenu: {
      type: String,
      default: () => '',
    },
    viewingParentMenu: {
      type: String,
      default: () => '',
    },
    changeSelectedParentHasNoSubmenu: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      // menuItems,
      // menuItensAux: [],
      // menuExterno,

      // Ensaios
      // ensaios,

      iconDown: 'chevron-down',
    }
  },
  computed: {
    ...mapGetters({
      menuType: 'menu/getMenuType',
      menuClickCount: 'menu/getMenuClickCount',
      selectedMenuHasSubItems: 'menu/getSelectedMenuHasSubItems',
      isExterno: 'usuario/isExterno',
      permissoesUser: 'usuario/permissoes',
      moduloAtual: 'appData/modulo',
      empresa: 'empresa/empresaAtual',
    }),
  },
  methods: {
    getItensMenu() {
      const menuAux = []
      // Tratativas para não aparecer dados no menu de Ensaios no módulo Laboratório
      // let solos = 0
      // let misturas = 0
      // let ligantes = 0
      // let geral = 0
      // let concreto = 0

      // let idSub

      // // Permissão de usuários
      // for (const permissao of this.permissoesUser) {
      //   // Verificação de permissões para ensaios de Solos/agregados
      //   for (const ensaio of ensaios[0].solosAgregados) {
      //     if (permissao.nome === ensaio.permissao) {
      //       solos++
      //     }
      //   }

      //   // Verificação de permissões para ensaios de Ligantes
      //   for (const ensaio of ensaios[0].ligantes) {
      //     if (permissao.nome === ensaio.permissao) {
      //       ligantes++
      //     }
      //   }

      //   // Verificação de permissões para ensaios de Mistúras Asfalticas
      //   for (const ensaio of ensaios[0].misturaAsfaltica) {
      //     if (permissao.nome === ensaio.permissao) {
      //       misturas++
      //     }
      //   }

      //   // Verificação de permissões para ensaios de Geral
      //   for (const ensaio of ensaios[0].geral) {
      //     if (permissao.nome === ensaio.permissao) {
      //       geral++
      //     }
      //   }

      //   // Verificação de permissões para ensaios de Concreto
      //   for (const ensaio of ensaios[0].concreto) {
      //     if (permissao.nome === ensaio.permissao) {
      //       concreto++
      //     }
      //   }
      // }

      // for (const menu of menuItems) {
      //   // Identifica o item no menu chamado Ensaios
      //   if (menu.id === 'LAB02') {
      //     for (let i = 0; i < menu.subs.length; i++) {
      //       idSub = menu.subs[i].idSub
      //       // Remove do menu de Ensaios o item Solos/Agregados
      //       if (solos === 0) {
      //         if (idSub === 1) {
      //           menu.subs.splice(i, 1)
      //         }
      //       }

      //       // Remove do menu de Ensaios o item Misturas Asfalticas
      //       if (misturas === 0) {
      //         if (idSub === 2) {
      //           menu.subs.splice(i, 1)
      //         }
      //       }

      //       // Remove do menu de Ensaios o item Ligantes
      //       if (ligantes === 0) {
      //         if (idSub === 3) {
      //           menu.subs.splice(i, 1)
      //         }
      //       }

      //       // Remove do menu de Ensaios o item Concreto
      //       if (concreto === 0) {
      //         if (idSub === 4) {
      //           menu.subs.splice(i, 1)
      //         }
      //       }

      //       // Remove do menu de Ensaios o item Geral
      //       if (geral === 0) {
      //         if (idSub === 5) {
      //           menu.subs.splice(i, 1)
      //         }
      //       }
      //     }
      //   }
      // }

      for (const menu of menuItems) {
        const subMenu = []
        let flagComPermissaoSubMenu = false
        let flagSemPermissaoSubMenu = false

        if (menu.modulo === this.moduloAtual) {
          if (menu.subs !== undefined) {
            if (menu.subs.length > 0) {
              for (const menuSub of menu.subs) {
                // Adiciona o item do subMenu se tiver permissão de acesso
                for (const permissao of this.permissoesUser) {
                  // CB: Adiciona o comentário na validação do dado módulo atual
                  // Paga poder utilizar permissões que fazem parte de outros módulos
                  // if (permissao.modulo === this.moduloAtual) {
                  if (menuSub.permissao === permissao.nome) {
                    flagComPermissaoSubMenu = true
                    subMenu.push(menuSub)
                  }
                  // }
                }

                // Adiciona os itens do subMenu que não tem permissões
                if (menuSub.permissao === undefined) {
                  if (flagComPermissaoSubMenu === false) {
                    flagSemPermissaoSubMenu = true
                    subMenu.push(menuSub)
                  } else {
                    subMenu.push(menuSub)
                  }
                }
              }
            }
          }
        }

        // Recria o subMenu de permissão
        if (flagComPermissaoSubMenu === true) {
          menuAux.push({
            id: menu.id,
            modulo: menu.modulo,
            icon: menu.icon,
            label: menu.label,
            to: menu.to,
            visivel: true,
            subs: subMenu,
          })
        } else if (flagSemPermissaoSubMenu === true) {
          // Adiciona os itens que estão sem permissao no menu (publicos ?)
          menuAux.push({
            id: menu.id,
            modulo: menu.modulo,
            icon: menu.icon,
            label: menu.label,
            to: menu.to,
            visivel: true,
            subs: subMenu,
          })
        }
      }

      return menuAux
    },

    getMenuExterno() {
      const menuAux = []

      for (const menu of menuExterno) {
        const subMenu = []
        let flagComPermissaoSubMenu = false
        let flagSemPermissaoSubMenu = false

        if (menu.modulo === this.moduloAtual) {
          if (menu.subs !== undefined) {
            if (menu.subs.length > 0) {
              for (const menuSub of menu.subs) {
                // Adiciona o item do subMenu se tiver permissão de acesso
                for (const permissao of this.permissoesUser) {
                  // CB: Adiciona o comentário na validação do dado módulo atual
                  // Paga poder utilizar permissões que fazem parte de outros módulos
                  // if (permissao.modulo === this.moduloAtual) {
                  if (menuSub.permissao === permissao.nome) {
                    flagComPermissaoSubMenu = true
                    subMenu.push(menuSub)
                  }
                  // }
                }

                // Adiciona os itens do subMenu que não tem permissões
                if (menuSub.permissao === undefined) {
                  if (flagComPermissaoSubMenu === false) {
                    flagSemPermissaoSubMenu = true
                    subMenu.push(menuSub)
                  } else {
                    subMenu.push(menuSub)
                  }
                }
              }
            }
          }
        }

        // Recria o subMenu de permissão
        if (flagComPermissaoSubMenu === true) {
          menuAux.push({
            id: menu.id,
            modulo: menu.modulo,
            icon: menu.icon,
            label: menu.label,
            to: menu.to,
            visivel: true,
            subs: subMenu,
          })
        } else if (flagSemPermissaoSubMenu === true) {
          // Adiciona os itens que estão sem permissao no menu (publicos ?)
          menuAux.push({
            id: menu.id,
            modulo: menu.modulo,
            icon: menu.icon,
            label: menu.label,
            to: menu.to,
            visivel: true,
            subs: subMenu,
          })
        }
      }

      return menuAux
    },
  },
}
