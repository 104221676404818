export const state = () => ({
  idAnaliseAmbiental: null,
})

export const mutations = {
  changeAnaliseAmbiental(state, analiseAmbiental) {
    state.idAnaliseAmbiental = analiseAmbiental.id.idAnalise
  },
  clearAnaliseAmbiental(state) {
    state.idAnaliseAmbiental = null
  },
}

export const actions = {
  setAnaliseAmbiental({ commit }, analiseAmbiental) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeAnaliseAmbiental', analiseAmbiental)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearAnaliseAmbiental')
  },
}

export const getters = {
  idAnaliseAmbiental(state) {
    return state.idAnaliseAmbiental;
  }
}