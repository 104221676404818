export default ($axios) => ({
  findTipos(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/analises/tipos`)
    } else {
      return $axios.get(`/analises/tipos?search=${search}`)
    }
  },

  getTipoById(idTipo) {
    return $axios.get(`/analises/tipos/${idTipo}`)
  },

  deleteTipo(idTipo) {
    return $axios.delete(`/analises/tipos/${idTipo}`)
  },

  saveTipo(tipo) {
    return $axios.post(`/analises/tipos`, tipo)
  },
})
