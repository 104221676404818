//
//
//
//
//
//
//
//
//

import version from '~/data/version'

export default {
  data() {
    return {
      version,
    }
  },
}
