// Módulo GED
const menuGed = [
  // Módulo de GED
  {
    id: 'GED_000',
    name: 'GED_HOME',
    modulo: 'GED',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'GED_001',
    name: 'GED_001',
    modulo: 'GED',
    icon: 'file-lines',
    label: 'Consultar',
    to: '/ged/consultaDocumentos',
    permissao: 'Consulta de Documentos',
    visivel: true,
  },
  {
    id: 'GED_002',
    modulo: 'GED',
    icon: 'file',
    label: 'Novo documento',
    to: '/ged/documentoNew',
  },
  {
    id: 'GED_003',
    modulo: 'GED',
    icon: 'folder-tree',
    label: 'Hierarquia',
    to: '/ged',
  },
  {
    id: 'GED_CAD',
    modulo: 'GED',
    icon: 'table',
    label: 'Cadastros',
    to: '/ged',
    subs: [
      {
        id: 'GED_CAD_001',
        icon: 'table-list',
        label: 'Temas',
        to: '/ged/cadastro/temas',
        permissao: 'Temas/tags',
        visivel: true,
      },
      {
        id: 'GED_CAD_002',
        icon: 'table-list',
        label: 'Fluxo de Aprovação',
        to: '/ged/cadastro/fluxoAprovacao',
        visivel: true,
      },
      {
        id: 'GED_CAD_003',
        icon: 'table-list',
        label: 'Formulários',
        to: '/ged/cadastro/formulario',
        visivel: true,
      },
    ],
  },
]

// Módulo Correspondências
const menuCrp = [
  {
    id: 'CRP00',
    name: 'HOME',
    modulo: 'CRP',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'CRP01',
    modulo: 'CRP',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/correspondencias/dashboard',
    visivel: true,
  },
  {
    id: 'CRP03',
    modulo: 'CRP',
    icon: 'table-cells',
    label: 'Consulta',
    to: '/correspondencias/consulta',
    permissao: 'Consultar correspondências',
    visivel: true,
  },
  {
    id: 'CRP02',
    modulo: 'CRP',
    icon: 'envelopes-bulk',
    label: 'Nova Corresp.',
    to: '/correspondencias/nova',
    permissao: 'Adicionar correspondência',
    visivel: true,
  },
  {
    id: 'CRP04',
    modulo: 'CRP',
    icon: 'file-lines',
    label: 'Relatórios',
    visivel: true,
    subs: [
      {
        icon: 'file-lines',
        label: 'Pendências por Área',
        to: '/correspondencias/relatorios/pendenciasArea',
        permissao: 'Pendências por Área',
        visivel: true,
      },
      {
        icon: 'file-lines',
        label: 'Relatório de Terceiros',
        to: '/correspondencias/relatorios/relatorioTerceiros',
        permissao: 'Relatório de Terceiros',
        visivel: true,
      },
    ],
  },
  {
    id: 'CRP05',
    modulo: 'CRP',
    icon: 'envelopes-bulk',
    label: 'Cadastros',
    to: '/correspondencias/consulta',
    visivel: true,
    subs: [
      {
        icon: 'file-lines',
        label: 'Modelos de Numeração',
        to: '/correspondencias/cadastros/modeloNumeracao',
        permissao: 'MODELO_NUMERACAO',
        visivel: true,
      },
      {
        icon: 'file-lines',
        label: 'Modelo de Minuta',
        to: '/correspondencias/cadastros/modeloMinuta',
        permissao: 'Módulo Cadastros',
        visivel: true,
      },
      {
        icon: 'file-lines',
        label: 'Modelos de Assinaturas',
        to: '/correspondencias/cadastros/modeloAssinatura',
        permissao: 'Módulo Cadastros',
        visivel: true,
      },
    ],
  },
]

// Módulo de Contrato da Concessão
const menuCtr = [
  {
    id: 'HOME_CTR00',
    name: 'HOME',
    modulo: 'OBR',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'HOME_CTR01',
    modulo: 'OBR',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/obrigacao/dashboard',
    visivel: true,
  },
  {
    id: 'HOME_CTR02',
    modulo: 'OBR',
    icon: 'calendar-days',
    label: 'Calendário',
    to: '/obrigacao/agenda',
    permissao: 'Calendário de Obrigações',
    visivel: true,
  },
  {
    id: 'HOME_CTR03',
    modulo: 'OBR',
    icon: 'table-cells',
    label: 'Consultar',
    to: '/obrigacao/consultar',
    permissao: 'Consultar Obrigações',
    visivel: true,
  },
  {
    id: 'HOME_CTR04',
    modulo: 'OBR',
    icon: 'calendar-plus',
    label: 'Nova Obrigação',
    to: '/obrigacao/novaObrigacao',
    permissao: 'Incluir Obrigação Contratual',
    visivel: true,
  },
  {
    id: 'OBR_CTR05',
    modulo: 'OBR',
    icon: 'solar-panel',
    label: 'Controles',
    to: '/obrigacao',
    visivel: true,
    subs: [
      {
        icon: 'tree',
        label: 'Análise item contratual',
        to: '/obrigacao/controles/analiseItens',
        // permissao: 'CONTROLES_ANALISE_ITEM_CONTRATO',
        visivel: true,
      },
    ],
  },
]

// Módulo de Notificação
const menuNtf = [
  {
    id: 'HOME_NTF',
    name: 'HOME',
    modulo: 'NTF',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'NTF001',
    modulo: 'NTF',
    icon: 'chart-line',
    label: 'Dashboards',
    to: '/notificacoes/dashboards',
    visivel: true,
  },

  {
    id: 'NTF002',
    modulo: 'NTF',
    icon: 'table-cells',
    label: 'Consultar',
    to: '/notificacoes/Notificacoes/consultar',
    permissao: 'CONSULTAR_NOTIFICACOES',
    visivel: true,
  },
  {
    id: 'NTF003',
    modulo: 'NTF',
    icon: 'fa-calendar-plus',
    label: 'Nova notificação',
    to: '/notificacoes/Notificacoes/novaNotificacao',
    permissao: 'INCLUIR_NOTIFICACAO',
    visivel: true,
  },
  {
    id: 'NTF004',
    modulo: 'NTF',
    icon: 'file-export',
    label: 'Relatórios',
    to: '/',
    visivel: true,
    subs: [
      {
        icon: 'receipt',
        label: 'TROs Recebidos',
        to: '/notificacoes/relatorios/totalizador',
        permissao: 'RELATORIO_TROS_RECEBIDOS',
        visivel: true,
      },
      {
        icon: 'equals',
        label: 'Totalizador x Km',
        to: '/notificacoes/relatorios/trosRecebidos',
        permissao: 'RELATORIO_TOTAL_KMS',
        visivel: true,
      },
    ],
  },

  {
    id: 'NTF005',
    modulo: 'NTF',
    icon: 'table',
    label: 'Cadastros',
    to: '/',
    visivel: true,
    subs: [
      {
        icon: 'bell',
        label: 'Grupos da Notificação',
        to: '/notificacoes/cadastros/gruposNotificacao',
        permissao: 'Notificações - Grupos',
        visivel: true,
      },
      {
        icon: 'layer-group',
        label: 'Níveis da Notificação',
        to: '/notificacoes/cadastros/niveisNotificacao',
        permissao: 'Notificações - Nível',
        visivel: true,
      },
      {
        icon: 'comment-dots',
        label: 'Status da Notificação',
        to: '/notificacoes/cadastros/statusNotificacao',
        permissao: 'Notificações - Status',
        visivel: true,
      },
      {
        icon: 'database',
        label: 'Classes de Notificações',
        to: '/notificacoes/cadastros/classesNotificacao',
        permissao: 'Notificações - Classes',
        visivel: true,
      },
      {
        icon: 'diagram-next',
        label: 'Sub-Classes de Notificações',
        to: '/notificacoes/cadastros/subClassesNotificacoes',
        permissao: 'Notificações - Subclasses',
        visivel: true,
      },
      {
        icon: 'chalkboard-user',
        label: 'Status do Processo de Notificação',
        to: '/notificacoes/cadastros/statusProcessoNotificacao',
        permissao: 'Notificações - Status processo',
        visivel: true,
      },
    ],
  },
]

const menuQualidade = [
  {
    id: 'HOME_QUA',
    name: 'HOME',
    modulo: 'QUA',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'QUA01',
    modulo: 'QUA',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/qualidade/dashboard',
    visivel: true,
  },
  {
    id: 'QUA02',
    modulo: 'QUA',
    icon: 'window-restore',
    label: 'RACOM',
    to: '/qualidade/racom',
    permissao: 'RACOM_VISUALIZAR',
    visivel: true,
  },
  {
    id: 'QUA03',
    modulo: 'QUA',
    icon: 'check',
    label: 'CheckLists',
    to: '/qualidade/checklist',
    permissao: 'CHECKLST_VISUALIZAR',
    visivel: true,
  },
]

// Módulo de Meio Ambiente
const menuAmb = [
  {
    id: 'HOME_MAM',
    name: 'HOME',
    modulo: 'MAM',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'MAM01',
    modulo: 'MAM',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/ambiental/dashboard',
    visivel: true,
  },
  {
    id: 'MAM07',
    modulo: 'MAM',
    icon: 'calendar',
    label: 'Calendário',
    to: '/ambiental/calendario',
    visivel: true,
  },
  {
    id: 'MAM02',
    modulo: 'MAM',
    icon: 'tree',
    label: 'Fichas',
    to: '/ambiental',
    visivel: true,
    subs: [
      {
        icon: 'tree',
        label: 'RACS',
        to: '/ambiental/fichas/rac',
        permissao: 'RAC_VISUALIZAR_RAC',
        visivel: true,
      },
      {
        icon: 'shield-alt',
        label: 'Inspeções Ambientais',
        to: '/ambiental/fichas/inspecaoAmbiental',
        permissao: 'INSPECAO_VISUALIZAR',
        visivel: true,
      },
      {
        icon: 'chart-pie',
        label: 'Análises Ambientais',
        to: '/ambiental/fichas/analiseAmbiental',
        permissao: 'ANALISE_AMBIENTAL_VISUALIZAR',
        visivel: true,
      },
      {
        icon: 'check',
        label: 'CheckLists',
        to: '/ambiental/fichas/checklist',
        permissao: 'CHECKLST_VISUALIZAR',
        visivel: true,
      },
      {
        icon: 'map-signs',
        label: 'Pontos de Controle',
        to: '/ambiental/fichas/pontoControle',
        permissao: 'PONTO_CONTROLE_VISUALIZAR',
        visivel: true,
      },
      {
        icon: 'box',
        label: 'Áreas de Apoio',
        to: '/ambiental/fichas/areaApoio',
        permissao: 'AREA_APOIO_VISUALIZAR',
        visivel: true,
      },
      {
        icon: 'user-check',
        label: 'Autorização interv. área vegetada',
        to: '/ambiental/fichas/autorizacaoIntervencao',
        permissao: 'AUTORIZACAO_INTERVENCAO_VISUALIZAR',
        visivel: true,
      },
      {
        icon: 'user-tag',
        label: 'Autorização descarte/retirada',
        to: '/ambiental/fichas/autorizacaoDescarte',
        permissao: 'AUTORIZACAO_RETIRADA_VISUALIZAR',
        visivel: true,
      },
      {
        icon: 'pencil-ruler',
        label: 'Boas Práticas',
        to: '/ambiental/fichas/boasPraticas',
        permissao: 'BOA_PRATICA_VISUALIZAR',
        visivel: true,
      },
    ],
  },

  {
    id: 'MAM06',
    modulo: 'MAM',
    icon: 'leaf',
    label: 'Licenças',
    to: '/ambiental',
    visivel: true,
    subs: [
      {
        icon: 'leaf',
        label: 'Licencas Ambientais',
        to: '/ambiental/licencas',
        permissao: 'Consultar licenças',
        visivel: true,
      },
      {
        icon: 'leaf',
        label: 'Adicionar Licenças',
        to: '/ambiental/licencas/licenca',
        permissao: 'Adicionar licença',
        visivel: true,
      },

      {
        icon: 'chalkboard-user',
        label: 'Status das Licenças',
        to: '/ambiental/licencas/cadastro/statusLicencas',
        permissao: 'Status das Licenças',
        visivel: true,
      },
      {
        icon: 'chart-bar',
        label: 'Caráter das Condicionantes',
        to: '/ambiental/licencas/cadastro/caraterCondicionantes',
        permissao: 'Caráter das Condicionantes',
        visivel: true,
      },
      {
        icon: 'signal',
        label: 'Status das Condicionantes',
        to: '/ambiental/licencas/cadastro/statusCondicionantes',
        permissao: 'Status das Condicionantes',
        visivel: true,
      },
      {
        icon: 'file-alt',
        label: 'Prioridades do Plano de Ação',
        to: '/ambiental/licencas/cadastro/prioridadesPlanoAcao',
        permissao: 'Prioridades do Plano de Ação',
        visivel: true,
      },
      {
        icon: 'file-invoice',
        label: 'Tipos do Plano de Ação',
        to: '/ambiental/licencas/cadastro/tiposPlanoAcao',
        permissao: 'Tipos do Plano de Ação',
        visivel: true,
      },
      {
        icon: 'comment-dots',
        label: 'Status do Plano de Ação',
        to: '/ambiental/licencas/cadastro/statusPlanoAcao',
        permissao: 'Status do Plano de Ação',
        visivel: true,
      },
    ],
  },
  {
    id: 'MAM03',
    modulo: 'MAM',
    icon: 'table',
    label: 'Cadastros',
    to: '/ambiental',
    visivel: true,
    subs: [
      {
        icon: 'file-alt',
        label: 'Fichas - Status',
        to: '/ambiental/cadastros/statusFicha',
        permissao: 'STATUS_FICHA',
        visivel: true,
      },
      {
        icon: 'road',
        label: 'Rodovias',
        to: '/ambiental/cadastros/rodovias',
        permissao: 'RODOVIAS',
        visivel: true,
      },
      {
        icon: 'gavel',
        label: 'Requisitos Legais',
        to: '/ambiental/cadastros/requisitosLegais',
        permissao: 'REQUISITO_LEGAL',
        visivel: true,
      },
      {
        icon: 'gavel',
        label: 'Tipo Requisito Legal',
        to: '/ambiental/cadastros/tipoRequisitoLegal',
        visivel: true,
      },
      {
        icon: 'user-shield',
        label: 'Regras de Ouro',
        to: '/ambiental/cadastros/regraDeOuro',
        permissao: 'REGRA_OURO',
        visivel: true,
      },
      {
        icon: 'lock',
        label: 'Áreas Protegidas',
        to: '/ambiental/cadastros/areaProtegida',
        permissao: 'AREA_PROTEGIDA',
        visivel: true,
      },
      {
        icon: 'lock',
        label: 'Classificação de Área Protegida',
        to: '/ambiental/cadastros/areaEspecialmenteProtegida',
        permissao: 'AREA_PROTEGIDA',
        visivel: true,
      },
      {
        icon: 'exclamation-circle',
        label: 'Ocorrências',
        to: '/ambiental/cadastros/ocorrencias',
        permissao: 'OCORRENCIA',
        visivel: true,
      },
      {
        icon: 'tasks',
        label: 'Gravidades',
        to: '/ambiental/cadastros/gravidades',
        permissao: 'GRAVIDADE',
        visivel: true,
      },
      {
        icon: 'flag-checkered',
        label: 'Soluções',
        to: '/ambiental/cadastros/solucoes',
        permissao: 'SOLUCAO',
        visivel: true,
      },
      {
        icon: 'check-circle',
        label: 'Checklist - Estruturas',
        to: '/ambiental/cadastros/estruturaCheckList',
        permissao: 'CHECKLIST_ESTRUTURA',
        visivel: true,
      },
      {
        icon: 'check-square',
        label: 'CheckList - Ficha',
        to: '/ambiental/cadastros/estruturaFichaCheckList',
        permissao: 'CHECKLIST_FICHA',
        visivel: true,
      },
      {
        icon: 'window-maximize',
        label: 'Classificação de Tipos',
        to: '/ambiental/cadastros/tiposFichas',
        permissao: 'CLASSIFICACAO_TIPOS',
        visivel: true,
      },
      {
        icon: 'recycle',
        label: 'Fluxos de Aprovação',
        to: '/ambiental/cadastros/fluxoAprovacao/fluxosAprovacao',
        permissao: 'FLUXO_APROVACAO',
        visivel: true,
      },
      {
        icon: 'chart-area',
        label: 'Análise Ambiental - Grupos',
        to: '/ambiental/cadastros/analiseAmbientalGrupos',
        permissao: 'ANALISE_AMBIENTAL_GRUPO',
        visivel: true,
      },
      {
        icon: 'chart-bar',
        label: 'Análise Ambiental - Tipos',
        to: '/ambiental/cadastros/analiseAmbientalTipos',
        permissao: 'ANALISE_AMBIENTAL_TIPO',
        visivel: true,
      },
      {
        icon: 'window-maximize',
        label: 'Fator Comportamental',
        to: '/ambiental/cadastros/fatoresComportamentais',
        permissao: 'FATOR_COMPORTAMENTAL',
        visivel: true,
      },
      {
        icon: 'calendar-alt',
        label: 'Tipo de Evento',
        to: '/ambiental/cadastros/tiposEventos',
        permissao: 'TIPO_EVENTO',
        visivel: true,
      },
      {
        icon: 'chess-board',
        label: 'Eixo Estratégicos',
        to: '/ambiental/cadastros/eixoEstrategico',
        visivel: true,
      },
      {
        icon: 'chart-line',
        label: 'Linhas Negócio',
        to: '/ambiental/cadastros/linhasNegocio',
        visivel: true,
      },
      {
        icon: 'chess-board',
        label: 'Tipos Estratégicos',
        to: '/ambiental/cadastros/tiposEstrategicos',
        permissao: 'TIPO_ESTRATEGICO',
        visivel: true,
      },
      {
        icon: 'users',
        label: 'Equipes',
        to: '/ambiental/cadastros/equipes',
        permissao: 'EQUIPES',
        visivel: true,
      },
      {
        icon: 'leaf',
        label: 'Licenças Ambientais - Tipos',
        to: '/ambiental/cadastros/tipoLicencaAmbiental',
        permissao: 'TIPO_LICENCA_AMBIENTAL',
        visivel: true,
      },
      {
        icon: 'tools',
        label: 'Obras',
        to: '/ambiental/cadastros/obras',
        permissao: 'OBRAS',
        visivel: true,
      },
      {
        icon: 'wrench',
        label: 'Obras - Tipos',
        to: '/ambiental/cadastros/tiposObras',
        permissao: 'TIPO_OBRA',
        visivel: true,
      },
      {
        icon: 'layer-group',
        label: 'Ponto de Controle - Tipos',
        to: '/ambiental/cadastros/tipoPontoControle',
        permissao: 'TIPO_PONTO_CONTROLE',
        visivel: true,
      },
      {
        icon: 'tree',
        label: 'Elemento Arboreo',
        to: '/ambiental/cadastros/elementoArboreo',
        permissao: 'ELEMENTOS_ARBOREOS',
        visivel: true,
      },
      {
        icon: 'building',
        label: 'Grau de risco',
        to: '/ambiental/cadastros/grauRisco',
        visivel: true,
      },
      {
        icon: 'tree',
        label: 'Ocorrência Florestal',
        to: '/ambiental/cadastros/ocorrenciaFlorestal',
        permissao: 'OCORRENCIAS_FLORESTAIS',
        visivel: true,
      },
      {
        icon: 'check-circle',
        label: 'Status de Aprovação de Uso',
        to: '/ambiental/cadastros/statusAprovacoesUso',
        visivel: true,
      },
      {
        icon: 'check-circle',
        label: 'Status da Aprovação Agência',
        to: '/ambiental/cadastros/statusAprovacaoAgencia',
        visivel: true,
      },
      {
        icon: 'box',
        label: 'Material Apoio',
        to: '/ambiental/cadastros/materiaisApoio',
        visivel: true,
      },
      {
        icon: 'box',
        label: 'Parecer Autorizativo',
        to: '/ambiental/cadastros/parecerAutorizativo',
        visivel: true,
      },
      {
        icon: 'box',
        label: 'Orgão Aprovador',
        to: '/ambiental/cadastros/orgaoAprovador',
        visivel: true,
      },
      {
        icon: 'building',
        label: 'Situação Agência',
        to: '/ambiental/cadastros/situacaoAgencia',
        visivel: true,
      },
      {
        icon: 'chart-bar',
        label: 'Status Utilização',
        to: '/ambiental/cadastros/statusUtilizacao',
        visivel: true,
      },
      {
        icon: 'user',
        label: 'Usuários',
        to: '/ambiental/cadastros/usuarios',
        permissao: 'USUARIOS_AMBIENTAL',
        visivel: true,
      },
      {
        icon: 'earth-americas',
        label: 'Impactos Ambientais',
        to: '/ambiental/cadastros/impactoAmbiental',
        permissao: 'IMPACTO_AMBIENTAL',
        visivel: true,
      },
    ],
  },
  {
    id: 'MAM04',
    modulo: 'MAM',
    icon: 'map',
    label: 'Mapas',
    to: '/ambiental/mapas',
    visivel: true,
  },
  {
    id: 'MAM05',
    modulo: 'MAM',
    icon: 'solar-panel',
    label: 'Controles',
    to: '/ambiental',
    visivel: true,
    subs: [
      {
        icon: 'tree',
        label: 'IQMAS',
        to: '/ambiental/controles/iqmas',
        permissao: 'CONTROLES_IQMA',
        visivel: true,
      },
    ],
  },
]

// Módulo de Demanda
const menuDem = [
  {
    id: 'HOME_DEM',
    name: 'HOME',
    modulo: 'DEM',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'DASHBOARDS_DEM',
    name: 'DASHBOARDS',
    modulo: 'DEM',
    icon: 'chart-line',
    label: 'Dashboards',
    to: '/demandas/dashboards',
    visivel: true,
  },

  {
    id: 'CONSULTAR_DEM',
    name: 'CONSULTAR',
    modulo: 'DEM',
    icon: 'table-cells',
    label: 'Consultar',
    to: '/demandas/consultar',
    permissao: 'Demandas - Consulta demandas',
    visivel: true,
  },
  {
    id: 'DEMANDAS_DEM',
    name: 'DEMANDAS',
    modulo: 'DEM',
    icon: 'fa-calendar-plus',
    label: 'Nova Demanda',
    to: '/demandas/demanda',
    permissao: 'Demandas - Alterar demanda',
    visivel: true,
  },

  {
    id: 'CADASTROS_DEM',
    name: 'CADASTROS',
    modulo: 'DEM',
    icon: 'user-pen',
    label: 'Cadastros',
    to: '/',
    visivel: true,
    subs: [
      {
        icon: 'chart-area',
        label: 'Áreas',
        to: '/demandas/areas',
        permissao: 'Demandas - Áreas',
        visivel: true,
      },
      {
        icon: 'bullseye',
        label: 'Objetivos',
        to: '/demandas/objetivos',
        permissao: 'Demandas - Objetivos',
        visivel: true,
      },
      {
        icon: 'file-alt',
        label: 'Status',
        to: '/demandas/status',
        permissao: 'Demandas - Status',
        visivel: true,
      },
      {
        icon: 'wrench',
        label: 'Tipos',
        to: '/demandas/tipos',
        permissao: 'Demandas - Tipos',
        visivel: true,
      },
      {
        icon: 'address-book',
        label: 'Índices Priorização',
        to: '/demandas/indicesPriorizacao',
        permissao: 'Índices de Priorização',
        visivel: true,
      },
      {
        icon: 'percent',
        label: 'Pesos Priorização',
        to: '/demandas/pesosPriorizacao',
        permissao: 'Demandas - Pesos de Priorização',
        visivel: true,
      },
    ],
  },
]

const menuPlt = [
  // Módulo de Pleitos
  {
    id: 'HOME_PLT',
    name: 'HOME',
    modulo: 'PLT',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'DASHBOARDS_PLT',
    name: 'DASHBOARDS',
    modulo: 'PLT',
    icon: 'chart-line',
    label: 'Dashboards',
    to: '/pleitos/pleitos/dashboards',
    permissao: 'DASHBOARD_PLEITOS',
    visivel: true,
  },
  {
    id: 'CONSULTAR_PLT',
    name: 'CONSULTAR',
    modulo: 'PLT',
    icon: 'table-cells',
    label: 'Consultar',
    to: '/pleitos/pleitos/consultar',
    permissao: 'Lista Pleitos - Consulta',
    visivel: true,
  },
  {
    id: 'NOVOPLEITO_PLT',
    name: 'NOVOPLEITO',
    modulo: 'PLT',
    icon: 'file',
    label: 'Novo Pleito',
    to: '/pleitos/pleitos/novoPleito',
    permissao: 'Lista Pleitos - Adicionar pleito',
    visivel: true,
  },

  {
    id: 'RELATORIOS_PLT',
    name: 'RELATORIOS',
    modulo: 'PLT',
    icon: 'file-export',
    label: 'Relatórios',
    to: '/',
    visivel: true,
    subs: [
      {
        icon: 'sheet-plastic',
        label: 'Reequilíbrios Contratuais',
        to: '/pleitos/relatorios/reequilibriosContratuais',
        permissao: 'Reequilíbrio',
        visivel: true,
      },
      {
        icon: 'prescription-bottle',
        label: 'Análise Reequilíbrios',
        to: '/pleitos/relatorios/analiseReequilibrios',
        permissao: 'Análise Reequilíbrios',
        visivel: true,
      },
      {
        icon: 'file-contract',
        label: 'Cronograma contrato',
        to: '/pleitos/relatorios/cronogramaContrato',
        permissao: 'Cronograma contrato',
        visivel: true,
      },
      {
        icon: 'calendar',
        label: 'Cronograma contrato Revisão tarifária',
        to: '/pleitos/relatorios/cronogramaContratoReviasaoTarifaria',
        permissao: 'Cronograma PER - Revisão tarifária',
        visivel: true,
      },
      {
        icon: 'weight-hanging',
        label: 'Reequilíbrios aprovados após revisão',
        to: '/pleitos/relatorios/reequilibriosAprovadosRevisaoTarifaria',
        permissao: 'Bridge',
        visivel: true,
      },
      {
        icon: 'x',
        label: 'Apuração Inexecuções',
        to: '/pleitos/relatorios/apuracaoinexecucoes',
        permissao: 'Apuração Inexecuções',
        visivel: true,
      },
    ],
  },
  {
    id: 'CADASTROS_PLT',
    name: 'CADASTROS',
    modulo: 'PLT',
    icon: 'user-pen',
    label: 'Cadastros',
    to: '/',
    visivel: true,
    subs: [
      {
        icon: 'layer-group',
        label: 'Tipos de Pleito',
        to: '/pleitos/cadastros/tipoPleito',
        permissao: 'Tipos de Pleito',
        visivel: true,
      },
      {
        icon: 'keyboard',
        label: 'Tipos de Característica',
        to: '/pleitos/cadastros/tipoCaracteristica',
        permissao: 'Tipos de Caracteristica',
        visivel: true,
      },
      {
        icon: 'database',
        label: 'Níveis na Agência',
        to: '/pleitos/cadastros/niveisAgencia',
        permissao: 'Niveis de Agência',
        visivel: true,
      },
      {
        icon: 'signal',
        label: 'Status de Pleito',
        to: '/pleitos/cadastros/statusPleito',
        permissao: 'Status do Pleito',
        visivel: true,
      },
      {
        icon: 'prescription-bottle',
        label: 'Tipos de Desequilíbrio',
        to: '/pleitos/cadastros/tipoDesequilibrio',
        permissao: 'Tipos de Desequilíbrio',
        visivel: true,
      },
      {
        icon: 'signal',
        label: 'Status na Agência',
        to: '/pleitos/cadastros/statusAgencia',
        permissao: 'Status na Agência',
        visivel: true,
      },
      {
        icon: 'calendar',
        label: 'Prioridade dos Pleitos',
        to: '/pleitos/cadastros/prioridadePleitos',
        permissao: 'Prioridades dos Pleitos',
        visivel: true,
      },
      {
        icon: 'layer-group',
        label: 'Grupos de Trabalho',
        to: '/pleitos/cadastros/gruposTrabalho',
        permissao: 'Grupos de Trabalho',
        visivel: true,
      },
      {
        icon: 'user-pen',
        label: 'Revisões tarifárias',
        to: '/pleitos/cadastros/revisoesTarifarias',
        permissao: 'Revisões Tarifárias',
        visivel: true,
      },
      {
        icon: 'check-double',
        label: 'Probabilidade de Sucesso',
        to: '/pleitos/cadastros/probabilidadeSucesso',
        permissao: 'Possibilidades de Êxito',
        visivel: true,
      },
      {
        icon: 'cash-register',
        label: 'Faixa de Valor',
        to: '/pleitos/cadastros/faixaValor',
        permissao: 'Pleitos - Faixa Valor',
        visivel: true,
      },
    ],
  },
]

// Módulo de Conserva
const menuCon = [
  {
    id: 'HOME_CSV',
    name: 'HOME',
    modulo: 'CSV',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'CSV01',
    modulo: 'CSV',
    icon: 'check',
    label: 'CheckLists',
    to: '/conservacao/checklist',
    visivel: true,
  },
]

const menuSig = [
  {
    id: 'HOME_SIG',
    name: 'HOME',
    modulo: 'SIG',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'SIG01',
    modulo: 'SIG',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/sig/dashboard',
    visivel: true,
  },
  {
    id: 'SIG02',
    modulo: 'SIG',
    icon: 'map',
    label: 'Mapas',
    to: '/sig/mapa',
    visivel: true,
  },
  {
    id: 'SIG03',
    modulo: 'SIG',
    icon: 'table',
    label: 'Elementos',
    to: '/sig/elementos',
    visivel: true,
  },
  {
    id: 'SIG04',
    modulo: 'SIG',
    icon: 'table',
    label: 'Cadastros',
    to: '/sig/cadastros',
    visivel: true,
    subs: [
      {
        icon: 'prescription-bottle',
        label: 'Camadas',
        to: '/sig/cadastros/camada',
        visivel: true,
      },
      {
        icon: 'prescription-bottle',
        label: 'Camadas Base',
        to: '/sig/cadastros/camadaBase',
        visivel: true,
      },
      {
        icon: 'prescription-bottle',
        label: 'Estutura de Dados',
        to: '/sig/cadastros/estruturaDados',
        visivel: true,
      },
    ],
  },
]

// Módulo de Ativos
const menuAtv = [
  {
    id: 'ATV01',
    modulo: 'ATV',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/ativos/dashboard',
    visivel: true,
  },
  {
    id: 'ATV02',
    modulo: 'ATV',
    icon: 'map',
    label: 'Mapas',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'location-dot',
        label: 'Mapas',
        to: '/ativos/mapa',
        visivel: true,
      },
      {
        icon: 'location-dot',
        label: 'Mapas de Calor',
        to: '/ativos/mapa/mapaCalor',
        visivel: true,
      },
    ],
  },
  {
    id: 'ATV03',
    modulo: 'ATV',
    icon: 'edit',
    label: 'Cadastros',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        label: 'Ativos',
        visivel: true,
        subs: [
          {
            icon: 'water',
            label: 'Drenagem/OAC',
            to: '/ativos/cadastro/drenagem',
            visivel: true,
          },
          {
            icon: 'road',
            label: 'Sinalização Horizontal',
            to: '/ativos/cadastro/sinalizacaoHorizontal',
            visivel: true,
          },
          {
            icon: 'traffic-light',
            label: 'Sinalização Vertical',
            to: '/ativos/cadastro/sinalizacaoVertical',
            visivel: true,
          },
          {
            icon: 'road-barrier',
            label: 'EPS',
            to: '/ativos/cadastro/eps',
            visivel: true,
          },
          {
            icon: 'snowplow',
            label: 'Terraplenos/Estr. Cont.',
            to: '/ativos/cadastro/terraplenos',
            visivel: true,
          },
        ],
      },
      {
        label: 'Geral',
        visivel: true,
        subs: [
          {
            icon: 'chart-pie',
            label: 'Categorias',
            to: '/ativos/categorias',
            visivel: true,
          },
          {
            icon: 'chart-bar',
            label: 'Parâmetros',
            to: '/ativos/parametros',
            visivel: true,
          },
          {
            icon: 'brazilian-real-sign',
            label: 'Preços Unitários',
            to: '/cadastros/precoUnitario',
            visivel: true,
          },
          {
            icon: 'cloud-sun',
            label: 'Eventos',
            to: '/ativos/cadastro/eventos',
            visivel: true,
          },
          {
            icon: 'clipboard',
            label: 'Tipos eventos',
            to: '/ativos/cadastro/tiposEventos',
            visivel: true,
          },
          {
            icon: 'road',
            label: 'Sinalização - Estação',
            to: '/ativos/cadastro/estacoes',
            visivel: true,
          },
          {
            icon: 'user-group',
            label: 'Fornecedores',
            permissao: 'FORNECEDORES',
            to: '/ativo/cadastro/fornecedores',
            visivel: true,
          },
        ],
      },
    ],
  },
  {
    id: 'ATV04',
    modulo: 'ATV',
    icon: 'panorama',
    label: 'Monitoração',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'water',
        label: 'Drenagem/OAC',
        to: '/ativos/monitoracao/drenagem',
        visivel: true,
      },
      {
        icon: 'road',
        label: 'Sinalizaçao Horizontal',
        to: '/ativos/monitoracao/sinalizacaoHorizontal',
        visivel: true,
      },
      {
        icon: 'traffic-light',
        label: 'Sinalização Vertical',
        to: '/ativos/monitoracao/sinalizacaoVertical',
        visivel: true,
      },
      {
        icon: 'road-barrier',
        label: 'EPS',
        to: '/ativos/monitoracao/eps',
        visivel: true,
      },
      {
        icon: 'snowplow',
        label: 'Terraplenos/Estr. Contenção',
        to: '/ativos/monitoracao/terrapleno',
        visivel: true,
      },
      {
        icon: 'building',
        label: 'Edificações',
        to: '/ativos/monitoracao/edificacao',
        visivel: true,
      },
      {
        icon: 'recycle',
        label: 'Ciclos de Monitoração',
        to: '/ativos/cadastro/cicloMonitoracao',
        visivel: true,
      },
      {
        icon: 'calendar',
        label: 'Cronograma de Entrega',
        to: '/ativos/monitoracao/cronogramaEntrega',
        visivel: true,
      },
    ],
  },
  {
    id: 'ATV05',
    modulo: 'ATV',
    icon: 'list-check',
    label: 'Aprovação',
    to: '/ativos/aprovacao',
    visivel: true,
  },
  {
    id: 'ATV06',
    modulo: 'ATV',
    icon: 'tasks',
    label: 'Inspeção',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'water',
        label: 'Drenagem/OAC',
        to: '/ativos/inspecao/drenagem',
        visivel: true,
      },
      {
        icon: 'road',
        label: 'Sinalizaçao Horizontal',
        to: '/ativos/inspecao/sinalizacaoHorizontal',
        visivel: true,
      },
      {
        icon: 'traffic-light',
        label: 'Sinalização Vertical',
        to: '/ativos/inspecao/sinalizacaoVertical',
        visivel: true,
      },
      {
        icon: 'bolt',
        label: 'EPS',
        to: '/ativos/inspecao/eps',
        visivel: true,
      },
      {
        icon: 'snowplow',
        label: 'Terraplenos/Estr.Cont.',
        to: '/ativos/inspecao/terrapleno',
        visivel: true,
      },
      {
        icon: 'building',
        label: 'Edificações',
        to: '/ativos/inspecao/edificacao',
        visivel: true,
      },
    ],
  },
  {
    id: 'ATV07',
    modulo: 'ATV',
    icon: 'file-invoice-dollar',
    label: 'Planejamento',
    to: '/ativos',
    visivel: true,
    subs: [
      {
        icon: 'water',
        label: 'Drenagem/OAC',
        to: '/ativos/planejamento/drenagem',
        visivel: true,
      },
      {
        icon: 'road',
        label: 'Sinalizaçao Horizontal',
        to: '/ativos/planejamento/sinalizacaoHorizontal',
        visivel: true,
      },
      {
        icon: 'traffic-light',
        label: 'Sinalização Vertical',
        to: '/ativos/planejamento/sinalizacaoVertical',
        visivel: true,
      },
      {
        icon: 'bolt',
        label: 'EPS',
        to: '/ativos/planejamento/eps',
        visivel: true,
      },
      {
        icon: 'snowplow',
        label: 'Terraplenos/Estr.Cont.',
        to: '/ativos/planejamento/terrapleno',
        visivel: true,
      },
      {
        icon: 'building',
        label: 'Edificações',
        to: '/ativos/planejamento/edificacao',
        visivel: true,
      },
    ],
  },
]

// Módulo SIGACO
const menuSigaco = [
  {
    id: 'HOME_GAC',
    name: 'HOME',
    modulo: 'GAC',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'SGC02',
    idSub: 2,
    modulo: 'GAC',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/sigaco/dashboard',
    permissao: 'GAC_DASHBOARDS',
    visivel: true,
  },
  {
    id: 'SGC03',
    modulo: 'GAC',
    icon: 'chalkboard-teacher',
    label: 'Monitorações',
    to: '/sigaco/monitoracoes',
    visivel: true,
    subs: [
      {
        icon: 'road',
        label: 'SGP - Pavimento',
        to: '/sigaco/monitoracao/pavimento',
        visivel: true,
      },
      {
        icon: 'traffic-light',
        label: 'SGS -  Sinalização',
        to: '/sigaco/monitoracao/sinalizacao',
        visivel: true,
      },
      {
        icon: 'archway',
        label: 'SGOAE - OAE',
        to: '/sigaco/monitoracao/obraArteEspecial',
        visivel: true,
      },
      {
        icon: 'water',
        label: 'SGOAC - Drenagem/OAC',
        to: '/sigaco/monitoracao/drenagem',
        visivel: true,
      },
      {
        icon: 'snowplow',
        label: 'SGTEC - Terrap./Contenção',
        to: '/sigaco/monitoracao/terrapleno',
        visivel: true,
      },
      {
        icon: 'route',
        label: 'SGF - Faixa Domínio',
        to: '/sigaco/monitoracao/faixaDominio',
        visivel: true,
      },
      {
        icon: 'building',
        label: 'SGE - Edific./Instalações',
        to: '/sigaco/monitoracao/edificacaoInstalacao',
        visivel: true,
      },
      {
        icon: 'bolt',
        label: 'SGSEI - Sistemas Elétricos',
        to: '/sigaco/monitoracao/sistemaEletrico',
        visivel: true,
      },
      {
        icon: 'bolt',
        label: 'SGITS - Equipamentos de ITS',
        to: '/sigaco/monitoracao/its',
        visivel: true,
      },
      {
        icon: 'archway',
        label: 'SGOST - Oper./Seg. Túneis',
        to: '/sigaco/monitoracao/operacaoSegurancaTunel',
        visivel: true,
      },
      {
        icon: 'list-check',
        label: 'Aprovação de Monitorações',
        to: '/sigaco/aprovacao',
        visivel: true,
      },
    ],
  },
  {
    id: 'SGC06',
    modulo: 'GAC',
    icon: 'table',
    label: 'Cadastros',
    to: '/',
    visivel: true,
    subs: [
      {
        icon: 'prescription-bottle',
        label: 'Tipos de Elementos',
        to: '/sigaco/cadastro/tipoElemento/list',
        visivel: true,
      },
      {
        icon: 'chart-line',
        label: 'Fases de Monitoração',
        to: '/sigaco/cadastro/faseMonitoracao',
        visivel: true,
      },
      {
        icon: 'ruler',
        label: 'Parâmetros contratuais',
        to: '/sigaco/cadastro/parametrosContratuais',
        visivel: true,
      },
      {
        icon: 'water',
        label: 'SGOAC - Drenagem/OAC',
        to: '/sigaco/cadastro/elementos/drenagem',
        visivel: true,
      },
      {
        icon: 'traffic-light',
        label: 'SGP - Pavimento',
        to: '/sigaco/cadastro/elementos/pavimento',
        visivel: true,
      },
      {
        icon: 'archway',
        label: 'SGS -  Sinalização',
        to: '/sigaco/cadastro/elementos/sinalizacao',
        visivel: true,
      },
      {
        icon: 'water',
        label: 'SGOAE - OAE',
        to: '/sigaco/cadastro/elementos/obraArteEspecial',
        visivel: true,
      },
      {
        icon: 'snowplow',
        label: 'SGTEC - Terrap./Contenção',
        to: '/sigaco/cadastro/elementos/terrapleno',
        visivel: true,
      },
      {
        icon: 'route',
        label: 'SGF - Faixa Domínio',
        to: '/sigaco/cadastro/elementos/faixaDominio',
        visivel: true,
      },
      {
        icon: 'building',
        label: 'SGE - Edific./Instalações',
        to: '/sigaco/cadastro/elementos/edificacao',
        visivel: true,
      },
      {
        icon: 'bolt',
        label: 'SGSEI - Sistemas Elétricos',
        to: '/sigaco/cadastro/elementos/sistemasEletricos',
        visivel: true,
      },
      {
        icon: 'archway',
        label: 'SGOST - Oper./Seg. Túneis',
        to: '/sigaco/cadastro/elementos/operacaoSegurancaTuneis',
        visivel: true,
      },
    ],
  },
  {
    id: 'SGC07',
    modulo: 'GAC',
    permissao: 'GAC_CONSULTAR_MAPA',
    icon: 'map',
    label: 'Mapas',
    to: '/sigaco/mapa',
    visivel: true,
  },
]

// Módulo de Laboratório
export const menuLab = [
  {
    id: 'HOME_LAB',
    name: 'HOME',
    modulo: 'LAB',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'LAB01',
    modulo: 'LAB',
    icon: 'chart-line',
    label: 'Dashboard',
    to: '/laboratorio/dashboard',
    permissao: 'LAB_DASHBOARDS',
    visivel: true,
  },
  {
    id: 'LAB02',
    modulo: 'LAB',
    icon: 'vials',
    label: 'Ensaios',
    to: '/laboratorio',
    visivel: true,
    subs: [
      {
        idSub: 1,
        icon: 'vial',
        label: 'Solos / Agregados',
        to: '/laboratorio/ensaios/solosAgregados',
        permissao: 'CONSULTAR_ENSAIOS',
        visivel: true,
      },
      {
        idSub: 2,
        icon: 'vial',
        label: 'Misturas Asfálticas',
        to: '/laboratorio/ensaios/misturasAsfalticas',
        permissao: 'CONSULTAR_ENSAIOS',
        visivel: true,
      },
      {
        idSub: 3,
        icon: 'vial',
        label: 'Ligantes',
        to: '/laboratorio/ensaios/ligantes',
        permissao: 'CONSULTAR_ENSAIOS',
        visivel: true,
      },
      {
        idSub: 4,
        icon: 'vial',
        label: 'Concreto',
        to: '/laboratorio/ensaios/concreto',
        permissao: 'CONSULTAR_ENSAIOS',
        visivel: true,
      },
      {
        idSub: 5,
        icon: 'vial',
        label: 'Geral',
        to: '/laboratorio/ensaios/geral',
        permissao: 'CONSULTAR_ENSAIOS',
        visivel: true,
      },
      {
        idSub: 6,
        icon: 'upload',
        label: 'Importação',
        to: '/laboratorio/ensaios/importacao',
        permissao: 'UPLOAD_FICHA_ENSAIO',
        visivel: true,
      },
    ],
  },
  {
    id: 'LAB03',
    modulo: 'LAB',
    icon: 'project-diagram',
    label: 'Projetos',
    to: '/laboratorio/projetos',
    permissao: 'PROJETOS_LISTAR',
    visivel: true,
  },
  {
    id: 'LAB04',
    modulo: 'LAB',
    icon: 'file-excel',
    label: 'Fichas',
    to: '/laboratorio/fichas',
    permissao: 'FICHAS_LISTAR',
    visivel: true,
  },
  {
    id: 'LAB05',
    modulo: 'LAB',
    icon: 'table',
    label: 'Cadastros',
    to: '/laboratorio/cadastros',
    visivel: true,
    subs: [
      {
        icon: 'hard-hat',
        label: 'Obras',
        permissao: 'OBRAS',
        to: '/laboratorio/cadastros/obras',
        visivel: true,
      },
      {
        icon: 'road',
        label: 'Rodovias',
        permissao: 'RODOVIAS',
        to: '/laboratorio/cadastros/rodovias',
        visivel: true,
      },
      // Ocultado pois está fora do ciclo de vida do projeto
      /* {
        icon: 'vial',
        label: 'Ligantes',
        to: '/laboratorio/cadastros/ligantes',
      },
      {
        icon: 'water',
        label: 'Parâmetros Ensaios',
        to: '/laboratorio/cadastros/parametrosEnsaios',
      }, */
      {
        icon: 'exclamation-circle',
        // svg: 'peneira',
        label: 'Peneiras',
        permissao: 'PENEIRAS_LISTAR',
        to: '/laboratorio/cadastros/peneiras',
        visivel: true,
      },
      {
        icon: 'user',
        // svg: 'peneira',
        label: 'Supervisor',
        to: '/laboratorio/cadastros/supervisores',
        permissao: 'ALTERAR_SUPERVISOR',
        visivel: true,
      },
      {
        icon: 'file-lines',
        label: 'Plano Auditoria',
        to: '/laboratorio/cadastros/planoAuditoria',
        permissao: 'PLANO_AUDITORIA_LISTAR',
        visivel: true,
      },
      {
        icon: 'ruler-combined',
        label: 'Unidades de Medida',
        permissao: 'UNIDADES_MEDIDA',
        to: '/laboratorio/cadastros/unidadeMedida',
        visivel: true,
      },
      {
        icon: 'user-group',
        label: 'Fornecedores',
        permissao: 'FORNECEDORES',
        to: '/laboratorio/cadastros/fornecedores',
        visivel: true,
      },
      {
        icon: 'layer-group',
        label: 'Grupos de Serviços',
        permissao: 'LAB_VISUALIZAR_GRUPO_SERVICO_AUDITORIA',
        to: '/laboratorio/cadastros/grupoServico',
        visivel: true,
      },
      {
        icon: 'users',
        label: 'Equipes',
        to: '/laboratorio/cadastros/equipes',
        permissao: 'EQUIPES',
        visivel: true,
      },
      {
        icon: 'user',
        label: 'Usuários',
        to: '/laboratorio/cadastros/usuarios',
        permissao: 'USUARIOS_LABORATORIO',
        visivel: true,
      },
    ],
  },
  {
    id: 'LAB06',
    modulo: 'LAB',
    icon: 'solar-panel',
    label: 'Controles',
    visivel: true,
    subs: [
      {
        icon: 'file-invoice-dollar',
        label: 'Fator pagamento',
        to: '/laboratorio/fatorPagamento',
        permissao: 'FATOR_PAGTO_LISTAR',
        visivel: true,
      },
      {
        icon: 'file-invoice-dollar',
        label: 'Fator pagamento - Relatórios',
        to: '/laboratorio/controles/fatorPagamentoRelatorios',
        permissao: 'LAB_CADASTRAR_RELATORIO_FATOR_PAGAMENTO',
        visivel: true,
      },
      {
        icon: 'file-lines',
        label: 'Plano Auditoria',
        to: '/laboratorio/controles/planoAuditoria',
        permissao: 'PLANO_AUDITORIA_LISTAR',
        visivel: true,
      },
      {
        icon: 'file-lines',
        label: 'Controle Estatístico',
        to: '/laboratorio/controles/controleEstatistico',
        permissao: 'LAB_CONSULTAR_CONTROLES_ESTATISTICOS',
        visivel: true,
      },
    ],
  },
]

// Módulo de Relações Institucionais
const menuRI = [
  {
    id: 'HOME_RLI',
    name: 'HOME',
    modulo: 'RLI',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'CALENDARIO_RLI',
    name: 'CALENDARIO',
    modulo: 'RLI',
    icon: 'calendar',
    label: 'Calendario',
    to: '/relacoes/calendario',
    permissao: 'Relações Institucionais - Agenda',
    visivel: true,
  },
  {
    id: 'REUNIOES_RLI',
    name: 'REUNIOES',
    modulo: 'RLI',
    icon: 'fa-message',
    label: 'Reuniões',
    to: '/',
    visivel: true,
    subs: [
      {
        icon: 'file-lines',
        label: 'Consultar',
        to: '/relacoes/reunioes/consulta',
        permissao: 'Relações Institucionais - Consulta',
        visivel: true,
      },
      {
        icon: 'fa-calendar',
        label: 'Agendar reunião',
        to: '/relacoes/agendamentos/_agendamento',
        permissao: 'Relações Institucionais - Agendar Reunião',
        visivel: true,
      },
      {
        icon: 'fa-calendar-plus',
        label: 'Nova reunião',
        to: '/relacoes/reunioes/reuniao/_reuniao',
        permissao: 'Relações Institucionais - Nova Reunião',
        visivel: true,
      },
    ],
  },
  {
    id: 'INFO_RLI',
    name: 'INFORMACOES',
    modulo: 'RLI',
    icon: 'fa-circle-info',
    label: 'Informações',
    to: '/',
    visivel: true,
    subs: [
      {
        icon: 'file-lines',
        label: 'Consultar',
        to: '/relacoes/consultarInformacao',
        permissao: 'Relações Institucionais - Consulta Informações',
        visivel: true,
      },
      {
        icon: 'cash-register',
        label: 'Novo registro',
        to: '/relacoes/editInformacao/_novaInformacao',
        permissao: 'Relações Institucionais - Nova Informação',
        visivel: true,
      },
    ],
  },
]

// Módulo de Cadastros
const menuCad = [
  {
    id: 'HOME_CAD',
    name: 'HOME',
    modulo: 'CAD',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'CAD-EMPRESAS',
    name: 'EMPRESAS',
    modulo: 'CAD',
    icon: 'building',
    label: 'Empresas',
    to: '/cadastros/empresas',
    permissao: 'Empresas',
    visivel: true,
  },
  {
    id: 'CAD-ENTIDADE',
    name: 'ENTIDADE',
    modulo: 'CAD',
    icon: 'clipboard',
    label: 'Entidades',
    to: '/cadastros/entidades',
    permissao: 'Entidades',
    visivel: true,
  },
  {
    id: 'CAD-RODOVIA',
    name: 'RODOVIA',
    modulo: 'CAD',
    icon: 'road',
    label: 'Rodovias',
    to: '/cadastros/rodovias',
    permissao: 'RODOVIAS',
    visivel: true,
  },
  {
    id: 'CAD-TRECHO',
    name: 'TRECHO',
    modulo: 'CAD',
    icon: 'file-invoice',
    label: 'Trechos',
    to: '/cadastros/trechos',
    permissao: 'TRECHO_GRUPO',
    visivel: true,
  },
  {
    id: 'CAD-CIDADE',
    name: 'CIDADE',
    modulo: 'CAD',
    icon: 'city',
    label: 'Cidades',
    to: '/cadastros/cidades',
    permissao: 'Cidades',
    visivel: true,
  },
  {
    id: 'CAD-PODER-CONCEDENTE',
    name: 'PODER-CONCEDENTE',
    modulo: 'CAD',
    icon: 'archway',
    label: 'Poder Concedente',
    to: '/cadastros/poderConcedente',
    permissao: 'Poder Concedente',
    visivel: true,
  },
  {
    id: 'CAD-TRECHO-HOMOGENIO',
    name: 'TRECHO-HOMOGENIO',
    modulo: 'CAD',
    icon: 'file-invoice',
    label: 'Trechos Homo',
    permissao: 'TRECHO',
    to: '/cadastros/trechosHomogeneos',
    visivel: true,
  },
  {
    id: 'CAD-OBRA',
    name: 'OBRA',
    modulo: 'CAD',
    icon: 'hard-hat',
    label: 'Obras',
    permissao: 'OBRAS',
    to: '/cadastros/obras',
    visivel: true,
  },
  {
    id: 'CAD-CALENDARIO',
    name: 'CALENDARIO',
    modulo: 'CAD',
    icon: 'calendar-alt',
    label: 'Calendário',
    to: '/cadastros/calendario',
    permissao: 'Calendário',
    visivel: true,
  },
  {
    id: 'CAD-TEMAS',
    name: 'TEMAS',
    modulo: 'CAD',
    icon: 'bookmark',
    label: 'Temas',
    to: '/cadastros/temas',
    permissao: 'Temas/tags',
    visivel: true,
  },
  {
    id: 'CAD-UNIDADE-MEDIDA',
    name: 'UNIDADE-MEDIDA',
    modulo: 'CAD',
    icon: 'ruler-combined',
    label: 'Unidades de Medida',
    to: '/cadastros/unidadeMedida',
    permissao: 'Unidades de Medida',
    visivel: true,
  },
  {
    id: 'CAD-TIPO-DOCUMENTO',
    name: 'TIPO-DOCUMENTO',
    modulo: 'CAD',
    icon: 'file-invoice',
    label: 'Tipos Doc',
    to: '/cadastros/tiposDeDocumento',
    permissao: 'Tipos de Correspondência',
    visivel: true,
  },
  {
    id: 'CAD-TIPO-FLUXO-CAIXA',
    name: 'TIPO-FLUXO-CAIXA',
    modulo: 'CAD',
    icon: 'cash-register',
    label: 'Tipo de Fluxo de Caixa',
    to: '/cadastros/tipoFluxoCaixa',
    permissao: 'Tipos de Fluxo de Caixa',
    visivel: true,
  },
  {
    id: 'CAD-INDICE-REAJUSTE',
    name: 'INDICE-REAJUSTE',
    modulo: 'CAD',
    icon: 'pencil-ruler',
    label: 'Índice de Reajuste',
    to: '/cadastros/indicesReajuste',
    permissao: 'INDICES_REAJUSTE',
    visivel: true,
  },
  {
    id: 'CAD-FORNECEDOR',
    name: 'FORNECEDOR',
    modulo: 'CAD',
    icon: 'user-group',
    label: 'Fornecedores',
    to: '/cadastros/fornecedores',
    permissao: 'FORNECEDORES',
    visivel: true,
  },
]

// Módulo de Segurança
const menuSeg = [
  {
    id: 'HOME_SEG',
    name: 'HOME',
    modulo: 'SEG',
    icon: 'home',
    label: 'Início',
    to: '/',
    visivel: true,
  },
  {
    id: 'SEG-USUARIO',
    name: 'USUARIO',
    modulo: 'SEG',
    icon: 'user',
    label: 'Usuários',
    to: '/seguranca/usuarios',
    visivel: true,
  },
  {
    id: 'SEG-GRUPOS-USUARIOS',
    name: 'GRUPOS-USUARIOS',
    modulo: 'SEG',
    icon: 'users',
    label: 'Grupos',
    to: '/seguranca/gruposAcesso',
    visivel: true,
  },
  // CB - Adicionado as permissões de acesso junto com Grupos de Acesso
  // {
  //   id: 'SEG-PERMISSOES-ACESSO',
  //   name: 'PERMISSOES-ACESSO',
  //   modulo: 'SEG',
  //   icon: 'user-shield',
  //   label: 'Permissões',
  //   to: '/seguranca/permissoesAcesso',
  //   visivel: true,
  // },
  {
    id: 'SEG-AUDITORIA',
    name: 'AUDITORIA',
    modulo: 'SEG',
    icon: 'building-user',
    label: 'Auditoria',
    to: '/seguranca/auditoria',
    visivel: true,
  },
  {
    id: 'SEG-SYSCONFIG',
    name: 'SYSCONFIG',
    modulo: 'SEG',
    icon: 'gear',
    label: 'Configuração',
    to: '/seguranca/sysConfig',
    visivel: true,
  },
  {
    id: 'SEG-EMAIL',
    name: 'EMAIL',
    modulo: 'SEG',
    icon: 'envelopes-bulk',
    label: 'Fila de E-mail',
    to: '/seguranca/emails',
    visivel: true,
  },
  {
    id: 'SEG-LOGSSYSTEM',
    name: 'LOGSSYSTEM',
    modulo: 'SEG',
    icon: 'tasks',
    label: 'Logs do Sistema',
    to: '/seguranca/logsSystem',
    visivel: true,
  },
]

const data = [
  ...menuAmb,
  ...menuAtv,
  ...menuCad,
  ...menuCon,
  ...menuCrp,
  ...menuDem,
  ...menuLab,
  ...menuPlt,
  ...menuQualidade,
  ...menuRI,
  ...menuSig,
  ...menuSigaco,
  ...menuSeg,
  ...menuNtf,
  ...menuGed,
  ...menuCtr,
]

export default data
