const URL_API = '/tiposDocumento'

export default ($axios) => ({
  find(empresa, search) {
    // let parms = ''
    // if (search !== undefined && search !== null) parms += 'search=' + search
    // if (parms !== '') parms = '?' + parms

    // return $axios.get(`${URL_API}/${empresa}${parms}`)
    return $axios.get(`${URL_API}/${empresa}`)
  },
  findByClasse(empresa, classe) {
    return $axios.get(`${URL_API}/${empresa}/${classe}`)
  },

  getById(empresa, id) {
    return $axios.get(`${URL_API}/${empresa}/${id}`)
  },

  save(empresa, tipo) {
    return $axios.post(`${URL_API}/${empresa}`, tipo)
  },

  delete(empresa, idTipo) {
    return $axios.delete(`${URL_API}/${empresa}/${idTipo}`)
  },
})
