import { authHeaderMultipart } from '~/helpers'

export default ($axios) => ({
  findInspecoesAmbientais(
    siglaEmpresa,
    payload,
    pageNumber,
    pageSize,
    sortField,
    sortDirection
  ) {
    if (!payload) payload = {}

    let pageNbr = pageNumber
    let pageSz = pageSize
    if (pageNbr == null || pageNbr === 0) pageNbr = 1
    if (pageSz == null || pageSz === 0) pageSz = 15

    let field = 'numero'
    let sortDesc = true
    if (sortField !== null && sortField !== undefined) field = sortField
    if (sortDirection !== null && sortDirection !== undefined)
      sortDesc = sortDirection

    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/inspecoes/${siglaEmpresa}/filtros?page=${pageNbr}&size=${pageSz}&sortField=${field}&sortDesc=${sortDesc}`,
      payload
    )
  },

  getInspecaoAmbientalById(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(`/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}`)
  },

  saveInspecaoAmbiental(siglaEmpresa, inspecaoAmbiental, ocorrencias, anexos) {
    const requestCfg = {
      method: 'POST',
      headers: authHeaderMultipart(),
    }

    const formData = new FormData()

    if (inspecaoAmbiental !== undefined) {
      formData.append(
        'inspecao',
        JSON.stringify(inspecaoAmbiental, (key, value) => {
          if (value !== null) return value
        })
      )
    }

    if (ocorrencias !== undefined)
      formData.append('ocorrencias', JSON.stringify(ocorrencias))
    // if (correcoes !== undefined)
    //   formData.append('correcoes', JSON.stringify(correcoes))

    if (anexos !== undefined) {
      const anexosRaw = []
      for (const anexo of anexos) {
        formData.append('filesAnexos', anexo.file)
        anexosRaw.push({
          comentario: anexo.comentario,
          anexo: {
            nome: anexo.file.name,
          },
        })
      }

      formData.append('anexos', JSON.stringify(anexosRaw))
    }

    return $axios.post(`/inspecoes/${siglaEmpresa}`, formData, requestCfg)
  },

  deleteInspecaoAmbiental(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.delete(`/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}`)
  },

  createAnexo(siglaEmpresa, idInspecaoAmbiental, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')

    return $axios.post(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/anexos`,
      formData
    )
  },

  findAnexos(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/anexos`
    )
  },

  deleteAnexo(siglaEmpresa, idInspecaoAmbiental, seqAnexo) {
    return $axios.delete(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/anexos/${seqAnexo}`
    )
  },

  editComentarioAnexo(siglaEmpresa, idInspecaoAmbiental, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/anexos/comentarios`,
      payload
    )
  },

  findOcorrenciasInspecaoAmbiental(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/ocorrencias`
    )
  },

  findHistoricosInspecaoAmbiental(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/historicos`
    )
  },

  findAprovacoesInspecaoAmbiental(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/aprovacoes`
    )
  },

  downloadExcel(empresa, filter) {
    $axios.setHeader('Content-Type', 'application/json')

    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.post(
      `/inspecoes/${empresa}/exportacaoExcel`,
      filter,
      requestCfg
    )
  },

  downloadPdf(empresa, idInspecao, tipoMapa = 'S') {
    return $axios.get(
      `/inspecoes/${empresa}/exportacaoPdf/${idInspecao}?tipoMapa=${tipoMapa}`,
      {
        responseType: 'blob',
      }
    )
  },

  executeEvento(empresa, idInspecaoAmbiental, tipoEvento, paramsPayload = {}) {
    $axios.setHeader('Content-Type', 'application/json')
    const { seqAprovacao, justificativa, perfilReprovar } = paramsPayload

    let parms = ''

    if (Object.keys(paramsPayload).length > 0) {
      if (seqAprovacao !== undefined) parms += '&seqAprovacao=' + seqAprovacao
      if (justificativa !== undefined) parms += '&descricao=' + justificativa
      if (perfilReprovar !== undefined)
        parms += '&perfilReprovar=' + perfilReprovar

      parms = '?' + parms.slice(1)
    }

    return $axios.post(
      `/inspecoes/${empresa}/${idInspecaoAmbiental}/eventos/${tipoEvento}${parms}`
    )
  },

  findLogs(empresa, datInicial, datFinal) {
    return $axios.get(
      `/inspecoes/${empresa}/logs?datInicial=${datInicial}&datFinal=${datFinal}`
    )
  },

  findHistoricos(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/historicos`
    )
  },

  saveHistorico(siglaEmpresa, idInspecaoAmbiental, historico) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/historicos?descricao=${historico}`
    )
  },

  downloadExcelInspecao(siglaEmpresa, idInspecao) {
    return $axios.get(
      `/inspecoes/${siglaEmpresa}/exportacaoExcel/${idInspecao}`,
      { responseType: 'blob' }
    )
  },

  findAssinatura(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/assinaturas`
    )
  },

  girarImagem(siglaEmpresa, idInspecaoAmbiental, idAnexo, angulo) {
    return $axios.post(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/anexos/girar/${idAnexo}/${angulo}`
    )
  },

  findRacs(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(`/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/racs`)
  },

  gerarRac(siglaEmpresa, idInspecaoAmbiental, idTipoRac, prazo) {
    let prazoAux = ''
    if(prazo !== undefined) {
      prazoAux = '&prazo=' + prazo
    }
    return $axios.post(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/racs?idTipoRac=${idTipoRac}${prazoAux}`
    )
  },

  findInspecoesFlorestais(siglaEmpresa, idInspecaoAmbiental) {
    return $axios.get(
      `/inspecoes/${siglaEmpresa}/${idInspecaoAmbiental}/inspecoesFlorestais`
    )
  },
})
