export default ($axios) => ({
  find(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/temas/`)
    } else {
      return $axios.get(`/cadastros/temas/?search=${search}`)
    }
  },

  getById(id) {
    return $axios.get(`/cadastros/temas/${id}`)
  },

  save(payload) {
    return $axios.post('/cadastros/temas/', payload)
  },

  delete(id) {
    return $axios.delete(`/cadastros/temas/${id}`)
  },
})
