export default ($axios) => ({
  findObras(empresa, search) {
    if (search === null || search === undefined || search === '') {
      return $axios.get(`/obras/${empresa}/all`)
    } else {
      return $axios.get(`/obras/${empresa}/all?search=${search}`)
    }
  },

  getObraById(idObra) {
    return $axios.get(`/obras/${idObra}`)
  },

  saveObra(obra) {
    return $axios.post(`/obras`, obra)
  },

  deleteObra(idObra) {
    return $axios.delete(`/obras/${idObra}`)
  },

  exportCSVOrXLS(tipoArquivo, empresa) {
    const requestCfg = {
      method: 'GET',
      type: 'arraybuffer',
    }

    return $axios.get(`/obras/${empresa}/export/${tipoArquivo}`, requestCfg)
  },

  findObraByLicenca(empresa, idObra) {
    return $axios.get(`cadastros/amb/licencasObras/${empresa}/${idObra}`)
  },

  findObraByLicencaById(empresa, idLicenca, seqObra) {
    return $axios.get(`cadastros/amb/${empresa}/licencasObras/${idLicenca}/${seqObra}`)
  },

  deleteObraByLicenca(empresa, idLicenca, seqLicenca) {
    return $axios.delete(`cadastros/amb/${empresa}/licencasObras/${idLicenca}/${seqLicenca}`)
  },

  saveObraByLicencas(empresa, payload) {
    return $axios.post(`cadastros/amb/${empresa}/licencasObras`, payload);
  }
  
})
