export default ($axios) => ({
  findObrigacoes(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/obrigacoes/${empresa}`)
    } else {
      return $axios.get(`/obrigacoes/${empresa}?search=${search}`)
    }
  },

  getLancamentoById(empresa, idObrigacao, seqLancamento) {
    return $axios.get(`/obrigacoes/${empresa}/${idObrigacao}/${seqLancamento}`)
  },
})
