export const state = () => ({
    rodovias: [],
  })
  
  export const mutations = {
    fill(state, rows) {
      state.rodovias = rows
    },
  }
  
  export const actions = {
    find({ commit }, empresa) {
      this.$rodoviaService
        .findRodovias(empresa)
        .then((response) => {
          const rodovias = []
          for (const item of response.data) {
            rodovias.push(item)
          }
  
          commit('fill', rodovias)
        })
        .catch((error) => {
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error',
          })
        })
    },
  }
  
  export const getters = {
    rodovias(state) {
      return state.rodovias
    },
  }
  