export default ($axios) => ({
  findEventos(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/atv/${siglaEmpresa}/eventos`)
    } else {
      return $axios.get(
        `/cadastros/atv/${siglaEmpresa}/eventos?search=${search}`
      )
    }
  },

  getEventoyId(siglaEmpresa, idEvento) {
    return $axios.get(
      `/cadastros/atv/${siglaEmpresa}/eventos/${idEvento}`
    )
  },

  saveEvento(siglaEmpresa, evento) {
    return $axios.post(
      `/cadastros/atv/${siglaEmpresa}/eventos`,
      evento
    )
  },

  deleteEvento(idEvento) {
    return $axios.delete(
      `/cadastros/atv/eventos/${idEvento}`
    )
  },
})
