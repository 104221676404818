export const state = () => ({
  idAutorizacaoIntervencao: null,
  statusAutorizacao: undefined,
})

export const mutations = {
  changeAutorizacaoIntervencao(state, autorizacaoIntervencao) {
    state.idAutorizacaoIntervencao = autorizacaoIntervencao.id.idIntervencao
    state.statusAutorizacao = autorizacaoIntervencao.status
  },
  changeStatusAutorizacao(state, status) {
    state.statusAutorizacao = status
  },
  clearAutorizacaoIntervencao(state) {
    state.idAutorizacaoIntervencao = null
    state.statusAutorizacao = null
  },
}

export const actions = {
  setAutorizacaoIntervencao({ commit }, autorizacaoIntervencao) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeAutorizacaoIntervencao', autorizacaoIntervencao)
        resolve()
      }, 50)
    })
  },
  setStatusAutorizacao({ commit }, status) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeStatusAutorizacao', status)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearAutorizacaoIntervencao')
  },
}

export const getters = {
  idAutorizacaoIntervencao(state) {
    return state.idAutorizacaoIntervencao
  },
  statusAutorizacao(state) {
    return state.statusAutorizacao
  },
}
