export default ($axios) => ({
  findPlanos(empresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) {
            if (filtro[key].length === 0) continue

            for (const col of filtro[key]) {
              if (parms !== '') parms += '&'
              parms += key + '=' + col
            }
          } else {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/cadastros/lab/${empresa}/planosAuditoria${parms}`)
  },

  getPlanoById(empresa, idPlano) {
    return $axios.get(`/cadastros/lab/${empresa}/planosAuditoria/${idPlano}`)
  },

  savePlano(empresa, plano) {
    return $axios.post(`/cadastros/lab/${empresa}/planosAuditoria`, plano)
  },

  deletePlano(empresa, idPlano) {
    return $axios.delete(`/cadastros/lab/${empresa}/planosAuditoria/${idPlano}`)
  },
})
