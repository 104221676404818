//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PDFViewer from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    PDFViewer,
  },
  data() {
    return {
      fileUrl: null,
      page: 1,
      pageCount: 1,
      modalWidth: '800px',
    }
  },
  created() {
    this.$root.$on('pdf-show', (file) => {
      if (file === null || file === undefined) return

      this.fileUrl = file
      this.page = 1
      this.pageCount = 1

      this.$bvModal.show('openModal')
    })

    this.$root.$on('pdf-close', () => {
      console.log('close')
    })
  },
  methods: {
    docRendered() {
      this.pageCount = this.$refs.pdfRef.pageCount
      const pdfElement = this.$refs.pdfRef.$el
      const pdfWidth = pdfElement.offsetWidth + 'px'
      this.modalWidth = pdfWidth
    },
    closePdf() {
      this.$bvModal.hide('openModal')
      this.findUrl = null
    },
  },
}
