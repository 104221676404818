export default ($axios) => ({
  findHistoricoIntervencao(siglaEmpresa, tipoAtivo) {
    return $axios.get(
      `/historicosIntervencoes/${siglaEmpresa}/?tipoAtivo=${tipoAtivo}`
    )
  },

  getHistoricoIntervencaoById(siglaEmpresa, idHistorico) {
    return $axios.get(`/historicosIntervencoes/${siglaEmpresa}/${idHistorico}`)
  },

  saveHistoricoIntervencao(siglaEmpresa, historicoIntervencao) {
    return $axios.post(
      `/historicosIntervencoes/${siglaEmpresa}`,
      historicoIntervencao
    )
  },

  updateHistoricoIntervencao(siglaEmpresa, historicoIntervencao) {
    return $axios.update(
      `/historicosIntervencoes/${siglaEmpresa}`,
      historicoIntervencao
    )
  },

  deleteHistoricoIntervencao(siglaEmpresa, idHistorico) {
    return $axios.delete(
      `/historicosIntervencoes/${siglaEmpresa}/${idHistorico}`
    )
  },

  findImagens(siglaEmpresa, idHistorico) {
    return $axios.get(
      `/historicosIntervencoes/${siglaEmpresa}/${idHistorico}/imagens`
    )
  },

  getImagemById(siglaEmpresa, idHistorico, sequencia) {
    return $axios.get(
      `/historicosIntervencoes/${siglaEmpresa}/${idHistorico}/${sequencia}/imagem`
    )
  },

  createAnexo(siglaEmpresa, idHistorico, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/historicosIntervencoes/${siglaEmpresa}/${idHistorico}/imagens`,
      formData
    )
  },
})
