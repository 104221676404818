export default ($axios) => ({
  findTiposObras(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/tiposObras`)
    } else {
      return $axios.get(`/tiposObras?search=${search}`)
    }
  },

  saveTipoObra(tipoObra) {
    return $axios.post(`/tiposObras`, tipoObra)
  },

  deleteTipoObra(idTipoObra) {
    return $axios.delete(`/tiposObras/${idTipoObra}`)
  },
})
