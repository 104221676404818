export const state = () => ({
  usuario: null,
  isExterno: null,
  modulos: null,
  permissoes: null,
  isTerceiro: null,
})

export const mutations = {
  clear(state) {
    state.usuario = null
    state.modulos = null
    state.permissoes = null
    state.isExterno = null
    state.isTerceiro = null
  },
  logout(state) {
    state.usuario = null
    state.modulos = null
    state.permissoes = null
    state.isExterno = null
    state.isTerceiro = null
  },
  setUsuario(state, usuario) {
    state.usuario = usuario
  },
  setIsExterno(state, isExterno) {
    state.isExterno = isExterno
  },
  setModulos(state, modulos) {
    state.modulos = modulos
  },
  setPermissoes(state, permissoes) {
    state.permissoes = permissoes
  },
  setIsTerceiro(state, terceiro) {
    state.isTerceiro = terceiro
  },
}

export const actions = {
  fill({ commit }, usuario) {
    commit('fillUsuario', usuario)
  },
  setUsuario({ commit }, usuario) {
    commit('setUsuario', usuario)
  },
  setIdExterno({ commit }, idExterno) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('setIsExterno', idExterno)
        resolve()
      }, 50)
    })
  },
  setModulos({ commit }, modulos) {
    commit('setModulos', modulos)
  },
  setPermissoes({ commit }, permissoes) {
    commit('setPermissoes', permissoes)
  },
  clear({ commit }) {
    commit('clear')
  },
  logout({ dispatch, commit }) {
    this.$usuarioService.logout()
    commit('logout')
    dispatch('empresa/clear', null, { root: true })
  },
  setIsTerceiro({ commit }, terceiro) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('setIsTerceiro', terceiro)
        resolve()
      }, 50)
    })
  },
}

export const getters = {
  usuario(state) {
    return state.usuario
  },
  isExterno(state) {
    return state.isExterno
  },
  modulos(state) {
    return state.modulos
  },
  permissoes(state) {
    return state.permissoes
  },
  isTerceiro(state) {
    return state.isTerceiro
  },
}
