export default ($axios) => ({
  find(search) {
    if (search === null || search === undefined || search === '') {
      return $axios.get(`/cadastros/poderesConcedentes`)
    } else {
      return $axios.get(`/cadastros/poderesConcedentes?search=${search}`)
    }
  },

  getById(id) {
    return $axios.get(`/cadastros/poderesConcedentes/${id}`)
  },

  save(payload) {
    return $axios.post('/cadastros/poderesConcedentes', payload)
  },

  delete(id) {
    return $axios.delete(`/cadastros/poderesConcedentes/${id}`)
  },
})
