export default ($axios) => ({
  findAreasEspecialmenteProtegidas(search, categoria) {
    const params = {};
    if (search) {
      params.search = search;
    }
    if (categoria) {
      params.categoria = categoria;
    }
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/areasEspecialmenteProtegidas`, { params })
    } else {
      return $axios.get(`/cadastros/amb/areasEspecialmenteProtegidas?search=${search}${categoria}`)
    }
  },

  getAreaEspecialmenteProtegidaById(idArea) {
    return $axios.get(`/cadastros/amb/areasEspecialmenteProtegidas/${idArea}`)
  },

  saveAreaEspecialmenteProtegida(payload) {
    return $axios.post(`/cadastros/amb/areasEspecialmenteProtegidas`, payload)
  },

  updateAreaEspecialmenteProtegida(idArea, payload) {
    return $axios.post(`/cadastros/areasEspecialmenteProtegidas/${idArea}`, payload)
  },

  deleteAreaEspecialmenteProtegida(idArea) {
    return $axios.delete(`/cadastros/amb/areasEspecialmenteProtegidas/${idArea}`)
  },
})
