export default ($axios) => ({
  findPeneiras(siglaEmpresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null && filtro[key] !== '') {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/cadastros/lab/${siglaEmpresa}/peneiras?${parms}`)
  },
  getByIdPeneira(siglaEmpresa, idPeneira) {
    return $axios.get(`/cadastros/lab/${siglaEmpresa}/peneiras/${idPeneira}`)
  },
  savePeneira(siglaEmpresa, peneira) {
    return $axios.post(
      `/cadastros/lab/${siglaEmpresa}/peneiras`,
      peneira
    )
  },
  deletePeneira(siglaEmpresa, idPeneira) {
    return $axios.delete(
      `/cadastros/lab/${siglaEmpresa}/peneiras/${idPeneira}`
    )
  },
})