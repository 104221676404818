import Vue from 'vue'
import x5GMaps from 'x5-gmaps'

Vue.use(x5GMaps, {
  key: 'AIzaSyBbRlQ1AQDf793xXjKtwR3FtkK7hkyKcmE',
  language: 'pt-BR',
  libraries: ['visualization'],
  region: 'BR',
})

/* import * as VueGoogleMaps from 'vue2-google-maps';
export default {
    install(Vue) {
        Vue.use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyBbRlQ1AQDf793xXjKtwR3FtkK7hkyKcmE',
                language: 'pt-BR',
                libraries: 'visualization',
                region: 'BR',
                // v: '3.41',
            },

            installComponents: true
        });
    }
} */
