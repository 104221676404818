export default ($axios) => ({

    findLinhas(search) {
      if (search === null || search === undefined) {
        return $axios.get(`/cadastros/linhasNegocios`)
      } else {
        return $axios.get(`/cadastros/linhasNegocios?search=${search}`)
      }
    },

    getLinhaById(idLinha) {
      return $axios.get(`/cadastros/linhasNegocios/${idLinha}`)
    },

    saveLinha(payload) {
      return $axios.post(`/cadastros/linhasNegocios`, payload)
    },

    updateLinha(idLinha, payload) {
      return $axios.post(`/cadastros/linhasNegocios/${idLinha}`, payload)
    },

    deleteLinha(idLinha) {
      return $axios.delete(`/cadastros/linhasNegocios/${idLinha}`)
    },
  })
