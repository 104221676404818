import { render, staticRenderFns } from "./default.vue?vue&type=template&id=03837391&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavsTopNav: require('/var/lib/jenkins/workspace/frontend-master/components/navs/TopNav.vue').default,NavsSideBar: require('/var/lib/jenkins/workspace/frontend-master/components/navs/SideBar.vue').default,Footer: require('/var/lib/jenkins/workspace/frontend-master/components/footer/Footer.vue').default})
