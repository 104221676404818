export const state = () => ({
  ocorrenciasDisponiveis: [],
  ocorrenciasAssociadas: [],
  solucoesEscolhidas: []
})

export const mutations = {
  fillOcorrenciasDisponiveis(state, ocorrenciasDisponiveis) {
    state.ocorrenciasDisponiveis = ocorrenciasDisponiveis
  },

  changeOcorrenciasDisponiveis(state, ocorrenciasDisponiveis) {
    state.ocorrenciasDisponiveis = ocorrenciasDisponiveis
  },

  clearOcorrenciasDisponiveis(state) {
    state.ocorrenciasDisponiveis = []
  },
  
  fillOcorrenciasAssociadas(state, ocorrenciasAssociadas) {
    state.ocorrenciasAssociadas = ocorrenciasAssociadas
  },

  changeOcorrenciasAssociadas(state, ocorrenciasAssociadas) {
    state.ocorrenciasAssociadas = ocorrenciasAssociadas
  },

  clearOcorrenciasAssociadas(state) {
    state.ocorrenciasAssociadas = []
  },

  fillSolucoesEscolhidas(state, solucoesEscolhidas) {
    state.solucoesEscolhidas = solucoesEscolhidas
  },

  changeSolucoesEscolhidas(state, solucoesEscolhidas) {
    state.solucoesEscolhidas = solucoesEscolhidas
  },

  clearSolucoesEscolhidas(state) {
    state.solucoesEscolhidas = []
  },
}

export const actions = {

  findOcorrenciasDisponiveis({ commit }) {
    this.$ocorrenciaService.findOcorrencias().then((response) => {
      const ocorr = []
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index]
        if (element.solucoes.length !== 0) {
          ocorr.push(element)
        }
      }

      window.console.log(ocorr)
      commit('fillOcorrenciasDisponiveis', ocorr)
    })
  },

  setOcorrenciasDisponiveis({ commit }, ocorrenciasDisponiveis) {
    commit('changeOcorrenciasDisponiveis', ocorrenciasDisponiveis)
  },

  clearOcorrenciasDisponiveis({ commit }) {
    commit('clearOcorrenciasDisponiveis')
  },

  setOcorrenciasAssociadas({ commit }, ocorrenciasAssociadas) {
    commit('changeOcorrenciasAssociadas', ocorrenciasAssociadas)
  },

  clearOcorrenciasAssociadas({ commit }) {
    commit('clearOcorrenciasAssociadas')
  },

  setSolucoesEscolhidas({ commit }, solucoes) {
    commit('changeSolucoesEscolhidas', solucoes)
  },

  clearSolucoesEscolhidas({ commit }) {
    commit('clearSolucoesEscolhidas')
  },

}

export const getters = {
  getOcorrenciasDisponiveis(state) {
    return state.ocorrenciasDisponiveis
  },
  getOcorrenciasAssociadas(state) {
    return state.ocorrenciasAssociadas
  },
  getSolucoesEscolhidas(state) {
    return state.solucoesEscolhidas
  },
}
