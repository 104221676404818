export default ($axios) => ({
  findPontoControle(
    siglaEmpresa,
    payload,
    pageNumber,
    pageSize,
    sortField,
    sortDirection
  ) {
    if (!payload) payload = {}

    let pageNbr = pageNumber
    let pageSz = pageSize
    if (pageNbr == null || pageNbr === 0) pageNbr = 1
    if (pageSz == null || pageSz === 0) pageSz = 15

    let field = 'descricao'
    let sortDesc = true
    if (sortField !== null && sortField !== undefined) field = sortField
    if (sortDirection !== null && sortDirection !== undefined)
      sortDesc = sortDirection
   // let parms = ''
    // if (filtro) {
    //  const keys = Object.keys(filtro)
    //  for (const key of keys) {
    //    if (filtro[key] !== undefined && filtro[key] !== null) {
    //      if (Array.isArray(filtro[key])) {
    //        if (filtro[key].length === 0) continue

    //        for (const col of filtro[key]) {
    //          if (parms !== '') parms += '&'
    //          parms += key + '=' + col
    //        }
    //      } else {
    //        if (parms !== '') parms += '&'
    //        parms += key + '=' + filtro[key]
    //      }
    //    }
    // if (parms !== '') parms = '?' + parms
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(`/pontosControle/${siglaEmpresa}/filtros?page=${pageNbr}&size=${pageSz}&sortField=${field}&sortDesc=${sortDesc}`,
    payload
  )
},

  findAll(siglaEmpresa) {
    return $axios.get(`/pontosControle/${siglaEmpresa}/all`)
  },

  getPontoControleById(idPontoControle) {
    return $axios.get(`/pontosControle/${idPontoControle}`)
  },

  savePontoControle(siglaEmpresa, pontoControle) {
    return $axios.post(`/pontosControle/${siglaEmpresa}`, pontoControle)
  },

  deletePontoControle(idPontoControle) {
    return $axios.delete(`/pontosControle/${idPontoControle}`)
  },

  findDesativacaoPontoControle(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/pontosControle/desativados/${siglaEmpresa}/find`)
    } else {
      return $axios.get(
        `/pontosControle/desativados/${siglaEmpresa}/find?search=${search}`
      )
    }
  },

  getDesativacaoPontoControleById(idPontoControle) {
    return $axios.get(`/pontosControle/desativados/${idPontoControle}`)
  },

  saveDesativacaoPontoControle(PontoControle, siglaEmpresa) {
    return $axios.post(
      `/pontosControle/desativados/${siglaEmpresa}`,
      PontoControle
    )
  },

  deleteDesativacaoPontoControle(idPontoControle) {
    return $axios.delete(`/pontosControle/desativados/${idPontoControle}`)
  },

  findFichasByPontoControle(siglaEmpresa, idPontoControle) {
    return $axios.get(
      `/pontosControle/${siglaEmpresa}/${idPontoControle}/fichas`
    )
  },

  ativarPonto(empresa, idPonto, obj) {
    return $axios.post(`/pontosControle/${empresa}/${idPonto}/ativacoes`, obj)
  },

  relatorioChecklists(empresa, filter) {
    return $axios.post(`/pontosControle/${empresa}/exportacaoExcel`, filter, {
      responseType: 'blob',
    })
  },

  saveDesativacao(desativacaoPonto, siglaEmpresa, idPontoControle) {
    return $axios.post(
      `/pontosControle/${siglaEmpresa}/${idPontoControle}/desativacoes`,
      desativacaoPonto
    )
  },

  downloadPdf(siglaEmpresa, idPontoControle, tipoMapa = 'S') {
    return $axios.get(
      `/pontosControle/${siglaEmpresa}/exportacaoPdf/${idPontoControle}?tipoMapa=${tipoMapa}`,
      {
        responseType: 'blob',
      }
    )
  },

  findHistoricos(idPontoControle) {
    return $axios.get(`/pontosControle/${idPontoControle}/historicos`)
  },

  saveHistorico(siglaEmpresa, idPontoControle, historico) {
    const formData = new FormData()

    if (historico !== undefined)
      formData.append('descricao', historico.descricao + '')

    return $axios.post(
      `/pontosControle/${siglaEmpresa}/${idPontoControle}/historicos`,
      formData
    )
  },
})
