export default ($axios) => ({
  findImpactos(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/${empresa}/impactosAmbientais`)
    } else {
      return $axios.get(
        `/cadastros/amb/${empresa}/impactosAmbientais?search=${search}`
      )
    }
  },

  getImpactoById(empresa, idImpacto) {
    return $axios.get(
      `/cadastros/amb/${empresa}/impactosAmbientais/${idImpacto}`
    )
  },

  saveImpacto(empresa, impacto) {
    return $axios.post(`/cadastros/amb/${empresa}/impactosAmbientais`, impacto)
  },

  deleteImpacto(empresa, idImpacto) {
    return $axios.delete(
      `/cadastros/amb/${empresa}/impactosAmbientais/${idImpacto}`
    )
  },
})
