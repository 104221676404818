export const state = () => ({
  idCheckList: null,
  statusRac: null,
  supervisorAmbiental: null,
  coordenadorObra: null,
})

export const mutations = {
  changeCheckList(state, checklist) {
    state.idCheckList = checklist.id.idCheckList
    state.statusRac = checklist.status
    state.supervisorAmbiental = checklist.idSupervisorAmbiental
    state.coordenadorObra = checklist.idCoordenadorObra
  },
  clearCheckList(state) {
    state.idCheckList = null
    state.statusRac = null
    state.supervisorAmbiental = null
    state.coordenadorObra = null
  },
  changeIdCheckList(state, idCheckList) {
    state.idCheckList = idCheckList
  },
}

export const actions = {
  setCheckList({ commit }, checklist) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeCheckList', checklist)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearCheckList')
  },
  setIdCheckList({ commit }, idCheckList) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeIdCheckList', idCheckList)
        resolve()
      }, 50)
    })
  },
}

export const getters = {
  idCheckList(state) {
    return state.idCheckList
  },
  statusChecklist(state) {
    return state.statusRac
  },
  supervisorAmbiental(state) {
    return state.supervisorAmbiental
  },
  coordenadorObra(state) {
    return state.coordenadorObra
  },
}
