export default ($axios) => ({
  // Fator de Pagamento
  findFatoresPagamento(empresa) {
    return $axios.get(`/controles/lab/${empresa}/fatoresPagamento`)
  },

  getFatorPagamentoById(empresa, idFator) {
    return $axios.get(`/controles/lab/${empresa}/fatoresPagamento/${idFator}`)
  },

  getFatorPagamentoItensById(empresa, idFator) {
    return $axios.get(
      `/controles/lab/${empresa}/fatoresPagamento/${idFator}/itens`
    )
  },

  saveFatorPagamento(empresa, fatorPagamento) {
    return $axios.post(
      `/controles/lab/${empresa}/fatoresPagamento`,
      fatorPagamento
    )
  },

  deleteFatorPagamento(empresa, idFator) {
    return $axios.delete(
      `/controles/lab/${empresa}/fatoresPagamento/${idFator}`
    )
  },
  saveItensFatorPagamento(empresa, idFator, itens) {
    return $axios.post(
      `/controles/lab/${empresa}/fatoresPagamento/${idFator}/itens`,
      itens
    )
  },

  deleteItensFatorPagamento(empresa, idFatorPagamento, nroLancamento) {
    return $axios.delete(
      `/controles/lab/${empresa}/fatoresPagamento/${idFatorPagamento}/itens/${nroLancamento}`
    )
  },

  exportFatorPagamentoPdf(empresa, idFator) {
    return $axios.post(
      `/controles/lab/${empresa}/fatoresPagamento/${idFator}/exportPdf`,
      {},
      { responseType: 'blob' }
    )
  },

  exportFatorPagamentoXls(empresa, idFator) {
    return $axios.post(
      `/controles/lab/${empresa}/fatoresPagamento/${idFator}/exportExcel`,
      {},
      { responseType: 'blob' }
    )
  },

  findPlanoAuditoria(empresa, filtro) {
    let parms = ''

    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        console.log('filtro.key ==>', key, ', filtro.val ==> ', filtro[key])

        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`/controles/lab/${empresa}/planosAuditoria${parms}`)
  },
  getPlanoAuditoriaById(empresa, idPlano) {
    return $axios.get(`/controles/lab/${empresa}/planosAuditoria/${idPlano}`)
  },
  savePlanoAuditoria(empresa, plano) {
    return $axios.post(`/controles/lab/${empresa}/planosAuditoria`, plano)
  },
  deletePlanoAuditoria(empresa, idPlano) {
    return $axios.delete(`/controles/lab/${empresa}/planosAuditoria/${idPlano}`)
  },

  findControleEstatistico(empresa) {
    return $axios.get(`/controles/lab/${empresa}/controlesEstatisticos`)
  },
  getControleEstatisticoById(empresa, idControle) {
    return $axios.get(
      `/controles/lab/${empresa}/controlesEstatisticos/${idControle}`
    )
  },
  saveControleEstatistico(empresa, controle) {
    return $axios.post(
      `/controles/lab/${empresa}/controlesEstatisticos`,
      controle
    )
  },
  deleteControleEstatistico(empresa, idControle) {
    return $axios.delete(
      `/controles/lab/${empresa}/controlesEstatisticos/${idControle}`
    )
  },

  dowloadRelatorioEstatistico(empresa, idControle) {
    return $axios.post(
      `/controles/lab/${empresa}/controlesEstatistico/${idControle}/exportExcel
 `, {},
      {
        responseType: 'arraybuffer',
      }
    )
  },

  findKilometros(empresa, idControle) {
    return $axios.get(
      `/controles/lab/${empresa}/controlesEstatisticos/${idControle}/kilometros`
    )
  },

  uploadRelatorio(empresa, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')

    return $axios.post(`/ged/${empresa}/LAB/Fator`, formData)
  },
})
