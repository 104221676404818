export const state = () => ({
  cidades: [],
})

export const mutations = {
  fill(state, rows) {
    state.cidades = rows
  },
}

export const actions = {
  find({ commit }, empresa) {
    this.$cidadeService
      .findCidades(empresa)
      .then((response) => {
        const cidades = response.data

        commit('fill', cidades)
      })
      .catch((error) => {
        this.$notify({
          title: 'Erro',
          text: error.response ? error.response.data.message : error,
          type: 'error',
        })
      })
  },
}

export const getters = {
  cidades(state) {
    return state.cidades
  },
}
