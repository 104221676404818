var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-menu"},[_c('vue-perfect-scrollbar',{staticClass:"scroll",attrs:{"settings":{ suppressScrollX: true, wheelPropagation: false }}},[(_vm.isExterno === false)?_c('div',_vm._l((_vm.getItensMenu()),function(item,itemIndex){return _c('ul',{key:("sub_" + (item.id)),class:{
          'list-unstyled': true,
          'd-block':
            (_vm.selectedParentMenu === item.id && _vm.viewingParentMenu === '') ||
            _vm.viewingParentMenu === item.id,
        }},[(item.modulo === _vm.moduloAtual)?_c('div',_vm._l((item.subs),function(sub,subIndex){return _c('li',{key:subIndex,class:{
              'has-sub-item': sub.subs && sub.subs.length > 0,
              active: _vm.$route.fullPath.indexOf(sub.to) > -1,
            }},[(sub.newWindow)?_c('a',{attrs:{"href":sub.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('span',[_c('fa-icon',{staticClass:"icon-submenu",attrs:{"icon":sub.icon}})],1),_vm._v(" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(sub.label))])]):(sub.subs && sub.subs.length > 0)?[_c('b-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("menu_" + itemIndex + "_" + subIndex)),expression:"`menu_${itemIndex}_${subIndex}`"}],staticClass:"rotate-arrow-icon",attrs:{"variant":"link"}},[_c('fa-icon',{staticClass:"chevron-down",attrs:{"icon":_vm.iconDown}}),_vm._v(" "),_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(sub.label))])],1),_vm._v(" "),_c('b-collapse',{attrs:{"id":("menu_" + itemIndex + "_" + subIndex)}},[_c('ul',{staticClass:"list-unstyled third-level-menu"},_vm._l((sub.subs),function(thirdLevelSub,thirdIndex){return _c('li',{key:("third_" + itemIndex + "_" + subIndex + "_" + thirdIndex),class:{
                      'third-level-menu': true,
                      active: _vm.$route.path === thirdLevelSub.to,
                    }},[(thirdLevelSub.newWindow)?_c('a',{attrs:{"href":thirdLevelSub.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('fa-icon',{class:thirdLevelSub.icon,attrs:{"icon":thirdLevelSub.icon}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(thirdLevelSub.label))])],1):_c('nuxt-link',{attrs:{"to":thirdLevelSub.to}},[_c('fa-icon',{class:thirdLevelSub.icon,attrs:{"icon":thirdLevelSub.icon}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(thirdLevelSub.label))])],1)],1)}),0)])]:_c('nuxt-link',{attrs:{"to":sub.to}},[(sub.visivel === true)?_c('div',[_c('span',[_c('fa-icon',{staticClass:"icon-submenu",attrs:{"icon":sub.icon}})],1),_vm._v(" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(sub.label))])]):_vm._e()])],2)}),0):_vm._e()])}),0):_vm._e(),_vm._v(" "),(_vm.isExterno === true)?_c('div',_vm._l((_vm.getMenuExterno()),function(item,itemIndex){return _c('ul',{key:("sub_" + (item.id)),class:{
          'list-unstyled': true,
          'd-block':
            (_vm.selectedParentMenu === item.id && _vm.viewingParentMenu === '') ||
            _vm.viewingParentMenu === item.id,
        }},_vm._l((item.subs),function(sub,subIndex){return _c('li',{key:subIndex,class:{
            'has-sub-item': sub.subs && sub.subs.length > 0,
            active: _vm.$route.fullPath.indexOf(sub.to) > -1,
          }},[(sub.newWindow)?_c('a',{attrs:{"href":sub.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('span',[_c('fa-icon',{staticClass:"icon-submenu",attrs:{"icon":sub.icon}})],1),_vm._v(" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(sub.label))])]):(sub.subs && sub.subs.length > 0)?[_c('b-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("menu_" + itemIndex + "_" + subIndex)),expression:"`menu_${itemIndex}_${subIndex}`"}],staticClass:"rotate-arrow-icon",attrs:{"variant":"link"}},[_c('fa-icon',{staticClass:"chevron-down",attrs:{"icon":_vm.iconDown}}),_vm._v(" "),_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(sub.label))])],1),_vm._v(" "),_c('b-collapse',{attrs:{"id":("menu_" + itemIndex + "_" + subIndex)}},[_c('ul',{staticClass:"list-unstyled third-level-menu"},_vm._l((sub.subs),function(thirdLevelSub,thirdIndex){return _c('li',{key:("third_" + itemIndex + "_" + subIndex + "_" + thirdIndex),class:{
                    'third-level-menu': true,
                    active: _vm.$route.path === thirdLevelSub.to,
                  }},[(thirdLevelSub.newWindow)?_c('a',{attrs:{"href":thirdLevelSub.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('fa-icon',{class:thirdLevelSub.icon,attrs:{"icon":thirdLevelSub.icon}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(thirdLevelSub.label))])],1):_c('nuxt-link',{attrs:{"to":thirdLevelSub.to}},[_c('fa-icon',{class:thirdLevelSub.icon,attrs:{"icon":thirdLevelSub.icon}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(thirdLevelSub.label))])],1)],1)}),0)])]:_c('nuxt-link',{attrs:{"to":sub.to}},[(sub.visivel === true)?_c('div',[_c('span',[_c('fa-icon',{staticClass:"icon-submenu",attrs:{"icon":sub.icon}})],1),_vm._v(" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(sub.label))])]):_vm._e()])],2)}),0)}),0):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }