export default ($axios) => ({

    //  Grupos da Notificação
    findGruposNotificacao(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/notificacoes/cadastros/${empresa}/grupos`)
    } else {
      return $axios.get(`/notificacoes/cadastros/${empresa}/grupos?search=${search}`)
    }
  },
 
  getGruposNotificacaoById(empresa) {
    return $axios.get(`/notificacoes/cadastros/${empresa}/grupos`)
  },
 
  saveGrupos(empresa, grupo) {
    return $axios.post(`/notificacoes/cadastros/${empresa}/grupos`, grupo)
  },
 
  deleteGrupos(empresa, idGrupo) {
    return $axios.delete(`/notificacoes/cadastros/${empresa}/grupo/${idGrupo}`)
  },

   //  Níveis da Notificação
   findNiveisNotificacao(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/notificacoes/cadastros/${empresa}/niveis`)
    } else {
      return $axios.get(`/notificacoes/cadastros/${empresa}/niveis?search=${search}`)
    }
  },

  getNiveisById(empresa) {
    return $axios.get(`/notificacoes/cadastros/${empresa}/niveis`)
  },

  saveNiveis(empresa, niveis) {
    return $axios.post(`/notificacoes/cadastros/${empresa}/niveis`, niveis)
  },

  deleteNiveis(empresa,idNivel) {
    return $axios.delete(`/notificacoes/cadastros/${empresa}/nivel/${idNivel}`)
  },

  //  Status da Notificação
  findStatus(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/notificacoes/cadastros/${empresa}/status`)
    } else {
      return $axios.get(`/notificacoes/cadastros/${empresa}/status?search=${search}`)
    }
  },

  getStatusById(empresa) {
    return $axios.get(`/notificacoes/cadastros/${empresa}/status`)
  },

  saveStatus(empresa, status) {
    return $axios.post(`/notificacoes/cadastros/${empresa}/status`, status)
  },

  deleteStatus(empresa,idStatus) {
    return $axios.delete(`/notificacoes/cadastros/${empresa}/status/${idStatus}`)
  },

  //   Classes de Notificações
  findClassesNotificacao(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/notificacoes/cadastros/${empresa}/classes`)
    } else {
      return $axios.get(`/notificacoes/cadastros/${empresa}/classes?search=${search}`)
    }
  },

  getClassesById(empresa) {
    return $axios.get(`/notificacoes/cadastros/${empresa}/classes`)
  },

  saveClasses(empresa, classes) {
    return $axios.post(`/notificacoes/cadastros/${empresa}/classes`, classes)
  },

  deleteClasses(empresa,idClasse) {
    return $axios.delete(`/notificacoes/cadastros/${empresa}/classe/${idClasse}`)
  },

  //    Sub-Classes de Notificações
  findSubClasses(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/notificacoes/cadastros/${empresa}/subClasses`)
    } else {
      return $axios.get(`/notificacoes/cadastros/${empresa}/subClasses?search=${search}`)
    }
  },

  getSubClassesById(empresa) {
    return $axios.get(`/notificacoes/cadastros/${empresa}/subClasses`)
  },

  saveSubClasses(empresa, subClasses) {
    return $axios.post(`/notificacoes/cadastros/${empresa}/subClasses`, subClasses)
  },

  deleteSubClasses(empresa,idSubClasse) {
    return $axios.delete(`/notificacoes/cadastros/${empresa}/subClasse/${idSubClasse}`)
  },

  //    Sub-Classes de Notificações
  findStatusProcesso(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/notificacoes/cadastros/${empresa}/statusDoProcesso`)
    } else {
      return $axios.get(`/notificacoes/cadastros/${empresa}/statusDoProcesso?search=${search}`)
    }
  },

  getStatusProcessoById(empresa) {
    return $axios.get(`/notificacoes/cadastros/${empresa}/statusDoProcesso`)
  },

  saveStatusProcesso(empresa, statusDoProcesso) {
    return $axios.post(`/notificacoes/cadastros/${empresa}/statusDoProcesso`, statusDoProcesso)
  },

  deleteStatusProcesso(empresa,idStatusDoProcesso) {
    return $axios.delete(`/notificacoes/cadastros/${empresa}/statusDoProcesso/${idStatusDoProcesso}`)
  },

  // Notificações
  findNotificacoes(empresa, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        console.log('key==> ', key, ':: ', filtro[key])
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (parms !== '') parms += '&'
          parms += key + '=' + filtro[key]
        }
      }

      if (parms !== '') parms = '?' + parms
    }
    return $axios.get(`/notificacoes/${empresa}${parms}`)
  },

  // Relatorio TROs Recebidas
  findTrosRecebidos(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/notificacoes/${empresa}/TROS/recebidos`)
    } else {
      return $axios.get(`/notificacoes/${empresa}/TROS/recebidos?search=${search}`)
    }
  },
  getTrosRecebidosById(empresa) {
    return $axios.get(`/notificacoes/${empresa}/TROS/recebidos`)
  },


// Relatorio Totalizador
findRelatorioTotalizador(empresa, search) {
  if (search === null || search === undefined) {
    return $axios.get(`/notificacoes/${empresa}/TROS/totalizadorKm`)
  } else {
    return $axios.get(`/notificacoes/${empresa}/TROS/totalizadorKm?search=${search}`)
  }
},
getRelatorioTotalizadorById(empresa) {
  return $axios.get(`/notificacoes/${empresa}/TROS/totalizadorKm`)
},

})