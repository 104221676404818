export default ($axios) => ({
  findFatores(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/incidentes/fatores`)
    } else {
      return $axios.get(`/incidentes/fatores?search=${search}`)
    }
  },

  getFatorById(idFator) {
    return $axios.get(`/incidentes/fatores/${idFator}`)
  },

  deleteFator(idFator) {
    return $axios.delete(`/incidentes/fatores/${idFator}`)
  },

  saveFator(fator) {
    return $axios.post(`/incidentes/fatores/`, fator)
  },

  findEventos(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/incidentes/eventos`)
    } else {
      return $axios.get(`/incidentes/eventos?search=${search}`)
    }
  },

  getEventoById(idEvento) {
    return $axios.get(`/incidentes/eventos/${idEvento}`)
  },

  deleteEvento(idEvento) {
    return $axios.delete(`/incidentes/eventos/${idEvento}`)
  },

  saveEvento(evento) {
    return $axios.post(`/incidentes/eventos/`, evento)
  },
})
