export default ($axios) => ({
  findItensPeneiras(siglaEmpresa, idClassificacao) {
    return $axios.get(
      `/cadastros/lab/${siglaEmpresa}/peneiras/${idClassificacao}/itens`
    )
  },

  getByIdPeneiraItem(siglaEmpresa, idPeneiraItem) {
    return $axios.get(
      `/cadastros/lab/${siglaEmpresa}/peneiras/${idPeneiraItem}`
    )
  },

  saveItemPeneira(siglaEmpresa, idClassificacao, item) {
    return $axios.post(
      `/cadastros/lab/${siglaEmpresa}/peneiras/${idClassificacao}/itens`,
      item
    )
  },

  deleteItemPeneira(siglaEmpresa, idClassificacao, sequencia) {
    return $axios.delete(
      `/cadastros/lab/${siglaEmpresa}/peneiras/${idClassificacao}/itens/${sequencia}`
    )
  },
})
