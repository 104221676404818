export default ($axios) => ({
  findFluxoAprovacao(siglaEmpresa) {
    return $axios.get(`/fluxosAprovacoes/${siglaEmpresa}`)
  },

  getFluxoAprovacaoById(siglaEmprea, idFluxoAprovacao) {
    return $axios.get(
      `/fluxosAprovacoes/${siglaEmprea}/${idFluxoAprovacao}`
    )
  },

  saveFluxoAprovacao(fluxoAprovacao) {
    return $axios.post(`/fluxosAprovacoes`, fluxoAprovacao)
  },

  deleteFluxoAprovacao(siglaEmprea, idFluxoAprovacao) {
    return $axios.delete(
      `/fluxosAprovacoes/${siglaEmprea}/${idFluxoAprovacao}`
    )
  },
})
