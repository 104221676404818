//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import PDFViewer from '@/components/PdfViewer.vue'

export default {
  components: {
    PDFViewer,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({ getMenuType: 'menu/getMenuType' }),
  },
  methods: {
    // visualizaLog() {
    //   const token = this.$store.state.auth.token
    //   console.log(token)
    //   let usuario = false
    //   if (token != null || token !== undefined) {
    //     usuario = true
    //   }
    //   return usuario
    // },
  },
}
