export default ($axios) => ({
  findSolucoes(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/solucoes`)
    } else {
      return $axios.get(`/solucoes?search=${search}`)
    }
  },

  getSolucaoById(idSolucao) {
    return $axios.get(`/solucoes/${idSolucao}`)
  },

  saveSolucao(solucao) {
    return $axios.post(`/solucoes`, solucao)
  },

  deleteSolucao(idSolucao) {
    return $axios.delete(`/solucoes/${idSolucao}`)
  },
})
