export default ($axios) => ({

    findMateriais(search) {
      if (search === null || search === undefined) {
        return $axios.get(`/cadastros/amb/materiaisApoio`)
      } else {
        return $axios.get(`/cadastros/amb/materiaisApoio?search=${search}`)
      }
    },
    
    getMaterialById(idMaterialApoio) {
      return $axios.get(`/cadastros/amb/materiaisApoio/${idMaterialApoio}`)
    },

    saveMaterial(payload) {
      return $axios.post(`/cadastros/amb/materiaisApoio/`, payload)
    },
    
    updateMaterial(idMaterialApoio, payload) {
      return $axios.post(`/cadastros/amb/materiaisApoio/${idMaterialApoio}`, payload)
    },
  
    deleteMaterial(idMaterialApoio) {
      return $axios.delete(`/cadastros/amb/materiaisApoio/${idMaterialApoio}`)
    },
  })