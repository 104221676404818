export const state = () => ({
  empresas: [],
  empresaAtual: undefined,
})

export const mutations = {
  clear(state) {
    state.empresas = null
    state.empresaAtual = null
  },
  fillEmpresas(state, empresas) {
    state.empresas = empresas
    state.empresaAtual = empresas[0]
  },
  getEmpresa(state, empresa) {
    const idx = state.empresas.findIndex((item) => item.sigla === empresa.sigla)

    if (idx !== -1) {
      state.empresa = state.empresas[idx]
    } else {
      state.empresa = null
    }
  },
  setEmpresa(state, empresa) {
    state.empresaAtual = empresa
  },
}

export const actions = {
  fill({ commit }, empresas) {
    commit('fillEmpresas', empresas)
  },
  getOne({ commit }, empresa) {
    commit('getEmpresa', empresa)
  },
  select({ commit }, empresa) {
    commit('setEmpresa', empresa)
  },
  clear({ commit }) {
    commit('clear')
  },
}

export const getters = {
  empresaAtual(state) {
    return state.empresaAtual
  },
  empresas(state) {
    return state.empresas
  },
}
