export default ($axios) => ({
  findAgencia(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/tiposStatus?tipo=O`)
    } else {
      return $axios.get(`/cadastros/amb/tiposStatus?tipo=O&search=${search}`)
    }
  },

  getAgenciaById(idSituacaoAgencia) {
    return $axios.get(`/cadastros/amb/tiposStatus/${idSituacaoAgencia}`)
  },

  saveAgencia(payload) {
    return $axios.post(`/cadastros/amb/tiposStatus`, payload)
  },

  updateAgencia(idSituacaoAgencia, payload) {
    return $axios.post(
      `/cadastros/amb/tiposStatus/${idSituacaoAgencia}`,
      payload
    )
  },

  deleteAgencia(idSituacaoAgencia) {
    return $axios.delete(`/cadastros/amb/tiposStatus/${idSituacaoAgencia}`)
  },
})
