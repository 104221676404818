export default ($axios) => ({
  findCoordenadas(empresa, modulo, item, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) {
            if (filtro[key].length === 0) continue

            for (const col of filtro[key]) {
              if (parms !== '') parms += '&'
              parms += key + '=' + col
            }
          } else {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`mapas/${empresa}/${modulo}/${item}${parms}`)
  },

  exportKMZ(empresa, modulo, item, filtro) {
    let parms = ''
    if (filtro) {
      const keys = Object.keys(filtro)
      for (const key of keys) {
        if (filtro[key] !== undefined && filtro[key] !== null) {
          if (Array.isArray(filtro[key])) {
            if (filtro[key].length === 0) continue

            for (const col of filtro[key]) {
              if (parms !== '') parms += '&'
              parms += key + '=' + col
            }
          } else {
            if (parms !== '') parms += '&'
            parms += key + '=' + filtro[key]
          }
        }
      }
    }

    if (parms !== '') parms = '?' + parms

    return $axios.get(`mapas/${empresa}/kmzData/${modulo}/${item}${parms}`)
  },
})
