//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
import {
  menuHiddenBreakpoint,
  subHiddenBreakpoint,
} from '../../constants/config'

import SubMenu from './SideBar/SubMenu'
import MainMenu from './SideBar/MainMenu'
import menuItems from '@/data/menu'

export default {
  name: 'SideBar',
  components: {
    SubMenu,
    MainMenu,
  },
  data() {
    return {
      menuItems,
      selectedParentMenu: '',
      viewingParentMenu: '',
      moduleSelected: '',
    }
  },
  computed: {
    ...mapGetters({
      menuType: 'menu/getMenuType',
      menuClickCount: 'menu/getMenuClickCount',
      selectedMenuHasSubItems: 'menu/getSelectedMenuHasSubItems',
    }),
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        const toParentUrl = to.path.split('/').filter((x) => x !== '')
        if (
          toParentUrl !== undefined &&
          toParentUrl !== null &&
          toParentUrl.length > 0
        ) {
          this.selectedParentMenu =
            toParentUrl[toParentUrl.length - 1].toLowerCase()
        } else {
          this.selectedParentMenu = 'home'
        }

        this.selectMenu()
        this.toggle()
        this.changeSideMenuStatus({
          step: 0,
          classNames: this.menuType,
          selectedMenuHasSubItems: this.selectedMenuHasSubItems,
        })

        window.scrollTo(0, top)
      }
    },
  },
  mounted() {
    this.selectMenu()
    window.addEventListener('resize', this.handleWindowResize)
    document.addEventListener('click', this.handleDocumentClick)
    this.handleWindowResize()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick)
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    ...mapMutations({
      changeSideMenuStatus: 'menu/changeSideMenuStatus',
      addMenuClassname: 'menu/addMenuClassname',
      changeSelectedMenuHasSubItems: 'menu/changeSelectedMenuHasSubItems',
    }),
    selectMenu() {
      const currentParentUrl = this.$nuxt.$route.path
        .split('/')
        .filter((x) => x !== '')

      if (
        currentParentUrl !== undefined &&
        currentParentUrl !== null &&
        currentParentUrl.length > 0
      ) {
        this.selectedParentMenu =
          currentParentUrl[currentParentUrl.length - 1].toLowerCase()
      } else {
        this.selectedParentMenu = 'home'
      }
      this.isCurrentMenuHasSubItem()
    },
    isCurrentMenuHasSubItem() {
      const menuItem = this.menuItems.find(
        (x) => x.id === this.selectedParentMenu
      )
      const isCurrentMenuHasSubItem =
        menuItem && menuItem.subs && menuItem.subs.length > 0
      if (isCurrentMenuHasSubItem !== this.selectedMenuHasSubItems) {
        if (!isCurrentMenuHasSubItem) {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: false,
          })
        } else {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: true,
          })
        }
      }

      return isCurrentMenuHasSubItem
    },

    changeSelectedParentHasNoSubmenu(parentMenu) {
      this.selectedParentMenu = parentMenu
      this.moduleSelected = parentMenu
      this.viewingParentMenu = parentMenu
      this.changeSelectedMenuHasSubItems(false)
      this.changeSideMenuStatus({
        step: 0,
        classNames: this.menuType,
        selectedMenuHasSubItems: false,
      })
    },

    openSubMenu(e, menuItem) {
      const selectedParent = menuItem.id
      const hasSubMenu = menuItem.subs && menuItem.subs.length > 0
      this.changeSelectedMenuHasSubItems(hasSubMenu)
      if (!hasSubMenu) {
        this.viewingParentMenu = selectedParent
        this.selectedParentMenu = selectedParent
        this.toggle()
      } else {
        const currentClasses = this.menuType
          ? this.menuType.split(' ').filter((x) => x !== '')
          : ''
        if (!currentClasses.includes('menu-mobile')) {
          if (
            currentClasses.includes('menu-sub-hidden') &&
            (this.menuClickCount === 2 || this.menuClickCount === 0)
          ) {
            this.changeSideMenuStatus({
              step: 3,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu,
            })
          } else if (
            currentClasses.includes('menu-hidden') &&
            (this.menuClickCount === 1 || this.menuClickCount === 3)
          ) {
            this.changeSideMenuStatus({
              step: 2,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu,
            })
          } else if (
            currentClasses.includes('menu-default') &&
            !currentClasses.includes('menu-sub-hidden') &&
            (this.menuClickCount === 1 || this.menuClickCount === 3)
          ) {
            this.changeSideMenuStatus({
              step: 0,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu,
            })
          } else if (
            currentClasses.includes('menu-default') &&
            (this.menuClickCount === 0 || this.menuClickCount === 1)
          ) {
            this.changeSideMenuStatus({
              step: 0,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu,
            })
          } else if (
            currentClasses.includes('menu-default') &&
            currentClasses.includes('menu-sub-hidden') &&
            currentClasses.includes('sub-show-temporary') &&
            this.menuClickCount === 3
          ) {
            this.changeSideMenuStatus({
              step: 0,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu,
            })
          }
        } else {
          this.addMenuClassname({
            classname: 'sub-show-temporary',
            currentClasses: this.menuType,
          })
        }
        this.viewingParentMenu = selectedParent
        this.moduleSelected = selectedParent
      }
    },
    handleDocumentClick() {
      this.viewingParentMenu = this.moduleSelected
      this.selectMenu()
      this.toggle()
    },
    toggle() {
      const currentClasses = this.menuType.split(' ').filter((x) => x !== '')
      if (
        currentClasses.includes('menu-sub-hidden') &&
        this.menuClickCount === 3
      ) {
        this.changeSideMenuStatus({
          step: 2,
          classNames: this.menuType,
          selectedMenuHasSubItems: this.selectedMenuHasSubItems,
        })
      } else if (
        currentClasses.includes('menu-hidden') ||
        currentClasses.includes('menu-mobile')
      ) {
        if (!(this.menuClickCount === 1 && !this.selectedMenuHasSubItems)) {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: this.selectedMenuHasSubItems,
          })
        }
      }
    },
    // Resize
    handleWindowResize(event) {
      if (event && !event.isTrusted) {
        return
      }
      const nextClasses = this.getMenuClassesForResize(this.menuType)
      this.changeSideMenuStatus({
        step: 0,
        classNames: nextClasses.join(' '),
        selectedMenuHasSubItems: this.selectedMenuHasSubItems,
      })
    },
    getMenuClassesForResize(classes) {
      let nextClasses = []
      if (classes !== undefined)
        nextClasses = classes.split(' ').filter((x) => x !== '')

      const windowWidth = window.innerWidth

      if (windowWidth < menuHiddenBreakpoint) {
        nextClasses.push('menu-mobile')
      } else if (windowWidth < subHiddenBreakpoint) {
        nextClasses = nextClasses.filter((x) => x !== 'menu-mobile')
        if (
          nextClasses.includes('menu-default') &&
          !nextClasses.includes('menu-sub-hidden')
        ) {
          nextClasses.push('menu-sub-hidden')
        }
      } else {
        nextClasses = nextClasses.filter((x) => x !== 'menu-mobile')
        if (
          nextClasses.includes('menu-default') &&
          nextClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = nextClasses.filter((x) => x !== 'menu-sub-hidden')
        }
      }
      return nextClasses
    },
  },
}
