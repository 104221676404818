export default ($axios) => ({
  findTipos(tipoFicha, search) {
    let parms = ''
    if (search !== undefined && search !== null) parms += 'search=' + search
    if (parms !== '') parms = '?' + parms

    return $axios.get(`/tiposFicha/all/${tipoFicha}${parms}`)
  },

  saveTipo(tipo) {
    return $axios.post(`/tiposFicha`, tipo)
  },

  deleteTipo(idTipo) {
    return $axios.delete(`/tiposFicha/${idTipo}`)
  },
})
