export const ensaios = [
  {
    ligantes: [
      {
        id: 14,
        descricao: 'Caracterização de Ligante Betuminoso - Asfalto Borracha',
        permissao: 'CARACTERIZACAO_LIGANTE_BETUMINOSO_ASFALTO_BORRACHA',
        tipoLigante: 'ASFALTO_BORRACHA',
        route: 'liganteAsfalto',
        tipoEnsaio: 'ASFALTO_BORRACHA',
        grupo: 'ligantes',
      },
      {
        id: 15,
        descricao: 'Caracterização de Ligante Betuminoso - CAP Polimero 60/85',
        permissao: 'CARACTERIZACAO_LIGANTE_BETUMINOSO_POLIMERO_60_85',
        tipoLigante: 'CAP_POLIMERO_60_85',
        route: 'ligantePolimero',
        tipoEnsaio: 'CAP_POLIMERO_60_85',
        grupo: 'ligantes',
      },
      {
        id: 16,
        descricao:
          'Caracterização de Ligante Betuminoso - CAP Convencional 30/45',
        permissao: 'CARACTERIZACAO_LIGANTE_BETUMINOSO_CAP_30_45',
        tipoLigante: 'CAP_CONVENCIONAL_30_45',
        route: 'liganteCap30',
        tipoEnsaio: 'CAP_CONVENCIONAL_30_45',
        grupo: 'ligantes',
      },
      {
        id: 17,
        descricao:
          'Caracterização de Ligante Betuminoso - CAP Convencional 50/70',
        permissao: 'CARACTERIZACAO_LIGANTE_BETUMINOSO_CAP_50_70',
        tipoLigante: 'CAP_CONVENCIONAL_50_70',
        route: 'liganteCap50',
        tipoEnsaio: 'CAP_CONVENCIONAL_50_70',
        grupo: 'ligantes',
      },
      {
        id: 28,
        descricao:
          'Caracterização de Ligante Betuminoso - Emulsão Asfáltica RR-1C',
        permissao: 'CARACTERIZACAO_LIGANTE_BETUMINOSO_RR_1C',
        tipoLigante: 1,
        route: 'liganteEmulsao1C',
        tipoEnsaio: 1,
        grupo: 'ligantes',
      },
      {
        id: 29,
        descricao:
          'Caracterização de Ligante Betuminoso - Emulsão Asfáltica RR-2C',
        permissao: 'CARACTERIZACAO_LIGANTE_BETUMINOSO_RR_2C',
        tipoLigante: 2,
        route: 'liganteEmulsao2C',
        tipoEnsaio: 2,
        grupo: 'ligantes',
      },
    ].sort((a, b) =>
      a.id ? a.id - b.id : a.descricao.localeCompare(b.descricao)
    ),
    misturaAsfaltica: [
      {
        id: 18,
        descricao: 'Controle Temperatura de Insumos e da Mistura - Usina',
        permissao: 'TEMPERATURA_INSUMOS_MISTURA_USINA',
        tipo: 'CONTROLE_TEMPERATURA_INSUMOS',
        route: 'temperaturaInsumos',
        tipoEnsaio: 'CTI/USI',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 19,
        descricao: 'Danos por Umidade Induzida - DUI',
        permissao: 'DANO_UMIDADE_INDUZIDA_DUI',
        tipo: 'UMIDADE_INDUZIDA',
        route: 'danosUmidade',
        tipoEnsaio: 'UMD/IND',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 20,
        descricao: 'Teor de ligante, Granulometria e Ensaio Marshall',
        permissao: 'TEOR_LIGANTE_GRANULOMETRIA_MARSHALL',
        tipo: 'TEOR_LIGANTE',
        route: 'teorGranulometria',
        tipoEnsaio: 'TEO/GRN',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 21,
        descricao: 'Controle de Execução de Mistura Asfáltica - Pista',
        permissao: 'CONTROLE_EXECUCAO_MISTURA_ASFALTICA_PISTA',
        tipo: 'CONTROLE_EXECUCAO_MISTURA',
        route: 'controleExecucao',
        tipoEnsaio: 'CEM/PIS',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 22,
        descricao:
          'Controle de Grau de Compactação e Espessura - Sonda Rotativa',
        permissao: 'CONTROLE_GRAU_COMPACTACAO_ESPESSURA',
        tipo: 'CONTROLE_COMPACTACAO_ESPESSURA',
        route: 'controleGrau',
        tipoEnsaio: 'CTC/MIS',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 23,
        descricao: 'Resistência a Tração por Compressão Diametral',
        permissao: 'RESISTENCIA_TRACAO_COMPRESSAO_DIAMETRAL',
        tipo: 'RESISTENCIA_TRACAO',
        route: 'resistenciaTracao',
        tipoEnsaio: 'RES/TRA',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 24,
        descricao:
          'Medição da Irregularidade Longitudinal - Equipamento Merlin',
        permissao: 'MERLIN',
        tipo: 'ENSAIO_RESUMO_MERLIN',
        route: 'merlin',
        tipoEnsaio: 'MIS/MER',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 27,
        descricao: 'Teor e Granulometria - Microrrevestimento Asfáltico',
        permissao: 'TEOR_GRANULOMETRIA_MICRO',
        tipo: 'ENSAIO_TEOR_MICRO',
        route: 'teorMicro',
        tipoEnsaio: 'TEO/MIC',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 33,
        descricao: 'Controle de Grau de Compactação - Densímetro Não Nuclear',
        permissao: 'CONTROLE_GRAU_COMPACTACAO_ESPESSURA_DENSIMETRO',
        tipo: 'ENSAIO_GRAU_COMPACTACAO',
        route: 'controleDensimetro',
        tipoEnsaio: 'CMP/GRA',
        grupo: 'misturaAsfaltica',
      },
      {
        id: 34,
        descricao:
          'Determinação da Densidade Máxima de Misturas Betuminosas Não Compactadas - Rice Test',
        permissao: 'DENSIDADE_MAXIMA_TEORICA_RICE',
        tipo: 'ENSAIO_DENSIDADE_MAXIMA',
        route: 'densidadeMaxima',
        tipoEnsaio: 'MIS/MAX',
        grupo: 'misturaAsfaltica',
      },
    ].sort((a, b) =>
      a.id ? a.id - b.id : a.descricao.localeCompare(b.descricao)
    ),
    solosAgregados: [
      {
        id: 37,
        descricao: 'Boletim de Sondagem - Cilindro Cortante',
        permissao: 'BOLETIM_SONDAGEM_CILINDRO_CORTANTE',
        tipo: 'ENSAIO_SONDAGEM_CILINDRO',
        route: 'boletimSondagem',
        tipoEnsaio: 'SON/CIL',
        grupo: 'solosAgregados',
      },
      {
        id: 1,
        descricao: 'Caracterização de Solos',
        permissao: 'CARACTERIZACAO_SOLOS_LL_LP',
        tipo: 'ENSAIO_CARACTERIZACAO_SOLO',
        route: 'caracterizacaoSolos',
        tipoEnsaio: 'CAR/SOL',
        grupo: 'solosAgregados',
      },
      {
        id: 4,
        descricao: 'Controle de Compactação - Método do Frasco de Areia',
        permissao: 'CONTROLE_COMPACTACAO',
        tipo: 'CONTROLE_COMPACTACAO_FRASCO',
        route: 'controleCompactacao',
        tipoEnsaio: 'CTC/FRA',
        grupo: 'solosAgregados',
      },
      {
        id: 10,
        descricao: 'Densidade Real e Absorção dos Agregados',
        permissao: 'DENSIDADE_REAL_ABSORCAO_AGREGADOS',
        tipo: 'ENSAIO_DENSIDADE_REAL_ABSORCAO',
        route: 'densidadeReal',
        tipoEnsaio: 'DEN/ABS',
        grupo: 'solosAgregados',
      },
      {
        id: 2,
        descricao: 'Ensaio de Compactação - (ISC/CBR) e Expansão',
        permissao: 'INDICE_SUPORTE_CALIFORNIA_ISC_CBR_EXPANSAO',
        tipo: 'ENSAIO_COMPACTACAO',
        route: 'ensaioCompactacao',
        tipoEnsaio: 'CMP/TAC',
        grupo: 'solosAgregados',
      },
      {
        id: 6,
        descricao: 'Ensaio de Equivalente de Areia',
        permissao: 'EQUIVALENTE_AREIA',
        tipo: 'ENSAIO_EQUIVALENTE_AREIA',
        route: 'equivalenteAreia',
        tipoEnsaio: 'EQV/ARE',
        grupo: 'solosAgregados',
      },
      {
        id: 36,
        descricao: 'Ensaio de Umidade Natural',
        permissao: 'UMIDADE_NATURAL',
        tipo: 'ENSAIO_UMIDADE_NATURAL',
        route: 'umidadeNatural',
        tipoEnsaio: 'UMD/NAT',
        grupo: 'solosAgregados',
      },
      {
        id: 11,
        descricao: 'Índice de Forma Crivos',
        permissao: 'INDICE_FORMA_CRIVOS',
        tipo: 'ENSAIO_INDICE_CRIVO',
        route: 'indiceFormasCrivos',
        tipoEnsaio: 'IDX/CRV',
        grupo: 'solosAgregados',
      },
      {
        id: 13,
        descricao: 'Índice de Lamelaridade',
        permissao: 'INDICE_LAMELARIDADE',
        tipo: 'ENSAIO_LAMELARIDADE',
        route: 'indiceLamelaridade',
        tipoEnsaio: 'IDX/LAM',
        grupo: 'solosAgregados',
      },
    ].sort((a, b) =>
      a.id ? a.id - b.id : a.descricao.localeCompare(b.descricao)
    ),
    geral: [
      {
        id: 9,
        descricao: 'Análise Granulométrica de Agregados',
        permissao: 'GRANULOMETRIA_AGREGADOS',
        tipo: 'ENSAIO_GRANULOMETRIA_AGREGADOS',
        route: 'analiseGranulometrica',
        tipoEnsaio: 'ANA/GAG',
        grupo: 'geral',
      },
      {
        id: 5,
        descricao: 'Determinação das Deflexões - Viga Benkelman',
        permissao: 'DETERMINACAO_DEFLEXOES_VIGA_BENKELMAN',
        tipo: 'ENSAIO_VIGA_BENKELMAN',
        route: 'determinacaoDeflexoes',
        tipoEnsaio: 'VIG/BKL',
        grupo: 'geral',
      },
      {
        id: 25,
        descricao: 'Macrotextura - Ensaio de Mancha de Areia',
        permissao: 'MACROTEXTURA_ENSAIO_MANCHA_AREIA',
        tipo: 'ENSAIO_MANCHA_AREIA',
        route: 'macrotextura',
        tipoEnsaio: 'MAN/ARE',
        grupo: 'geral',
      },
      {
        id: 26,
        descricao:
          'Microtextura - Resistência à Derrapagem - Método Pêndulo Britânico',
        permissao: 'MICROTEXTURA_RESISTENCIA_DERRAPAGEM_PENDULO',
        tipo: 'ENSAIO_RESISTENCIA_DERRAPAGEM',
        route: 'microtextura',
        tipoEnsaio: 'RES/DER',
        grupo: 'geral',
      },
      {
        id: 30,
        descricao: 'Análise de Microesfera de Vidro - Tipo (II-A)',
        permissao: 'ANALISE_MICROESFERA_VIDRO_TIPO_II_A',
        tipo: 'ENSAIO_MICROESFERA_VIDRO',
        route: 'analiseMicroesferaA',
        grupo: 'geral',
      },
      {
        id: 31,
        descricao: 'Análise de Microesfera de Vidro - Tipo (II-C)',
        permissao: 'ANALISE_MICROESFERA_VIDRO_TIPO_II_C',
        tipo: 'ENSAIO_MICROESFERA_VIDRO',
        route: 'analiseMicroesferaC',
        grupo: 'geral',
      },
      {
        id: 32,
        descricao: 'Análise de Tinta para Demarcação Viária',
        permissao: 'ANALISE_TINTA_DEMARCACAO_VIARIA',
        tipo: 'ENSAIO_DEMARCACAO_VIARIA',
        route: 'analiseTinta',
        tipoEnsaio: 'ADV/GRN',
        grupo: 'geral',
      },
      {
        id: 35,
        descricao: 'Taxa de Aplicação',
        permissao: 'TAXA_PINTURA_LIGACAO',
        tipo: 'ENSAIO_PINTURA_LIGACAO',
        route: 'taxaPintura',
        tipoEnsaio: 'LIG/PIN',
        grupo: 'geral',
      },
    ].sort((a, b) =>
      a.id ? a.id - b.id : a.descricao.localeCompare(b.descricao)
    ),
    concreto: [
      {
        id: 7,
        descricao:
          'Resistência Compressão Axial de Corpos de Prova Cilíndricos',
        permissao: 'RESISTENCIA_COMPRESSAO_AXIAL_CORPOS_PROVA_CILINDRICOS',
        tipo: 'ENSAIO_RESISTENCIA_COMPRESSAO_AXIAL',
        route: 'resistenciaCompressao',
        tipoEnsaio: 'RES/CAX',
        grupo: 'concreto',
      },
      {
        id: 8,
        descricao:
          'Resistência à Tração na Flexão de Corpos de Prova Prismáticos',
        permissao: 'RESISTENCIA_TRACAO_FLEXAO_CORPOS_PROVA_PRISMATICOS',
        tipo: 'ENSAIO_RESISTENCIA_TRACAO_FLEXAO',
        route: 'resistenciaFlexao',
        tipoEnsaio: 'RES/TFL',
        grupo: 'concreto',
      },
    ].sort((a, b) =>
      a.id ? a.id - b.id : a.descricao.localeCompare(b.descricao)
    ),
  },
]

export const tiposEnsaios = [
  {
    descricao: 'Caracterização de Ligante Betuminoso - Asfalto Borracha',
    tipo: 'ASFALTO_BORRACHA',
  },
  {
    descricao: 'Caracterização de Ligante Betuminoso - CAP Polimero 60/85',
    tipo: 'CAP_POLIMERO_60_85',
  },
  {
    descricao: 'Caracterização de Ligante Betuminoso - CAP Convencional 30/45',
    tipo: 'CAP_CONVENCIONAL_30_45',
  },
  {
    descricao: 'Caracterização de Ligante Betuminoso - CAP Convencional 50/70',
    tipo: 'CAP_CONVENCIONAL_50_70',
  },
  {
    descricao: 'Controle Temperatura de Insumos',
    tipo: 'CTI/USI',
  },
  {
    descricao: 'Danos por Umidade Induzida - DUI',
    tipo: 'UMD/IND',
  },
  {
    descricao: 'Teor de ligante, Granulometria e Ensaio Marshall',
    tipo: 'TEO/GRN',
  },
  {
    descricao: 'Controle de Execução de Mistura Asfáltica - Pista',
    tipo: 'CEM/PIS',
  },
  {
    descricao: 'Controle de Grau de Compactação e Espessura  - Sonda Rotativa',
    tipo: 'CTC/MIS',
  },
  {
    descricao: 'Resistência à Tração por Compressão Diametral',
    tipo: 'RES/TRA',
  },
  {
    descricao: 'Controle de Compactação ',
    tipo: 'CTC/FRA',
  },
  {
    descricao: 'Determinação das Deflexões - Viga Benkelman',
    tipo: 'VIG/BKL',
  },
  {
    descricao: 'Macrotextura - Ensaio de Mancha de Areia',
    tipo: 'MAN/ARE',
  },
  {
    descricao: 'Microtextura - Resistência à Derrapagem ',
    tipo: 'RES/DER',
  },
  {
    descricao: 'Caracterização de Solos ',
    tipo: 'CAR/SOL',
  },
  {
    descricao: 'Ensaio de Compactação - (ISC/CBR) e Expansão',
    tipo: 'CMP/TAC',
  },
  {
    descricao: 'Resistência Compressão Axial de Corpos de Prova Cilíndricos',
    tipo: 'RES/CAX',
  },
  {
    descricao: 'Resistência à Tração na Flexão de Corpos de Prova Prismáticos',
    tipo: 'RES/TFL',
  },
  {
    descricao: 'Ensaio de Equivalente de Areia',
    tipo: 'EQV/ARE',
  },
  {
    descricao: 'Análise Granulometria',
    tipo: 'ANA/GAG',
  },
  {
    descricao: 'Densidade Real e Absorção dos Agregados',
    tipo: 'DEN/ABS',
  },
  {
    descricao: 'Índice de Forma Crivos',
    tipo: 'IDX/CRV',
  },
  {
    descricao: 'Índice de Forma Paquimetro',
    tipo: 'IDX/PQT',
  },
  {
    descricao: 'Índice de Lamelaridade',
    tipo: 'IDX/LAM',
  },
  {
    descricao: 'Caracterização de Ligante Betuminoso - Emulsão Asfáltica RR-1C',
    tipo: 1,
  },
  {
    descricao:
      'Caracterização de Ligante Betuminoso -  Emulsão Asfáltica RR-2C',
    tipo: 2,
  },
  {
    descricao: 'Análise de Microsfera de Vidro',
    tipo: 'MEV/GRN',
    tipoMicroesfera: 1,
  },
  {
    descricao: 'Análise de Microsfera de Vidro - Tipo (II-C)',
    tipo: 'MEV/GRN',
    tipoMicroesfera: 2,
  },
  {
    descricao: 'Teor e Granulometria - Microrrevestimento Asfáltico',
    tipo: 'TEO/MIC',
  },
  {
    descricao: 'Análise de Tinta para Demarcação Viária',
    tipo: 'ADV/GRN',
  },
  {
    descricao: 'Medição da Irregularidade Longitudinal - Equipamento Merlin',
    tipo: 'MIS/MER',
  },
  {
    descricao: 'Controle de Grau de Compactação - Densímetro Não Nuclear',
    tipo: 'CMP/GRA',
  },
  {
    descricao: 'Taxa de Aplicação',
    tipo: 'LIG/PIN',
  },
  {
    descricao: 'Ensaio de Umidade Natural',
    tipo: 'UMD/NAT',
  },
  {
    descricao: 'Boletim de Sondagem - Cilindro Cortante',
    tipo: 'SON/CIL',
  },
  {
    descricao:
      'Determinação da Densidade Máxima de Misturas Betuminosas Não Compactadas - Rice Test',
    tipo: 'MIS/MAX',
  },
]

/**
 * Função que retorna os ensaios com dados atualizados (descricao)
 */
export function getEnsaiosUpdated() {
  function updateEnsaio(ensaio) {
    return {
      ...ensaio,
      descricao: `FE${ensaio.id} - ${ensaio.descricao}`,
      descricaoBase: `${ensaio.descricao}`,
    }
  }

  return ensaios.map((items) => {
    return {
      ligantes: items.ligantes.map((ensaio) => updateEnsaio(ensaio)),
      misturaAsfaltica: items.misturaAsfaltica.map((ensaio) =>
        updateEnsaio(ensaio)
      ),
      solosAgregados: items.solosAgregados.map((ensaio) =>
        updateEnsaio(ensaio)
      ),
      geral: items.geral.map((ensaio) => updateEnsaio(ensaio)),
      concreto: items.concreto.map((ensaio) => updateEnsaio(ensaio)),
    }
  })
}
