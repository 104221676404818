const URL_API = '/resumos'

export default ($axios) => ({
    findResumoCorrespondencias(filtro) {
        let parms = ''
        if (filtro) {
            const keys = Object.keys(filtro)
            for (const key of keys) {
                if (filtro[key] !== undefined && filtro[key] !== null) {
                    if (Array.isArray(filtro[key])) if (filtro[key].length === 0) continue
                    if (parms !== '') parms += '&'
                    parms += key + '=' + filtro[key]
                }
            }
        }

        if (parms !== '') parms = '?' + parms

        return $axios.get(`${URL_API}/correspondencias${parms}`)
    }
})