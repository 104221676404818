import { authHeaderMultipart } from '~/helpers'

export default ($axios) => ({
  findBoasPraticas(
    siglaEmpresa,
    payload,
    pageNumber,
    pageSize,
    sortField,
    sortDirection
  ) {
    if (!payload) payload = {}

    let pageNbr = pageNumber
    let pageSz = pageSize
    if (pageNbr == null || pageNbr === 0) pageNbr = 1
    if (pageSz == null || pageSz === 0) pageSz = 15

    let field = 'titulo'
    let sortDesc = true
    if (sortField !== null && sortField !== undefined) field = sortField
    if (sortDirection !== null && sortDirection !== undefined)
      sortDesc = sortDirection
    // if (search === null || search === undefined) {
      // return $axios.get(`/boasPraticas`)
    // } // else {
      $axios.setHeader('Content-Type', 'application/json')
      return $axios.post(`/boasPraticas/${siglaEmpresa}/filtros?page=${pageNbr}&size=${pageSz}&sortField=${field}&sortDesc=${sortDesc}`,
      payload
    )
  },

  getBoasPraticasById(idBoaPratica) {
    return $axios.get(`/boasPraticas/${idBoaPratica}`)
  },

  saveBoasPraticas(siglaEmpresa, boaPratica, anexos) {
    console.log('boasPraticas.service->Anexos=>', anexos)

    const requestCfg = {
      method: 'POST',
      headers: authHeaderMultipart(),
    }

    const formData = new FormData()

    if (boaPratica !== undefined) {
      formData.append(
        'boaPratica',
        JSON.stringify(boaPratica, (key, value) => {
          if (value !== null) return value
        })
      )
    }

    if (anexos !== undefined) {
      const anexosRaw = []
      for (const anexo of anexos) {
        formData.append('filesAnexos', anexo.file)
        anexosRaw.push({
          comentario: anexo.comentario,
          anexo: {
            nome: anexo.file.name,
          },
        })
      }

      formData.append('anexos', JSON.stringify(anexosRaw))
    }

    return $axios.post(`/boasPraticas/${siglaEmpresa}`, formData, requestCfg)
  },

  deleteBoasPraticas(idBoasPraticas) {
    return $axios.delete(`/boasPraticas/${idBoasPraticas}`)
  },

  findAnexos(idBoasPraticas) {
    return $axios.get(`/boasPraticas/${idBoasPraticas}/anexos`)
  },

  createAnexo(siglaEmpresa, idBoasPraticas, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/boasPraticas/${siglaEmpresa}/${idBoasPraticas}/anexos`,
      formData
    )
  },

  deleteAnexo(siglaEmpresa, idBoasPraticas, seqAnexo) {
    return $axios.delete(
      `/boasPraticas/${siglaEmpresa}/${idBoasPraticas}/anexos/${seqAnexo}`
    )
  },

  editComentarioAnexo(siglaEmpresa, idBoasPraticas, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/boasPraticas/${siglaEmpresa}/${idBoasPraticas}/anexos/comentarios`,
      payload
    )
  },

  girarImagem(siglaEmpresa, idBoasPraticas, idAnexo, angulo) {
    return $axios.post(
      `/boasPraticas/${siglaEmpresa}/${idBoasPraticas}/anexos/girar/${idAnexo}/${angulo}`
    )
  },

  downloadExcel(empresa, filter) {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    let params = ''

    if (Object.keys(filter).length > 0) {
      params =
        '?' +
        Object.keys(filter)
          .map((key) => key + '=' + filter[key])
          .join('&')
    }

    return $axios.post(
      `/boasPraticas/${empresa}/exportacaoExcel${params}`,
      {},
      requestCfg
    )
  },

  downloadPdf(empresa, idBoasPraticas) {
    return $axios.get(
      `/boasPraticas/${empresa}/exportacaoPdf/${idBoasPraticas}`,
      {
        responseType: 'blob',
      }
    )
  },

  findHistoricos(idBoasPraticas) {
    return $axios.get(`/boasPraticas/${idBoasPraticas}/historicos`)
  },

  saveHistorico(siglaEmpresa, idBoasPraticas, historico) {
    const formData = new FormData()

    if (historico !== undefined)
      formData.append('descricao', historico.descricao + '')

    return $axios.post(
      `/boasPraticas/${siglaEmpresa}/${idBoasPraticas}/historicos`,
      formData
    )
  },
})
