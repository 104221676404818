export const state = () => ({
  idLDPTC: null || undefined,
})

export const mutations = {
  changeLDPTC(state, ldptc) {
    state.idLDPTC = ldptc.id
  },
  clearLDPTC(state) {
    state.idLDPTC = null
  },
}

export const actions = {
  setLDPTC({ commit }, ldptc) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeLDPTC', ldptc)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearLDPTC')
  },
}

export const getters = {
  idLDPTC(state) {
    return state.idLDPTC;
  }
}