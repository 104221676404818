export default ($axios) => ({
  findTiposEventos(siglaEmpresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(
        `/cadastros/atv/${siglaEmpresa}/tiposEventos`
      )
    } else {
      return $axios.get(
        `/cadastros/atv/${siglaEmpresa}/tiposEventos?search=${search}`
      )
    }
  },

  getTipoEventoById(siglaEmpresa, idEvento) {
    return $axios.get(
      `/cadastros/atv/${siglaEmpresa}/tiposEventos/${idEvento}`
    )
  },

  saveTipoEvento(siglaEmpresa, tipoEvento) {
    return $axios.post(
      `/cadastros/atv/${siglaEmpresa}/tiposEventos`,
      tipoEvento
    )
  },

  deleteTipoEvento(siglaEmpresa, idTipoEvento) {
    return $axios.delete(
      `/cadastros/atv/${siglaEmpresa}/tiposEventos/${idTipoEvento}`
    )
  },
})
