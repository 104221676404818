export default ($axios) => ({
  findParametrosEnsaios(search) {
    return $axios.get(`/parametros`)
  },
  getByIdParametroEnsaios(idParametro) {
    return $axios.get(`/parametros/${idParametro}`)
  },
  saveParametroEnsaios(parametro) {
    return $axios.post(`/parametros`, parametro)
  },
  deleteParametroEnsaios(idParametro) {
    return $axios.delete(`/parametros/${idParametro}`)
  },
})
