export default ($axios) => ({
  find(search) {
    if (search === null || search === undefined || search === '') {
      return $axios.get(`/cadastros/fluxosCaixa`)
    } else {
      return $axios.get(`/cadastros/fluxosCaixa/?search=${search}`)
    }
  },

  getById(idFluxo) {
    return $axios.get(`/cadastros/fluxosCaixa/${idFluxo}`)
  },

  save(payload) {
    return $axios.post('/cadastros/fluxosCaixa/', payload)
  },
})
