export default ($axios) => ({
  findCategoriasAtivos(tipo, search) {
    let parms = ''
    if (tipo != null) {
      if (parms !== '') parms += '&'
      else parms += '?'

      parms = parms + `tipoAtivo=${tipo}`
    }

    if (search != null) {
      if (parms !== '') parms += '&'
      else parms += '?'

      parms = '/' + parms + `search=${search}`
    }

    return $axios.get(`/cadastros/atv/categorias${parms}`)
  },
  getCategoriaAtivoById(idCategoria) {
    return $axios.get(`/cadastros/atv/categorias/${idCategoria}`)
  },

  saveCategoriaAtivo(categoria) {
    return $axios.post(`/cadastros/atv/categorias`, categoria)
  },

  deleteCategoriaAtivo(idCategoria) {
    return $axios.delete(`/cadastros/atv/categorias/${idCategoria}`)
  },
})
