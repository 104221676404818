import { authHeaderMultipart } from '~/helpers'

export default ($axios) => ({
  findDeposito(
    siglaEmpresa,
    payload,
    pageNumber,
    pageSize,
    sortField,
    sortDirection
  ) {
    if (!payload) payload = {}

    let pageNbr = pageNumber
    let pageSz = pageSize
    if (pageNbr == null || pageNbr === 0) pageNbr = 1
    if (pageSz == null || pageSz === 0) pageSz = 15

    let field = 'numero'
    let sortDesc = true
    if (sortField !== null && sortField !== undefined) field = sortField
    if (sortDirection !== null && sortDirection !== undefined)
      sortDesc = sortDirection

    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/areasApoio/${siglaEmpresa}/filtros?page=${pageNbr}&size=${pageSz}&sortField=${field}&sortDesc=${sortDesc}`,
      payload
    )
  },

  getDepositoById(siglaEmpresa, idDeposito) {
    return $axios.get(`/areasApoio/${siglaEmpresa}/${idDeposito}`)
  },

  saveDepositoPost(
    siglaEmpresa,
    { areaApoio, ocorrencias, anexos, situacoes }
  ) {
    const requestCfg = {
      method: 'POST',
      headers: authHeaderMultipart(),
    }

    const formData = new FormData()

    if (areaApoio !== undefined) {
      formData.append(
        'areaApoio',
        JSON.stringify(areaApoio, (key, value) => {
          if (value !== null) return value
        })
      )
    }

    if (ocorrencias !== undefined)
      formData.append('ocorrencias', JSON.stringify(ocorrencias))
    console.log(anexos)
    if (anexos !== undefined) {
      const anexosRaw = []
      for (const anexo of anexos) {
        formData.append('filesAnexos', anexo.file)
        anexosRaw.push({
          comentario: anexo.comentario,
          anexo: {
            nome: anexo.file.name,
          },
        })
      }

      if (situacoes !== undefined) {
        formData.append('situacoes', JSON.stringify(situacoes))
      }

      formData.append('anexos', JSON.stringify(anexosRaw))
    }

    return $axios.post(`/areasApoio/${siglaEmpresa}`, formData, requestCfg)
  },

  saveDepositoPut(siglaEmpresa, areaApoio) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.put(`/areasApoio/${siglaEmpresa}/pareceres`, areaApoio)
  },

  deleteDeposito(siglaEmpresa, idDeposito) {
    return $axios.delete(`/areasApoio/${siglaEmpresa}/${idDeposito}`)
  },

  createAnexo(siglaEmpresa, idDeposito, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')
    return $axios.post(
      `/areasApoio/${siglaEmpresa}/${idDeposito}/anexos`,
      formData
    )
  },

  findAnexos(siglaEmpresa, idDeposito) {
    return $axios.get(`/areasApoio/${siglaEmpresa}/${idDeposito}/anexos`)
  },

  deleteAnexo(siglaEmpresa, idDeposito, seqAnexo) {
    return $axios.delete(
      `/areasApoio/${siglaEmpresa}/${idDeposito}/anexos/${seqAnexo}`
    )
  },

  findOcorrenciasDeposito(siglaEmpresa, idDeposito) {
    return $axios.get(`/areasApoio/${siglaEmpresa}/${idDeposito}/ocorrencias`)
  },

  findHistoricos(siglaEmpresa, idDeposito) {
    return $axios.get(`/areasApoio/${siglaEmpresa}/${idDeposito}/historicos`)
  },

  saveHistorico(siglaEmpresa, idDeposito, historico) {
    const formData = new FormData()

    if (historico !== undefined)
      formData.append('descricao', historico.descricao + '')

    return $axios.post(
      `/areasApoio/${siglaEmpresa}/${idDeposito}/historicos`,
      formData
    )
  },
  girarImagem(siglaEmpresa, idDeposito, idAnexo, angulo) {
    return $axios.post(
      `/areasApoio/${siglaEmpresa}/${idDeposito}/anexos/girar/${idAnexo}/${angulo}`
    )
  },

  editComentarioAnexo(siglaEmpresa, idDeposito, payload) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(
      `/areasApoio/${siglaEmpresa}/${idDeposito}/anexos/comentarios`,
      payload
    )
  },

  downloadPdf(empresa, idDeposito, tipoMapa = 'S') {
    return $axios.get(
      `/areasApoio/${empresa}/exportacaoPdf/${idDeposito}?tipoMapa=${tipoMapa}`,
      {
        responseType: 'blob',
      }
    )
  },
  downloadExcel(empresa, filter) {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }

    return $axios.post(
      `/areasApoio/${empresa}/exportacaoExcel`,
      filter,
      requestCfg
    )
  },

  findAprovacoesDeposito(siglaEmpresa, idDeposito) {
    console.log('findAprovacoesDeposito=>', siglaEmpresa, idDeposito)
    return $axios.get(`/areasApoio/${siglaEmpresa}/${idDeposito}/aprovacoes`)
  },

  executeEvento(empresa, idDeposito, tipoEvento, paramsPayload = {}) {
    $axios.setHeader('Content-Type', 'application/json')

    const { seqAprovacao, justificativa, perfilReprovar } = paramsPayload

    let parms = ''

    if (Object.keys(paramsPayload).length > 0) {
      if (seqAprovacao !== undefined) parms += '&seqAprovacao=' + seqAprovacao
      if (justificativa !== undefined) parms += '&descricao=' + justificativa
      if (perfilReprovar !== undefined)
        parms += '&perfilReprovar=' + perfilReprovar

      parms = '?' + parms.slice(1)
    }

    return $axios.post(
      `/areasApoio/${empresa}/${idDeposito}/eventos/${tipoEvento}${parms}`
    )
  },

  findSituacoes(empresa, idAreaApoio) {
    return $axios.get(`/areasApoio/${empresa}/${idAreaApoio}/situacoes`)
  },

  saveSituacao(empresa, idAreaApoio, situacao) {
    return $axios.post(
      `/areasApoio/${empresa}/${idAreaApoio}/situacoes`,
      situacao
    )
  },

  findSituacaoById(empresa, idAreaApoio, idSituacao) {
    return $axios.get(
      `/areasApoio/${empresa}/${idAreaApoio}/situacoes/${idSituacao}`
    )
  },

  deleteSituacao(empresa, idAreaApoio, idSituacao) {
    return $axios.delete(
      `/areasApoio/${empresa}/${idAreaApoio}/situacoes/${idSituacao}`
    )
  },

  // Encerramento e Reabertura de área de apoio
  eventoAreaApoio(siglaEmpresa, idArea, tipoEvento) {
    return $axios.put(
      `/areasApoio/${siglaEmpresa}/${idArea}/eventos/${tipoEvento}`
    )
  },

  saveReferencias(siglaEmpresa, idDeposito, payload) {
    return $axios.post(
      `/areasApoio/${siglaEmpresa}/${idDeposito}/referencias`,
      payload
    )
  },

  getReferencias(siglaEmpresa, idDeposito) {
    return $axios.get(`/areasApoio/${siglaEmpresa}/${idDeposito}/referencias`)
  },

  deleteReferencia(siglaEmpresa, idDeposito, idReferencia) {
    return $axios.delete(
      `/areasApoio/${siglaEmpresa}/${idDeposito}/referencias/${idReferencia}`
    )
  },
})
