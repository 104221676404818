import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAnglesRight,
  faAnglesLeft,
  faAddressBook,
  faArchive,
  faArchway,
  faAsterisk,
  faBolt,
  faBookmark,
  faBorderAll,
  faBox,
  faClover,
  faEarthAmericas,
  faBrazilianRealSign,
  faBuilding,
  faBuildingUser,
  faBullseye,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCamera,
  faCaretDown,
  faX,
  faPen,
  faCaretUp,
  faCashRegister,
  faChalkboardTeacher,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChessBoard,
  faChevronUp,
  faChevronDown,
  faCity,
  faClipboard,
  faClipboardCheck,
  faCloudSun,
  faComments,
  faCut,
  faDatabase,
  faDiagnoses,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeCircleCheck,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faEraser,
  faExclamation,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileCsv,
  faFileExcel,
  faFileExport,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileLines,
  faFilePdf,
  faBell,
  faFlagCheckered,
  faFlask,
  faFolderTree,
  faGavel,
  faGear,
  faGlobe,
  faHandshake,
  faHardHat,
  faHelmetSafety,
  faHome,
  faImage,
  faInfo,
  faChalkboardUser,
  faInfoCircle,
  faKaaba,
  faKey,
  faKeyboard,
  faLayerGroup,
  faLeaf,
  faListCheck,
  faCommentDots,
  faLocationDot,
  faLock,
  faMap,
  faMapMarked,
  faMapSigns,
  faMessage,
  faMinus,
  faNetworkWired,
  faPanorama,
  faPencilRuler,
  faPercent,
  faDiagramNext,
  faPlus,
  faPlusCircle,
  faPrescriptionBottle,
  faPrint,
  faProjectDiagram,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faRecordVinyl,
  faRecycle,
  faRedoAlt,
  faReply,
  faRoad,
  faRotateLeft,
  faRotateRight,
  faRoute,
  faRuler,
  faRulerCombined,
  faSave,
  faSearch,
  faSearchPlus,
  faShieldAlt,
  faSignal,
  faSitemap,
  faSnowplow,
  faSolarPanel,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faStamp,
  faSyncAlt,
  faTable,
  faTableCells,
  faTableList,
  faTasks,
  faTicketAlt,
  faTimes,
  faToolbox,
  faTools,
  faTrafficLight,
  faTrashAlt,
  faTree,
  faTv,
  faUndoAlt,
  faUpload,
  faUser,
  faUserCheck,
  faUserGroup,
  faUserPen,
  faUsers,
  faUserShield,
  faUserTag,
  faVial,
  faVials,
  faReceipt,
  faMagnifyingGlass,
  faWater,
  faEquals,
  faWindowMaximize,
  faWindowRestore,
  faWrench,
  faWeightHanging,
  faFileContract,
  faAngleDown,
  faSheetPlastic,
  faClock,
  faCircleInfo,
  faMoneyBillWave,
  faRightFromBracket,
  faMapLocationDot,
  faCodeCompare,
  faArrowRight,
  faArrowLeft,
  faStream,
  faRandom,
  faFileWord,
  faRoadBarrier,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faAngleUp,
  faBars,
  faClockRotateLeft,
  faFolder,
  faSignature,
  faToggleOff,
  faToggleOn,
  faTriangleExclamation,
} from '~/node_modules/@fortawesome/free-solid-svg-icons/index'

library.add(
  faAddressBook,
  faAngleDown,
  faArchive,
  faMagnifyingGlass,
  faArchway,
  faReceipt,
  faX,
  faPen,
  faAsterisk,
  faFileContract,
  faCommentDots,
  faEquals,
  faAsterisk,
  faBolt,
  faClover,
  faBookmark,
  faSheetPlastic,
  faWeightHanging,
  faBorderAll,
  faBox,
  faChalkboardUser,
  faBell,
  faBox,
  faBrazilianRealSign,
  faBuilding,
  faBuildingUser,
  faBullseye,
  faCalendar,
  faCalendarAlt,
  faEarthAmericas,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCamera,
  faCamera,
  faCaretDown,
  faDiagramNext,
  faCaretUp,
  faCashRegister,
  faChalkboardTeacher,
  faChartArea,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faChartPie,
  faCheck,
  faCheck,
  faCheckCircle,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faCheckSquare,
  faChessBoard,
  faChessBoard,
  faChevronUp,
  faChevronDown,
  faCity,
  faClipboard,
  faClipboardCheck,
  faClipboardCheck,
  faCloudSun,
  faComments,
  faComments,
  faCut,
  faCut,
  faDatabase,
  faDiagnoses,
  faDiagnoses,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeCircleCheck,
  faEnvelopeCircleCheck,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faEnvelopesBulk,
  faEraser,
  faExclamation,
  faExclamationCircle,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileAlt,
  faFileCsv,
  faFileCsv,
  faFileExcel,
  faFileExport,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileLines,
  faFilePdf,
  faFileExcel,
  faFlagCheckered,
  faFlagCheckered,
  faFlask,
  faFolderTree,
  faGavel,
  faGear,
  faGlobe,
  faHandshake,
  faHardHat,
  faHelmetSafety,
  faHome,
  faImage,
  faInfo,
  faInfo,
  faInfoCircle,
  faKaaba,
  faKaaba,
  faKeyboard,
  faLayerGroup,
  faLayerGroup,
  faKey,
  faLeaf,
  faLeaf,
  faListCheck,
  faLocationDot,
  faLock,
  faMap,
  faMapMarked,
  faMapSigns,
  faMapSigns,
  faMessage,
  faMinus,
  faNetworkWired,
  faNetworkWired,
  faPanorama,
  faPencilRuler,
  faPencilRuler,
  faPercent,
  faPlus,
  faPlusCircle,
  faPrescriptionBottle,
  faPrint,
  faProjectDiagram,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faRecordVinyl,
  faRecordVinyl,
  faRecycle,
  faRecycle,
  faRedoAlt,
  faReply,
  faRoad,
  faRotateLeft,
  faRotateRight,
  faRoute,
  faRuler,
  faRulerCombined,
  faSave,
  faSearch,
  faSearchPlus,
  faShieldAlt,
  faShieldAlt,
  faSignal,
  faSitemap,
  faSitemap,
  faSnowplow,
  faSolarPanel,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faStamp,
  faSyncAlt,
  faSyncAlt,
  faTable,
  faTableCells,
  faTableList,
  faTasks,
  faTicketAlt,
  faTimes,
  faTools,
  faTools,
  faToolbox,
  faTrafficLight,
  faTrashAlt,
  faTrashAlt,
  faTree,
  faTv,
  faUndoAlt,
  faUpload,
  faUser,
  faUserCheck,
  faUserGroup,
  faUserPen,
  faUsers,
  faUserShield,
  faUserTag,
  faVial,
  faVials,
  faWater,
  faWindowMaximize,
  faWindowMaximize,
  faWindowRestore,
  faWrench,
  faAngleDown,
  faClock,
  faLocationDot,
  faCircleInfo,
  faInfo,
  faMoneyBillWave,
  faGear,
  faKey,
  faRightFromBracket,
  faMapLocationDot,
  faCodeCompare,
  faArrowRight,
  faArrowLeft,
  faStream,
  faRandom,
  faBell,
  faFileWord,
  faRoadBarrier,
  faTriangleExclamation,
  faToggleOff,
  faSignature,
  faFolder,
  faBars,
  faClockRotateLeft,
  faAngleUp,
  faAnglesRight,
  faAnglesLeft,
  faToggleOn,
  faEnvelopesBulk,
  faExclamation,
)

Vue.component('FaIcon', FontAwesomeIcon)
