export default ($axios) => ({
  findFornecedores(empresa, search) {
    if (search === null || search === undefined || search === '') {
      return $axios.get(`/cadastros/${empresa}/fornecedores`)
    } else {
      return $axios.get(`/cadastros/${empresa}/fornecedores/?search=${search}`)
    }
  },

  getFornecedorByModulo(empresa, modulo) {
    return $axios.get(`/cadastros/${empresa}/fornecedores?modulo=${modulo}`)
  },

  getFornecedorById(empresa, idFornecedor) {
    return $axios.get(`/cadastros/${empresa}/fornecedores/${idFornecedor}`)
  },

  saveFornecedor(empresa, fornecedor) {
    return $axios.post(`/cadastros/${empresa}/fornecedores`, fornecedor)
  },

  deleteFornecedor(empresa, idFornecedor) {
    return $axios.delete(`/cadastros/${empresa}/fornecedores/${idFornecedor}`)
  },
})
