export default ($axios) => ({
  importFileFlorestal(siglaEmpresa, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')

    return $axios.post(
      `/inspecoesFlorestais/${siglaEmpresa}/importacoes`,
      formData
    )
  },
})
