export default ($axios) => ({
  findProjetos(empresa, search) {
    $axios.setHeader('Content-Type', 'application/json')

    if (search === undefined) {
      return $axios.get(`/cadastros/lab/${empresa}/projetos`)
    } else {
      return $axios.get(`/cadastros/lab/${empresa}/projetos?search=${search}`)
    }
  },
  findProjetosById(empresa, idProjeto) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/cadastros/lab/${empresa}/projetos/${idProjeto}`)
  },
  findItensProjetos(empresa, idProjeto) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/cadastros/lab/${empresa}/projetos/${idProjeto}/itens`)
  },
  saveProjeto(empresa, projeto) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(`/cadastros/lab/${empresa}/projetos`, projeto)
  },
  saveItensProjetos(empresa, idProjeto, itens) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.post(
      `/cadastros/lab/${empresa}/projetos/${idProjeto}/itens`,
      itens
    )
  },
  deleteProjeto(empresa, idProjeto) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.delete(`/cadastros/lab/${empresa}/projetos/${idProjeto}`)
  },
  findAnexosProjeto(empresa, idProjeto) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(`/cadastros/lab/${empresa}/projetos/${idProjeto}/anexos`)
  },
  saveAnexosProjeto(empresa, idProjeto, token, formData) {
    $axios.setToken(token, 'Bearer', ['post'])
    $axios.setHeader('Content-Type', 'multipart/form-data')

    return $axios.post(
      `/cadastros/lab/${empresa}/projetos/${idProjeto}/anexos`,
      formData
    )
  },
  deleteAnexoProjeto(empresa, idProjeto, seqAnexo) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.delete(
      `/cadastros/lab/${empresa}/projetos/${idProjeto}/anexos/${seqAnexo}`
    )
  },
  findAnexosProjetoById(empresa, idProjeto, sequencia) {
    $axios.setHeader('Content-Type', 'application/json')

    return $axios.get(
      `/cadastros/lab/${empresa}/projetos/${idProjeto}/anexos/${sequencia}`
    )
  },
})
