export default ($axios) => ({

    findGrauRiscos(empresa, search) {
      if (search === null || search === undefined) {
        return $axios.get(`/cadastros/amb/${empresa}/grausRiscos`)
      } else {
        return $axios.get(`/cadastros/amb/${empresa}/grausRiscos?search=${search}`)
      }
    },

    getGrauRiscoById(empresa, idRisco) {
      return $axios.get(`/cadastros/amb/${empresa}/grausRiscos/${idRisco}`)
    },

    saveGrauRiscos(empresa, payload) {
      return $axios.post(`/cadastros/amb/${empresa}/grausRiscos`, payload)
    },

    updateGrauRiscos(empresa, idRisco, payload) {
      return $axios.put(`/cadastros/amb/${empresa}/grausRiscos/${idRisco}`, payload)
    },

    deleteGrauRiscos(empresa, idRisco) {
      return $axios.delete(`/cadastros/amb/${empresa}/grausRiscos/${idRisco}`)
    },
  })
