export const state = () => ({
  tipos: [],
})

export const mutations = {
  fill(state, rows) {
    state.tipos = rows
  },
}

export const actions = {
  find({ commit }, tipoFicha) {
    this.$tipoFichaService
      .findTipos(tipoFicha)
      .then((response) => {
        const tipos = []
        for (const item of response.data) {
          tipos.push(item)
        }

        commit('fill', tipos)
      })
      .catch((error) => {
        this.$notify({
          title: 'Erro',
          text: error.response ? error.response.data.message : error,
          type: 'error',
        })
      })
  },
}

export const getters = {
  tipos(state) {
    return state.tipos
  },
}
