export default ($axios) => ({
  find() {
    return $axios.get('/empresas')
  },

  findAll(search) {
    if (search === null || search === undefined) {
      return $axios.get('/empresas/all')
    } else {
      return $axios.get(`/empresas/all?search=${search}`)
    }
  },

  findEmpresaByToken() {
    return $axios.get('/empresas')
  },

  getBySigla(sigla) {
    return $axios.get(`/empresas/${sigla}`)
  },

  getLogomarcas() {
    return $axios.get(`/downloads/logomarcas`)
  },

  getBackgrounds() {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }
    return $axios.get(`/downloads/backgrounds`, requestCfg)
  },

  findAreas(empresa) {
    return $axios.get(`/empresas/${empresa}/areas`)
  },

  findAreaById(idArea) {
    return $axios.get(`/empresas/areas/${idArea}`)
  },

  saveArea(area) {
    return $axios.post(`/empresas/areas`, area)
  },

  deleteArea(idArea) {
    return $axios.delete(`/empresas/${idArea}/areas`)
  },

  findResponsaveisArea(empresa, idArea) {
    return $axios.get(`/empresas/${empresa}/${idArea}/responsaveisArea`)
  },

  findUsuariosAreas(empresa) {
    return $axios.get(`/empresas/${empresa}/usuariosAreas`)
  },

  findUsuarios(empresa, search, gruposUsuarios, inativos) {
    return $axios.get(`/empresas/${empresa}/usuarios`)
  },

  save(empresa) {
    return $axios.post(`/empresas`, empresa)
  },

  deleteEmpresa(sigla) {
    return $axios.delete(`/empresas/${sigla}`)
  },

  findFiliais(siglaEmpresa, idHolding, incluirHolding) {
    const urlHolding = '?incluirHolding=true'

    if (incluirHolding === true) {
      return $axios.get(`/empresas/${siglaEmpresa}/filiais/${idHolding}${urlHolding}`)
    } else {
      return $axios.get(`/empresas/${siglaEmpresa}/filiais/${idHolding}`)
    }
  },

  findLocais(empresa) {
    return $axios.get(`/empresas/${empresa}/locais`)
  },
})
