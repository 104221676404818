export const state = () => ({
  rows: [],
})

export const mutations = {
  ADD_FILTRO(state, data) {
    state.rows.push(data)
  },
  CLEAR_FILTRO(state, nome) {
    const rows = state.rows
    for (let idx = 0; idx < rows.length; idx++) {
      if (rows[idx].nome === nome) {
        rows.splice(idx, 1)
        break
      }
    }
  },
}

export const actions = {
  updateFiltro({ commit }, payload) {
    const filtro = { nome: payload.nome, campos: new Map() }
    for (const item of payload.campos) {
      if (item === null) continue

      for (const prop in item) {
        if (Object.prototype.hasOwnProperty.call(item, prop)) {
          if (item[prop] !== undefined) filtro.campos.set(prop, item[prop])
          break
        }
      }
    }

    if (filtro !== null) {
      commit('CLEAR_FILTRO', filtro.nome)
      if (filtro.campos.size > 0) commit('ADD_FILTRO', filtro)
    }
  },
  clearFiltro({ commit }, nome) {
    commit('CLEAR_FILTRO', nome)
  },
}

export const getters = {
  getFiltro: (state) => (nome) => {
    return state.rows.find((item) => item.nome === nome)
  },
}
