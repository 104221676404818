export default function (context) {
  context.$axios.onError((error) => {
    if (error.response !== undefined && error.response.status === 401) {
      // context.$auth.logout()
      // context.redirect('/login')
      return Promise.reject(error)
    }

    /* 
    nuxtError({
      statusCode: error.response.status,
      message: error.message,
    })

    return Promise.resolve(false) */
  })
}
