export const state = () => ({
  idRacom: null,
  statusRacom: null || undefined,
  supervisorAmbiental: null || undefined,
  coordenadorObra: null || undefined,
})

export const mutations = {
  changeRacom(state, racom) {
    state.idRacom = racom.id.idRacom
    state.statusRacom = racom.status
    state.supervisorAmbiental = racom.idSupervisorAmbiental
    state.coordenadorObra = racom.idCoordenadorObra
  },
  clearRacom(state) {
    state.idRacom = null
    state.statusRacom = null
    state.supervisorAmbiental = null
    state.coordenadorObra = null
  },
}

export const actions = {
  setRacom({ commit }, racom) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeRacom', racom)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearRacom')
  },
}

export const getters = {
  idRacom(state) {
    return state.idRacom
  },
  statusRacom(state) {
    return state.statusRacom
  },
  supervisorAmbiental(state) {
    return state.supervisorAmbiental
  },
  coordenadorObra(state) {
    return state.coordenadorObra
  },
}
