export const state = () => ({
  idFicha: null,
  ficha: null,
})

export const mutations = {
  changeFicha(state, ficha) {
    state.idFicha = ficha.id.sequencia
    state.ficha = ficha
  },
  clearFicha(state) {
    state.idFicha = null
    state.ficha = null
  },
}

export const actions = {
  setFicha({ commit }, ficha) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeFicha', ficha)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearFicha')
  },
}

export const getters = {
  idFicha(state) {
    return state.idFicha
  },
  ficha(state) {
    return state.ficha
  },
}
