export const state = () => ({
  idDeposito: undefined,
  statusDeposito: undefined,
})

export const mutations = {
  changeDeposito(state, deposito) {
    state.idDeposito = deposito.id.idDeposito
    state.statusDeposito = deposito.status
  },
  changeStatusDeposito(state, status) {
    state.statusDeposito = status
  },
  clearDeposito(state) {
    state.idDeposito = undefined
    state.statusDeposito = undefined
  },
}

export const actions = {
  setDeposito({ commit }, deposito) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeDeposito', deposito)
        resolve()
      }, 50)
    })
  },
  setStatusDeposito({ commit }, status) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeStatusDeposito', status)
        resolve()
      }, 50)
    })
  },
  clear({ commit }) {
    commit('clearDeposito')
  },
}

export const getters = {
  idDeposito(state) {
    return state.idDeposito
  },
  statusDeposito(state) {
    return state.statusDeposito
  },
}
