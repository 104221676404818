export default ($axios) => ({
  findRequisitos(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/requisitosLegais/${empresa}`)
    } else {
      return $axios.get(`/requisitosLegais/${empresa}/?search=${search}`)
    }
  },

  getRequisitoById(empresa, idRequisito) {
    return $axios.get(`/requisitosLegais/${empresa}/${idRequisito}`)
  },

  saveRequisito(empresa, requisitoLegal) {
    return $axios.post(`/requisitosLegais/${empresa}`, requisitoLegal)
  },

  deleteRequisito(empresa, idRequisito) {
    return $axios.delete(`/requisitosLegais/${empresa}/${idRequisito}`)
  },
})
