export const state = () => ({
  pontoControle: [],
})

export const mutations = {
  fill(state, rows) {
    state.pontoControle = rows
  },
}

export const actions = {
  find({ commit }, empresa) {
    this.$pontoControleService
      .findPontoControle(empresa)
      .then((response) => {
        const pontoControle = response.data.filter(
          ({ status }) => status === 'A'
        )

        commit('fill', pontoControle)
      })
      .catch((error) => {
        this.$notify({
          title: 'Erro',
          text: error.response ? error.response.data.message : error,
          type: 'error',
        })
      })
  },
}

export const getters = {
  pontoControle(state) {
    return state.pontoControle
  },
}
