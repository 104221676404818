export default ($axios) => ({
  findAreaProtegida(empresa, search) {
    if (search === null || search === undefined) {
      return $axios.get(`/areasProtegidas/${empresa}`)
    } else {
      return $axios.get(`/areasProtegidas/${empresa}?search=${search}`)
    }
  },

  getareaProtegidaById(empresa, idAreaProtegida) {
    return $axios.get(`/areasProtegidas/${empresa}/${idAreaProtegida}`)
  },

  saveAreaProtegida(empresa, areaProtegida) {
    return $axios.post(`/areasProtegidas/${empresa}`, areaProtegida)
  },

  deleteAreaProtegida(empresa, idAreaProtegida) {
    return $axios.delete(`/areasProtegidas/${empresa}/${idAreaProtegida}`)
  },
})
