export default ($axios) => ({
  findOrgaos(search) {
    if (search === null || search === undefined) {
      return $axios.get(`/cadastros/amb/orgaosAprovadores`)
    } else {
      return $axios.get(`/cadastros/amb/orgaosAprovadores?search=${search}`)
    }
  },

  saveOrgao(payload) {
    return $axios.post(`/cadastros/amb/orgaosAprovadores`, payload)
  },

  getOrgaoById(idOrgao) {
    return $axios.get(`/cadastros/amb/orgaosAprovadores/${idOrgao}`)
  },

  updateOrgao(idOrgao, payload) {
    return $axios.post(`/cadastros/amb/orgaosAprovadores/${idOrgao}`, payload)
  },

  deleteOrgao(idOrgao) {
    return $axios.delete(`/cadastros/amb/orgaosAprovadores/${idOrgao}`)
  },
})
